import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root', })
export class SharedDataService {
    shared_data: any = [];

    fac_editor_user: string = "";
    fac_country: string = "";
    fac_details: any = {};
    fac_nap_products: any = [];
    fac_ap_products: any = [];
    fac_farmacias: any = [];
    fac_token: string = "";
    fac_ocr_details: any = {};

    fac_image_canvas: any = undefined;

    constructor() {}
}
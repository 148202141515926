import { Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Product } from '../../models/product';
import { ReceiptService } from '../../services/receipt.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "nap_search_box",
	templateUrl: "nap_search_box.component.html",
	styleUrls: ["nap_search_box.component.css"]
})
export class Non_Abbott_Products_Search_Box implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	public model: any;
	clickedItem:string;

	@Output() selectedItemChanged = new EventEmitter();

	private factura: any = {};
	private productsList: any = [{"name":"No hay productos encontrados."}];

    constructor(private messagingService: MessagingService, private shared_data_service: SharedDataService, private receiptService: ReceiptService) {
    }

	// When the user selects an item from the drop down, this method sets the item and the value in the search box
	// Invoking the message suscription "selectedItemChanged"
	selectedItem(item){
		item.preventDefault();
		this.clickedItem = item.item;
		this.selectedItemChanged.emit(item["item"]);
		this.model = null;
	}

	// Searches within the input item list, any item that matches the input from the searchbox
	find_term(term) {
		let _this = this;
		return term.length < 2 ? [] : function() {
			let found_items = _this.productsList.filter(v => {

				if (
					(v["corp"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
					(v["lab"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
					(v["product"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
					(v["product_package"].toLowerCase().indexOf(term.toLowerCase()) > -1)
				) {
					return v["product_package"];
				}

			});
			if (found_items.length > 0) {
				return found_items.slice(0, 20); // Returns the top 20 results found.
			}
			else {

				let product_name: string = term;

				return [
					{ product: "No se encontró. Agregar nuevo.", lab:"OTRO", product_package: "OTRO", corp: "OTRO", other: product_name.toUpperCase() }
				]
			}
		}();
	}

	// Sets the value that is shown in the list of the type ahead drop down
	formatter = (product: Product) => product["product_package"];

	// Main search function that receives the input from the searchbox
	search = (text$: Observable<string>) => 
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this.find_term(term))
		);


	// Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		this.factura = await this.shared_data_service.fac_details;
		let nap_search = await this.receiptService.get_non_abbott_products(this.factura["country"]);
		
		if (nap_search.result) {
			this.productsList = nap_search.data.products;
		}
		else {
			// Handle search error...
			console.log(nap_search);
		}
	}

    async ngOnInit() {

		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "app-receipt",
	templateUrl: "receipt.component.html",
	styleUrls: ["receipt.component.css"]
})
export class ReceiptComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('visbleCanvas', { static: true })
	visbleCanvas: ElementRef<HTMLCanvasElement>;
	private ctx: CanvasRenderingContext2D;

	private token:string = "";

	countryId: string = "";
	receiptId: string = "";
	receiptImageUrl: string = "";
	private receiptData: any = {};
	
	// Intermediate variables filled with the event listeners from child components
	selectedWord:string = "";
	foundMatches:any = [];
	private productToAdd:any = {};
	productList:any = [];

	// Event Emitters that communicate information to listeners
	dataUpdateEvent = new EventEmitter();

	constructor(
		private receiptService: ReceiptService, 
		private cacheService: CacheService,
		private route: ActivatedRoute,
		private router: Router) {
			this.receiptId = this.route.snapshot.params["id"];
			this.token = this.route.snapshot.params["token"];
	}
	
	// Controller (data manipulators) Methods
	// --------------------------------------------------
	
	processReceiptData(data) {
		this.receiptData = data["response"]["details"];
		let imageUrl = this.receiptData["imageUrl"];
		this.receiptImageUrl = imageUrl;
	}

	// Event listeners

	polygonWordSelected(e) {
		console.log(e);
		this.selectedWord = e["selectedWord"];
		this.foundMatches = e["foundMatches"];
	}

	productSelected(event) {
		
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		try {
			// Validates if there is a token and receipt
			if (!this.receiptId || this.receiptId === "") { this.router.navigate(['/']); return; }
			// if (!this.token || this.token === "") { this.router.navigate(['/']); return; }

			this.receiptService.SetToken(this.token);
			let r = await this.receiptService.GetReceiptData(this.receiptId); // 960901

			this.processReceiptData(r);

			// Gets the Products list
			let productsR = await this.receiptService.GetProducts('CR');
			this.productList = productsR;

			// Broadcasts message indicating the data has been updated
			this.dataUpdateEvent.emit(this.productList);
		}
		catch(err) {
			// TODO: Handle error
			console.log("Error:", err);
		}
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
        console.log("ReceiptComponent Changes:", changes);
    }
}
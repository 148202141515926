import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { Product } from '../../models/product';
import { ProductAdded } from '../../models/productadded'; 

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "productform",
	templateUrl: "productform.component.html",
	styleUrls: ["productform.component.css"]
})
export class ProductFormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    private selectedProduct: any = {}; 
    selectedProductName:string = "";
    private foundMatches:any = [];

    qty: number = 0;
    price: number = 0;

    errorMessage: string = "";

    @Output() productAdded = new EventEmitter();

	constructor(private messagingService: MessagingService) {

        // When a user selects an item from the product list component.
        this.messagingService.setSelectedProductInFormEvent.subscribe(
            (product) => {
                this.selectedProduct = product;
                
                if (this.selectedProduct["id"]) {
                    this.selectedProductName = this.selectedProduct["name"];
                }
            }
        );

        // When an error occurres when adding the product to the list.
        this.messagingService.errorWhileAddingProductToListEvent.subscribe(
            (errorMessage) => {
                this.errorMessage = errorMessage;
            }
        );
    }

    // Public Methods
    // --------------------------------------------------

    addProduct() {

        if (!this.validateForm()) return;

        // Builds the new Product to be added
        let newProductAdded = new ProductAdded(
            this.selectedProduct.id, 
            this.selectedProduct.name, 
            this.qty, 
            this.price
        );

        this.productAdded.emit(this.selectedProduct); 
        this.messagingService.AddNewProductMessage(newProductAdded);

        this.clearForm();
    }

    hideMsg() {
        this.errorMessage = "";
    }

    showErrorMsg(msg) {
        this.errorMessage = msg;
    }

    validateForm() {
        this.errorMessage = "";

        // The product object must be defined. If not, it means it hasn't been setup in the product list component
        if (!this.selectedProduct["id"]) { this.showErrorMsg("No hay un producto seleccionado."); return false; }

        // The qty field is required
        if (!this.qty || this.qty === 0) { this.showErrorMsg("No ingresó una cantidad para el producto."); return false; }

        // The price field is required
        if (!this.price || this.price === 0) { this.showErrorMsg("No ingresó un precio para el producto."); return false; }

        return true; // All validations passed.
    }

    clearForm() {
        this.errorMessage = "";
        this.qty = 0;
        this.price = 0;
        this.selectedProduct = {};
        this.selectedProductName = "";
    }


    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
        this.selectedProduct = changes.selectedProduct.currentValue;

        if (this.selectedProduct["id"]) {
            this.selectedProductName = this.selectedProduct["name"];
        }
    }
}
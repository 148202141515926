import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, HostListener  } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { MessagingService } from '../../services/messagingservice';
import { Subject, interval } from "rxjs";
// Environment Setup
import { environment } from '../../../environments/environment.prod';
import { Factura_SearchResults_Object } from "src/app/models/facturas_search";


declare var jquery: any;
declare var $: any;

export interface TimeSpan {
	hours: number;
	minutes: number;
	seconds: number;
}

@Component({
	selector: "receiptdetails-page",
	templateUrl: "receiptdetailspage.component.html",
	styleUrls: ["receiptdetailspage.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptDetailsPageComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	//Kevboard Event Biding -----------------------------------------------
	@HostListener('document:keydown', ['$event'])
	onKeydown(ev:KeyboardEvent){
		if(ev.ctrlKey && ev.key == 'a'){
			ev.preventDefault();
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			$("#aprobarFaturaModal").modal('show');
		}

		if(ev.ctrlKey && ev.key == 'r'){
			ev.preventDefault();
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			$("#rechazarFaturaModal").modal('show');
		}

		if(ev.ctrlKey && ev.key == 's'){
			ev.preventDefault();
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			$("#modificarFacturaModal").modal('show');
		}
	}
	//---------------------------------------------------------------------

	public loadingData: boolean = true;

	private encodedToken: string = "";
	public receiptId: string = "";
	private token: string = "";
	private userid: string = "";

	public receiptObject: any = {};
	public receiptImage: string = "";
	public receiptProducts: any = [];
	public receiptNonAbbottProducts: any = [];
	public receiptOCRData: any = {};
	public receiptWordsWithProductMatches: any = [];
	public hasBeenAnalized: boolean = false;
	public receiptOtherAbbottProducts = [];
	public receiptOtherNonAbbottProducts = [];

	public timerInit: boolean = false;
	timerCount: Date;
	timerString: string = '';
	timerFunction: any;
	timerSubscription = null;
	finalElapseTime: any = null;

	public availableProducts: any = null;
	public availableNonAbbotProducts: any = [];
	public searchedAbbotProducts: any = [];
	public updateRequestBody: any = {
		"ID": "",
		"Factura": "",
		"Fecha": "",
		"Farmacia": {
			"ID": "",
			"Nombre": ""
		},
		"ProductosAbbott": [
			
		],
		"ProductosNoAbbott": [
			
		],
		"ProductosNoAbbott_Suggestions": [

		],
		"MontoTotal": 0,
		"MedioCompra": "Domicilio",
		"TiempoRevision": 0
	}
	private imageURI: any = "";

	//Microsoft Dynamics Variables
	public dynamicsOn: boolean = true; // This variable defines if the information is synced with Dynamics.

	public rejectionReason: string = "";
	// Modals Variables
	rejectionProcessing: boolean = false;
	rejectionResponseMessage: string = "";

	public validationMessages: any = [];

	message: any = {
		title: "",
		body: ""
	};

	constructor(
		private rs: ReceiptService,
		private route: ActivatedRoute,
		private router: Router,
		private ms: MessagingService,
		private changeDetector: ChangeDetectorRef
	) {

		this.encodedToken = this.route.snapshot.params["token"];
	}

	// Private Methods
	// --------------------------------------------------

	ValidateInformation(receiptData) {
		try {
			let messages = [];

			let d = new Date(receiptData.Fecha);
			if (d > new Date()) {
				messages.push({"message":"La fecha es inválida. No puede ser mayor a la fecha de hoy."});
				return { result: false, messages: messages };
			}
			
			return { result: true, messages: [] };

		}
		catch(err) {
			return { result: false, messages: [ {"message":"Error al validar la factura."} ] };
		}
	}


	public Replace(stringToAnalize, charToFind, charReplaceValue) {
		if (stringToAnalize.indexOf(charToFind) >= 0) {
			stringToAnalize = stringToAnalize.replace(charToFind, charReplaceValue);
			return this.Replace(stringToAnalize, charToFind, charReplaceValue);
		}
		else {
			return stringToAnalize;
		}
	}

	async GetData(receiptId) {
		let response = await this.rs.GetReceiptDetailsWithProductsArray(receiptId);

		this.receiptObject = response;

		// Image Env bug Fix
		this.receiptImage = response["imageUrl"];
		if (this.receiptImage !== undefined) {
			var split = this.receiptImage.split('/');
			this.receiptImage = environment.nodeServer + "/" + split[3] + "/" + split[4];
		}
		else {
			this.receiptImage = "";
		}
		
		//--------------------------------------------------------------------------------------------

		this.receiptProducts = response["Products"];
		this.receiptNonAbbottProducts = response["NonAbbottProducts"];

		this.updateRequestBody["ProductosNoAbbott"] = this.receiptNonAbbottProducts;
		this.updateRequestBody["MontoTotal"] = response["MontoTotal"];
		this.updateRequestBody["MedioCompra"] = response["MedioCompra"];

		this.hasBeenAnalized = false;

		if (
			this.receiptImage
			&&
			this.receiptImage.toUpperCase().indexOf(".PDF") < 0
			&&
			response["OcrInfo"]["imageText"] !== ""
			&&
			response["OcrInfo"]["imageText"] !== null
		) {
			let unscapedData = unescape(response["OcrInfo"]["imageText"]);
			this.receiptOCRData = JSON.parse(unscapedData);

			this.receiptProducts = this.receiptOCRData["products"];
			this.hasBeenAnalized = true;

			// Other Abbott Products
			this.receiptOtherAbbottProducts = this.receiptOCRData["otherAbbottProducts"];
			this.receiptOtherNonAbbottProducts = this.receiptOCRData["otherNonAbbottProducts"];

			this.LoadNonAbbottProducts(this.receiptOtherNonAbbottProducts);
			this.LoadOtherAbbottProducts(this.receiptOtherAbbottProducts);
		}

		if (response["OcrInfo"]) {
			this.receiptImage = response["OcrInfo"]["imageUrlProcessed"] !== "" ? response["OcrInfo"]["imageUrlProcessed"] : response["imageUrl"];
			var split = this.receiptImage.split('/');
			this.receiptImage = environment.nodeServer + "/" + split[3] + "/"+split[4];
		}
		else {
			this.receiptImage = response["imageUrl"];
			if (this.receiptImage !== undefined) {
				var split = this.receiptImage.split('/');
				this.receiptImage = environment.nodeServer + "/" + split[3] + "/"+split[4];
			}
			else {
				this.receiptImage = "";
			}
		}

		// If the image hasn't bee analyzed it calls the process to do it.
		if (!this.hasBeenAnalized) {
			await this.InvokeAnalyze();
		}
		await this.GetProductsByCountry(response['country']);
		await this.GetNonAbbotProductsBySearch()
		this.loadingData = false;
	}

	async GetProductsByCountry(countryId) {
		try {
			let response = await this.rs.GetAboxProductList(countryId)
			this.availableProducts = response['data'];
		}
		catch (err) {
			console.log(err)
		}

	}

	async GetNonAbbotProductsBySearch(){
		try{
			let countryCode = this.receiptObject['country'];
			let response = await this.rs.GetNonAbbotProductList( countryCode);
			this.availableNonAbbotProducts = response['data'];
		}catch(err){
			console.log(err)
		}
	}

	searchNonAbbot(search:string){
		this.searchedAbbotProducts = [];
		this.availableNonAbbotProducts.forEach((p, i)=>{
			if(p.Producto.indexOf(search) != -1 && this.searchedAbbotProducts.length < 20){
				this.searchedAbbotProducts.push(p);
			}
		})
	}

	getElapsedTime(entry: Date): TimeSpan {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);

		let hours = 0;
		let minutes = 0;
		let seconds = 0;

		if (totalSeconds >= 3600) {
			hours = Math.floor(totalSeconds / 3600);
			totalSeconds -= 3600 * hours;
		}

		if (totalSeconds >= 60) {
			minutes = Math.floor(totalSeconds / 60);
			totalSeconds -= 60 * minutes;
		}

		seconds = totalSeconds;

		// if(!this.timerInit){
		// 	this.finalElapseTime = {
		// 		hours: hours,
		// 		minutes: minutes,
		// 		seconds: seconds
		// 	}
		// }

		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds
		};
	}

	getElapsedTime_seconds(entry: Date): number {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);
		return totalSeconds;
	}

	getFinalTime(entry: Date) {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);

		return totalSeconds;
	}

	//Update Reciept Methods
	async UpdateReceiptObject(receiptId) {
		let response = await this.rs.GetReceiptDetailsWithProductsArray(receiptId);
		this.receiptObject = response;
	}

	// Event triggered in the Image component when the image is loaded
	ImageLoaded(imageURI) {
		this.imageURI = imageURI;
		this.ms.TriggerDrawPolygons();
	}

	//API CALLS
	async Approve() {
		try {

			// Validations
			// ------------------------------------------------------------------------
			this.validationMessages = [];

			// Header (Receipt Id, date, Pharmacy)
			let vh = this.ValidateHeader();

			// Products
			let vr = this.ValidateUpdateProducts();

			if (!vh.result || !vr.result) {
				this.validationMessages = vh.messages.concat(vr.messages);

				this.changeDetector.detectChanges();
				$("#validationResultModal").modal('show');
				return;
			}

			$("#pleaseWaitDialog").modal('show');

			// Updates the Factura
			// ------------------------------------------------------------------------
			let updateResult = await this.UpdateWithNoStateChange(false);

			if (!updateResult) {
				return;
			}

			this.timerInit = false;
			this.timerSubscription.unsubscribe(); 

			//Micorsoft Dynamics CRM Integration START ------
			if(this.dynamicsOn){
				//Dynamics Microsoft Integration
				let dynamicsObject = this.createUpdateDynamicsJSON();
				dynamicsObject["status"] = "Aprobada"
				dynamicsObject["revisionTime1"] = this.getElapsedTime_seconds(this.timerCount);
				let rDynamics = await this.rs.DynamicsUpdateReceipt(dynamicsObject);
				if (!rDynamics["result"]) {
					// Handle error.
					console.log("Error in Dynamics CRM petition: ",rDynamics);
					// return false;
				}
			}	
			//Micorsoft Dynamics CRM Integration END ------

			let approvalResponse = await this.rs.ApproveReceipt(this.receiptId, this.userid, []);
			
			$("#pleaseWaitDialog").modal('hide');

			if (approvalResponse["result"]) {
				if (approvalResponse["data"]["response"]["code"] === 0) {
					this.message.callbackfx = () => {
						window.close();
					}
					
					this.changeDetector.detectChanges();
					$('#approvedModalWithAction').modal('show');
				}
				else {
					// Handle Error.
					this.changeDetector.detectChanges();
					$('#approvalErrorModal').modal('show');
				}
			}
			else {
				// Handle Error.
				this.changeDetector.detectChanges();
				$('#approvalErrorModal').modal('show');
			}
		}
		catch (err) {
			console.log("General Error approving the receipt:", err);
		}
	}

	async HandleRejectionLoading(e) {
		this.rejectionProcessing = true;
		this.timerInit = false;
		this.timerSubscription.unsubscribe(); 
	}

	async HandleRejectionResponse(e) {
		this.rejectionProcessing = false;
		this.rejectionResponseMessage = "";

		// The factura was rejected, updates the record with the revision time.
		await this.UpdateWithNoStateChange(false);

		$("#pleaseWaitDialog").modal('hide');

		if (e["result"]) {

			$("#rechazarFaturaModal").modal('hide');
			this.message.title = "Listo!";
			this.message.body = e["message"];
			this.message.callbackfx = () => {
				window.close();
			}
			this.changeDetector.detectChanges();
			$('#messageModalWithAction').modal('show');
		}
		else {
			// Handle Error
			this.rejectionResponseMessage = e["message"];
		}
	}

	async UpdateWithNoStateChange(showDialogMessage = true) {
		try {

			// Validates the information:
			let val_result = this.ValidateInformation(this.updateRequestBody);
			if (!val_result["result"]) {
				this.validationMessages = val_result["messages"];
				this.changeDetector.detectChanges();
				$("#validationResultModal").modal('show');
				return false;
			}

			// Validates all the required fields
			let req_val_result = this.ValidateHeader_Required_Fields(this.updateRequestBody);
			if (!req_val_result.result) {
				this.validationMessages = req_val_result.messages;
				this.changeDetector.detectChanges();
				$("#validationResultModal").modal('show');
				return false;
			}

			// Validates the informacion from the products
			let productsValidationResponse = this.ValidateUpdateProducts(false);
			if (!productsValidationResponse.result) {
				this.validationMessages = productsValidationResponse.messages;
				this.changeDetector.detectChanges();
				$("#validationResultModal").modal('show');
				return false;
			}

			//Scope
			var _this = this;

			this.timerInit = false;
			this.finalElapseTime = this.getElapsedTime(this.timerCount)
			this.timerSubscription.unsubscribe(); 

			if (showDialogMessage) {
				$("#pleaseWaitDialog").modal('show');
			}

			// Cleans the ID from the pharmacy when the other option is selected
			this.updateRequestBody.Farmacia.Nombre = this.updateRequestBody.Farmacia.ID === -1 ? this.updateRequestBody.Farmacia.Nombre_Otra : this.updateRequestBody.Farmacia.Nombre;
			this.updateRequestBody.Farmacia.ID = this.updateRequestBody.Farmacia.ID === -1 ? null : this.updateRequestBody.Farmacia.ID;

			let updateData = JSON.parse(JSON.stringify(this.updateRequestBody));

			delete updateData["ProductosNoAbbott_Suggestions"];
			updateData["TiempoRevision"] = this.getElapsedTime_seconds(this.timerCount);

			
			let r = await this.rs.UpdateCRMReceipt(updateData, this.encodedToken);
			
			this.timerCount = new Date();

			if (!r["result"]) {
				// Handle error.
				this.validationMessages = [{"message": "Ocurrió un problema al actualizar la factura."}];
				console.log(r);
				$("#pleaseWaitDialog").modal('hide');
				this.changeDetector.detectChanges();
				$("#validationResultModal").modal('show');
				return false;
			}

			if(this.dynamicsOn){

				console.log("syncing with dynamics...")

				//Dynamics Microsoft Integration
				let dynamicsObject = this.createUpdateDynamicsJSON();

				console.log(JSON.stringify(dynamicsObject))

				dynamicsObject["revisionTime1"] = this.getElapsedTime_seconds(this.timerCount);
				let rDynamics = await this.rs.DynamicsUpdateReceipt(dynamicsObject);
				if (!rDynamics["result"]) {
					// Handle error.
					console.log("Error in Dynamics CRM petition: ",rDynamics);
					// return false;
				}
			}	

			if (showDialogMessage) {
				$("#pleaseWaitDialog").modal('hide');

				this.message.title = "Listo!";
				this.message.body = "La factura se actualizó.";
				this.message.callbackfx = () => {
					window.close();
				}
				this.changeDetector.detectChanges();
				$('#messageModalWithAction').modal('show');
				
			}
			
			return true;
		}
		catch (err) {
			$("#pleaseWaitDialog").modal('hide');
			console.log("Error approving the receipt:", err);
			throw err;
		}
	}

	//MODAL FUNCTIONS
	OpenRejectModal() {
		this.rejectionResponseMessage = "";
		this.ms.ResetRejectionReasonFormEvent("");
		$("#rechazarFaturaModal").modal('show');
	}

	OpenPleaseWaitModal() {
		$("#pleaseWaitWithBar").modal('show');
	}

	ClosePleaseWaitModal() {
		$("#pleaseWaitWithBar").modal('hide');
	}

	ToggleModal(id, bool){
		if(bool){
			$("#"+id).modal('show');
		}
		else{
			$("#"+id).modal('hide');
		}
	}

	// Validation Methods
	// --------------------------------------------------
	ValidateProducts() {

		let errorsFound = [];
		let resultVal = true;

		this.receiptProducts.forEach(p => {
			if (!p["checked"]) {
				errorsFound.push({ message: `El producto ${p["Producto"]} no ha sido chequeado.` });
				resultVal = false;
			}
			if (!p["price"] || p["price"] === null || p["price"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto"]} no tiene un precio.` });
				resultVal = false;
			}
			if (!p["Cantidad"] || p["Cantidad"] === null || p["Cantidad"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto"]} no tiene una cantidad.` });
				resultVal = false;
			}
		});

		return {
			result: resultVal,
			messages: errorsFound
		}
	}

	ValidateHeader() {
		let errorsFound = [];
		let resultVal = true;

		if (!this.receiptObject["receipt_checked"]) {
			errorsFound.push({ message: `El número de factura no ha sido chequeado.` });
			resultVal = false;
		}

		if (!this.receiptObject["pharmacy_checked"]) {
			errorsFound.push({ message: `La farmacia de la factura no ha sido chequeado.` });
			resultVal = false;
		}

		if (!this.receiptObject["date_checked"]) {
			errorsFound.push({ message: `La fecha de la factura no ha sido chequeado.` });
			resultVal = false;
		}

		return {
			result: resultVal,
			messages: errorsFound
		}
	}

	ValidateUpdateProducts(validate_checked = true) {
		let errorsFound = [];
		let resultVal = true;

		this.updateRequestBody.ProductosAbbott.forEach(p => {
			if (!p["checked"] && validate_checked) {
				errorsFound.push({ message: `El producto ${p["Producto"]} no ha sido chequeado.` });
				resultVal = false;
			}
			if (!p["Precio"] || p["Precio"] === null || p["Precio"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto"]} no tiene un precio.` });
				resultVal = false;
			}
			if (!p["Cantidad"] || p["Cantidad"] === null || p["Cantidad"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto"]} no tiene una cantidad.` });
				resultVal = false;
			}
		});

		this.updateRequestBody.ProductosNoAbbott.forEach(p => {
			if (!p["checked"] && validate_checked) {
				errorsFound.push({ message: `El producto ${p["Producto_Otros"]} no ha sido chequeado.` });
				resultVal = false;
			}
			if (!p["Precio_Otros"] || p["Precio_Otros"] === null || p["Precio_Otros"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto_Otros"]} no tiene un precio.` });
				resultVal = false;
			}
			if (!p["Cantidad_Otros"] || p["Cantidad_Otros"] === null || p["Cantidad_Otros"] === "") {
				errorsFound.push({ message: `El producto ${p["Producto_Otros"]} no tiene una cantidad.` });
				resultVal = false;
			}
		});

		return {
			result: resultVal,
			messages: errorsFound
		}
	}

	ValidateUpdateHeader() {
		let errorsFound = [];
		let resultVal = true;

		if (!this.updateRequestBody["receipt_checked"]) {
			errorsFound.push({ message: `El número de factura no ha sido chequeado.` });
			resultVal = false;
		}

		if (!this.updateRequestBody["pharmacy_checked"]) {
			errorsFound.push({ message: `La farmacia de la factura no ha sido chequeado.` });
			resultVal = false;
		}

		if (!this.updateRequestBody["date_checked"]) {
			errorsFound.push({ message: `La fecha de la factura no ha sido chequeado.` });
			resultVal = false;
		}

		return {
			result: resultVal,
			messages: errorsFound
		}
	}

	ValidateHeader_Required_Fields(updateData) {
		try {
			let messages = [];

			if (updateData.Factura === undefined || updateData.Factura === '' || updateData.Factura === null) {
				messages.push({"message":"El número de factura es requerido."});
				return { result: false, messages: messages };
			}

			// Verifies that when the option Other is selected in the pharmacy
			// the name has a value, otherwise returns an error.
			if (updateData.Farmacia.ID === -1) {
				if (updateData.Farmacia["Nombre_Otra"] === undefined || updateData.Farmacia["Nombre_Otra"] === "") {
					messages.push({"message":"Por favor ingrese un nombre para la farmacia."});
					return { result: false, messages: messages };
				}
			}

			if (updateData.MontoTotal === undefined || updateData.MontoTotal === '' || updateData.MontoTotal === null) {
				messages.push({"message":"El monto de la factura es requerido."});
				return { result: false, messages: messages };
			}

			if (updateData.MedioCompra === undefined || updateData.MedioCompra === '' || updateData.MedioCompra === null) {
				messages.push({"message":"El medio de compra es requerido."});
				return { result: false, messages: messages };
			}
			
			return { result: true, messages: [] };

		}
		catch(err) {
			return { result: false, messages: [ {"message":"Error al validar la factura."} ] };
		}
		
	}


	// This method is invoked by the "revisionimage" component.
	setRecieptImage(url){
		var split = url.split('/');
		this.receiptImage =  environment.nodeServer + "/" + split[3] + "/"+split[4];
		// this.receiptImage = url;
	}

	async InvokeReanalize($event) {

		let _imageURI = $event.imgUrl;

		console.log("")
		console.log(_imageURI)
		console.log("")

		this.OpenPleaseWaitModal();

		let idFactura = this.Replace(this.receiptObject["idFactura"], "'", "");

		// 1. Runs the GPC OCR Analysis to extract the text from the image
		// ------------------------------------------------------------------------------------------

		console.time("AnalizaReceiptUsingIdAndURL");

		let r = await this.rs.AnalizaReceiptUsingIdAndURL(idFactura, _imageURI);
		console.log('Analize Response:', r)

		console.timeEnd("AnalizaReceiptUsingIdAndURL");

		// 2. Updates the receipt/bill with the OCR Results and the new URL of the image
		// ------------------------------------------------------------------------------------------

		console.time("UpdateOCRAnalysis");

		let updateResponse = await this.rs.UpdateOCRAnalysis(idFactura, _imageURI, escape(JSON.stringify(r["data"])));
		console.log("Update Response:", updateResponse);

		console.timeEnd("UpdateOCRAnalysis");

		// 4. Updates the UI with the OCR Details
		// ------------------------------------------------------------------------------------------

		console.time("UpdateReceiptObject");

		await this.UpdateReceiptObject(idFactura);
		console.log('Update Reciept:', this.receiptObject)

		console.timeEnd("UpdateReceiptObject");

		this.receiptProducts = r["data"]["products"];

		// Other Abbott Products
		this.receiptOtherAbbottProducts = r["data"]["otherAbbottProducts"];
		this.receiptOtherNonAbbottProducts = r["data"]["otherNonAbbottProducts"];

		this.ms.ReloadImageInImageRevisionComponent();
		this.ms.UpdateReceiptInfoComponent(this.receiptObject);

		this.LoadNonAbbottProducts(this.receiptOtherNonAbbottProducts);
		this.LoadOtherAbbottProducts(this.receiptOtherAbbottProducts);

		console.log("Reanalize Ready!");
		this.ClosePleaseWaitModal();
	}

	async InvokeAnalyze() {
		// console.log('Analyze Image:' , this.receiptImage)
		$("#reanalizeModal").modal('hide');

		if (!this.receiptImage) {
			return;
		}
		if (this.receiptImage.toUpperCase().indexOf(".PDF") >= 0) {
			return;
		}

		this.OpenPleaseWaitModal();

		let idFactura = this.Replace(this.receiptObject["idFactura"], "'", "");

		// 1. Runs the GPC OCR Analysis to extract the text from the image
		// ------------------------------------------------------------------------------------------


		let r = await this.rs.AnalizaReceiptUsingIdAndURL(idFactura, this.receiptImage);
		// console.log('Analize Response:', r)

		// 2. Updates the receipt/bill with the OCR Results and the new URL of the image
		// ------------------------------------------------------------------------------------------

		let updateResponse = await this.rs.UpdateOCRAnalysis(idFactura, this.receiptImage, escape(JSON.stringify(r["data"])));
		

		// 4. Updates the UI with the OCR Details
		// ------------------------------------------------------------------------------------------


		await this.UpdateReceiptObject(idFactura);

		this.receiptProducts = r["data"]["products"];

		// Other Abbott Products
		this.receiptOtherAbbottProducts = r["data"]["otherAbbottProducts"];
		this.receiptOtherNonAbbottProducts = r["data"]["otherNonAbbottProducts"];

		this.ms.ReloadImageInImageRevisionComponent();
		this.ms.UpdateReceiptInfoComponent(this.receiptObject);

		this.LoadNonAbbottProducts(this.receiptOtherNonAbbottProducts);
		this.LoadOtherAbbottProducts(this.receiptOtherAbbottProducts);
		this.ClosePleaseWaitModal();
	}

	// Other products
	LoadNonAbbottProducts(foundProducts) {
		if (foundProducts && foundProducts.length > 0) {

			// Orders the products alphabetically
			foundProducts.sort((a, b) => {
				return a["topMatch"] > b["topMatch"] ? 1 : -1;
			});

			this.updateRequestBody["ProductosNoAbbott_Suggestions"] = [];
			foundProducts.forEach(p => {
				this.updateRequestBody["ProductosNoAbbott_Suggestions"].push(
					{
						"Producto_Otros": p["topMatch"],
						"FoundText": p["text"],
						"MatchIndex": p["matches"][0]["score"],
						"Cantidad_Otros": 0,
						"Precio_Otros": 0,
						"hasMatches": true,
						"matched_words":[
							{vertices: p["vertices"]}
						]
					}
				);
			});
		} else{
			this.updateRequestBody["ProductosNoAbbott_Suggestions"] = [];
		}
	}

	LoadOtherAbbottProducts(foundProducts) {
		if (foundProducts && foundProducts.length > 0) {

			// Orders the products alphabetically
			foundProducts.sort((a, b) => {
				return a["topMatch"] > b["topMatch"] ? 1 : -1;
			});

			this.updateRequestBody["ProductosAbbott_Suggestions"] = [];
			foundProducts.forEach(p => {
				let prod = {};
				if(p['matches'].length > 0){
					prod = {
						"ID_Producto": p["topMatchID"],
						"Producto": p["topMatch"],
						"FoundText": p["text"],
						"MatchIndex": p["matches"][0]["score"],
						"Cantidad": 0,
						"Precio": 0,
						"hasMatches": true,
						"matched_words":[
							{vertices: p["vertices"]}
						]
					}
				}
				else{
					prod = {
						"ID_Producto": p["topMatchID"],
						"Producto": p["topMatch"],
						"FoundText": p["text"],
						"MatchIndex": 0,
						"Cantidad": 0,
						"Precio": 0,
						"hasMatches": true,
						"matched_words":[
							{vertices: p["vertices"]}
						]
					}
				}

				this.updateRequestBody["ProductosAbbott_Suggestions"].push(prod);
			});

			// console.log(this.updateRequestBody);
		} else{
			this.updateRequestBody["ProductosAbbott_Suggestions"] = [];
		}
	}

	//Microsoft Dynamics

	toggleDynamics(){
		// console.log(this.createUpdateDynamicsJSON());
		// console.log(this.rejectionReason);

		if(this.dynamicsOn){
			this.dynamicsOn = false;
		}
		else{
			this.dynamicsOn = true;
		}
	}

	createUpdateDynamicsJSON() {
		let parseMedioCompra = (medio) => {
			switch(medio){
				case "Farmacia": return 1;
				case "Domicilio": return 2;
				default: return 1;
			}
		}

		let mapAbbotProds = (abbotProds) => {
			let arr = [];
			abbotProds.forEach(prod => {
				let p = {id: prod.ID_Producto , quantity: prod.Cantidad , price: prod.Precio}
				arr.push(p);
			});

			return arr;
		}

		let mapNonAbbotProds = (nonAbbotProds) => {
			let arr = [];
			nonAbbotProds.forEach(prod => {
				let p = {name: prod.Producto_Otros , quantity: prod.Cantidad_Otros , price: prod.Precio_Otros}
				arr.push(p);
			});

			return arr;
		}

		// console.log('Request Body: ',this.updateRequestBody);
		var updateDynamicsJson: any = {
			"country": this.receiptObject.country, 
			"idFromDatabase": this.updateRequestBody.ID, 
			"patientId": -1, 
			"pharmacyId": this.updateRequestBody.Farmacia.ID, 
			"billId": this.updateRequestBody.Factura, 
			"billDate": this.updateRequestBody.Fecha, 
			"billImageUrl": this.receiptImage, 
			"products": mapAbbotProds(this.updateRequestBody.ProductosAbbott), 
			"nonAboxProducts": mapNonAbbotProds(this.updateRequestBody.ProductosNoAbbott), 
			"status": null, 
			"statusReason": null, 
			"totalAmount": this.updateRequestBody.MontoTotal, 
			"revisionTime1": 0, 
			"revisionTime2": null, 
			"purchaseMethod": parseMedioCompra(this.updateRequestBody.MedioCompra)
		}

		// console.log("Dynamics Update Object: ", updateDynamicsJson);

		var x = JSON.parse(JSON.stringify(updateDynamicsJson));
		return x;
	}

	async RejectDynamicsCRM(e){
		try{
			//Micorsoft Dynamics CRM Integration START ------
			if(this.dynamicsOn){
				//Dynamics Microsoft Integration
				let dynamicsObject = this.createUpdateDynamicsJSON();
				dynamicsObject["status"] = "Rechazada";
				dynamicsObject["statusReason"] = e.reason;
				dynamicsObject["revisionTime1"] = this.getElapsedTime_seconds(this.timerCount);
				console.log(dynamicsObject)
				let rDynamics = await this.rs.DynamicsUpdateReceipt(dynamicsObject);
				if (!rDynamics["result"]) {
					// Handle error.
					console.log("Error in Dynamics CRM petition: ",rDynamics);
					// return false;
				}
			}	
			//Micorsoft Dynamics CRM Integration END ------
		}
		catch(err){
			console.log(err);
		}
		
	}

	// Component Methods
	// --------------------------------------------------

	async ngOnInit() {

		// Validates if there is a token and receipt
		if (!this.encodedToken || this.encodedToken === "") {

			console.log("No Token Received.")
			return;
		}

		let ts = new TokenService();
		let tokenresponse = ts.Decode(this.encodedToken);
		if (tokenresponse.result) {
			this.receiptId = tokenresponse["data"]["receiptid"];
			this.token = tokenresponse["data"]["token"];
			this.userid = tokenresponse["data"]["user"];

			this.rs.SetToken(this.token);
			await this.GetData(this.receiptId);
			// Starts the time.
			// ------------------------------------------------
			this.timerInit = true;
			this.timerCount = new Date();
			this.timerSubscription = interval(1000).subscribe(() => {
				if (!this.changeDetector['destroyed']) {
					this.changeDetector.detectChanges();
				}
			});

			this.changeDetector.detectChanges();
		}
		else {
			// Invalid token.
			console.log("Invalid Token.")
		}
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
	}
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { MessagingService } from '../../services/messagingservice';
declare var jquery:any;
declare var $ :any;

@Component({
	selector: "receipt-image",
	templateUrl: "receiptimage.component.html",
	styleUrls: ["receiptimage.component.css"]
})
export class ReceiptImageComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('visbleCanvas', { static: true })
	visbleCanvas: ElementRef<HTMLCanvasElement>;
	private ctx: CanvasRenderingContext2D;

	@Input() receiptId: string = "";
	@Input() imageUrl: string = "";
	@Output() wordSelected = new EventEmitter();

    rot:number = 0;
    ratio:number = 1;
	CanvasCrop: any;

	imageHasPolygonsDrawn: boolean = false;
	
	_highlightColor:string = "rgba(255, 0, 0, 0.2)";

	_receiptAnalyzedData:any = [];

	constructor(private receiptService: ReceiptService, private messagingService: MessagingService) {
		
    }

    // Image Loading Operations
	// --------------------------------------------------
	
	GetImageURI(canvas) {
		if (!canvas || canvas === "") return "";

		// Converts the contents on the canvas to a serialized text that will be sent to the server
		// to be interpreted and analyzed.

		// get image URI from canvas object
		var imageURI = canvas.toDataURL("image/jpg");

		return imageURI;
	}

	LoadImage() {

		if (this.imageUrl === "") return;

		let url = this.imageUrl;
		let _this = this;

        this.CanvasCrop = $.CanvasCrop({
            cropBox : ".imageBox",
            imgSrc : url, 
			limitOver : 0,
			imageLoadedFx: () => {
				// Hides the Spinner
				$(".spinner").hide();

				// Adds the click Events to the Canvas
				var canvas = document.getElementById("visbleCanvas");
				canvas.addEventListener('click', function (e) {
					let coords = {x:e.offsetX, y:e.offsetY};
					_this.processClick(coords, _this._receiptAnalyzedData.wordsfound)
				});

			}
		});
	}

	async AnalizeReceipt() {
		// This code interacts with the DOM.
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
		let uri = this.GetImageURI(canvas);
		
		$(".spinner").show();
		let r = await this.receiptService.AnalizeReceipt(this.receiptId, uri);
		$(".spinner").hide();

		// Draws all the polygons on top of the receipt image
		if (r["result"]) {
			let receiptData = r["data"];
			this._receiptAnalyzedData = receiptData;
			this.DrawAllPolygons(receiptData);
		}
	}

	// Polygon Operations
	// --------------------------------------------------

	DrawPolygon = (polygon) => {
        let color = this._highlightColor;
        let vs = polygon.vertices;
        let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
        let vArray = [];
        vs.forEach(v => { vArray.push(v.x); vArray.push(v.y); });
        var poly = vArray;
        ctx.fillStyle = color;
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.moveTo(poly[0], poly[1]);
        for( let item=2 ; item < poly.length-1 ; item+=2 ){ctx.lineTo( poly[item] , poly[item+1] )}

        ctx.closePath();
        ctx.fill();
	}

	DrawAllPolygons(receiptData) {
		// Draws the polygons
		// - wordsfound / - wordswithmatches
		if ( receiptData.wordsfound) {
			receiptData.wordsfound.forEach(w => {
				this.DrawPolygon(w);
			});
		}

		this.imageHasPolygonsDrawn = true;
	}
	
	// Image Canvas Polygon Click
    // --------------------------------------------------

	private selectedWord:string = "";
	private scaleFactor = 1;
	private polygonsWithMatches = [];
	private xoffset = 0;
	private yoffset = 0;
	private foundMatches = [];

	processClick(coords, polygons) {
		polygons = polygons || [];

		// Example: coords => {x:e.offsetX, y:e.offsetY}

		this.polygonsWithMatches = [];

		let _this = this;
		this.selectedWord = "";

		polygons.forEach(p => {
			let pol = p.vertices;
			let ret = _this.isPointInPoly(pol, coords);
			if (ret) {

				// Polygon Match with area click
				_this.selectedWord = p["text"];

				let found = _this.polygonsWithMatches.findIndex(f => f.text == p.text);
				if (found < 0) {
					_this.polygonsWithMatches.push(p);
				}
				else {
					_this.polygonsWithMatches.splice(found, 1);
				}
			}
		});
		
		if (this.polygonsWithMatches && this.polygonsWithMatches.length > 0) {
			this.foundMatches = this.polygonsWithMatches[0].matches;
		}

		this.wordSelected.emit({
			selectedWord: this.selectedWord,
			foundMatches: this.foundMatches
		});

		this.messagingService.WordPolygonSelectedMessage(this.selectedWord);
	}

	getRelativeMousePos(e, xoffset, yoffset){
		//get mouse position relative to the canvas object
		var ex = e.pageX - xoffset;
		var wy = e.pageY - yoffset;
		return {x:ex,y:wy};
	}

	isPointInPoly(poly, point) {
		//draw vectors from mouse location to detect of a given point is within a polygon
		var x = point.x, y = point.y;
		var inside = false;
		for (var i = 0, j = poly.length - 1; i < poly.length; j = i++) {
			var xi = this.scaleValue(poly[i].x), yi = this.scaleValue(poly[i].y);
			var xj = this.scaleValue(poly[j].x), yj = this.scaleValue(poly[j].y);
			var intersect = ((yi > y) != (yj > y))
				&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
			if (intersect) inside = !inside;
		}
		return inside;
	}

	scaleValue(value){
		//scale a provided value by the scalefactor
		//this way we can define the polygons at the native image size
		//but it scales the values relative to the hidden image element
		return value*this.scaleFactor;
	}

    // Image Operations
	// --------------------------------------------------

	Rotate_Left() {
		this.rot -= 90;
		this.rot = this.rot<0?270:this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.imageHasPolygonsDrawn = false;
	}

	Rotate_Right() {
		this.rot += 90;
		this.rot = this.rot>360?90:this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.imageHasPolygonsDrawn = false;
	}

	Zoom_Out() {
		this.ratio = this.ratio*0.9;
		this.CanvasCrop.scale(this.ratio);
		this.imageHasPolygonsDrawn = false;
	}

	Zoom_In() {
		this.ratio = this.ratio*1.1;
		this.CanvasCrop.scale(this.ratio);
		this.imageHasPolygonsDrawn = false;
	}

	// File Uploader
	// --------------------------------------------------

	changeListener($event) {
		// this.readThis($event.target);
	}

	// readThis(inputValue: any) : void {
	// 	console.log("Reading file...");

	// 	var file:File = inputValue.files[0]; 
	// 	var myReader:FileReader = new FileReader();

	// 	let _this = this;
	
	// 	myReader.onloadend = function(e){
	// 		// you can perform an action with readed data here
	// 		console.log(myReader.result);

	// 		_this.CanvasCrop = $.CanvasCrop({
	// 			cropBox : ".imageBox",
	// 			imgSrc : e.target.result,
	// 			limitOver : 2
	// 		});
	// 		_this.rot =0 ;
	// 		_this.ratio = 1;
	// 		_this.imageUrl = "---";
	// 	}

	// 	myReader.readAsDataURL(file);
	// }
	
	

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		if (this.imageUrl === "") {
			// Hides the Spinner
			$(".spinner").hide();
		}
		
		let _this = this;

		// $(function(){
		// 	$('#upload-file').on('change', function(){

		// 		console.log("File uploader changed.");

		// 		var reader = new FileReader();
		// 		reader.onload = function(e) {

		// 			console.log("File Loaded.");

		// 			_this.imageUrl = "---";

		// 			CanvasCrop = $.CanvasCrop({
		// 				cropBox : ".imageBox",
		// 				imgSrc : e.target.result,
		// 				limitOver : 2
		// 			});

		// 			rot =0 ;
		// 			ratio = 1;
		// 		}
		// 		reader.readAsDataURL(this.files[0]);
		// 		// files = [];
		// 	});
		// });
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log("Changed!");
		// console.log(changes.imageUrl.currentValue);
		this.LoadImage();
	}
	
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Factura_SearchResults_Object } from '../../../models/facturas_search';
import { MessagingService } from '../../../services/messagingservice';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "facturas_search_results",
	templateUrl: "facturas_search_results.component.html",
	styleUrls: ["facturas_search_results.component.css"]
})
export class facturas_search_results_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	
	@Input() facturas_list: Factura_SearchResults_Object[] = [];
	@Output() selectedItemEvent = new EventEmitter();

	OpenFactura(factura) {
		this.messagingService.TriggerOpenFacturaModal(factura);
	}

    // Component Methods
	// --------------------------------------------------

	constructor(private messagingService: MessagingService) {
	}

    async ngOnInit() {
		console.log(this.facturas_list);
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
export class ReceiptApprovalRequest {
    billId: string;
    user: string;
    role: string;

    constructor(_billId, _user, _role) {
        this.billId = _billId;
        this.user = _user;
        this.role = _role;
    }
}

export class ReceiptApprovalCallCenterRequest_Product {
    productid: string = "";
    qty: number = 0;
    price: number = 0;
    constructor(_productid, _qty, _price) {
        this.productid = _productid;
        this.qty = _qty;
        this.price = _price;
    }
}

export class ReceiptApprovalCallCenterRequest {
    billid: string;
    user: string;
    role: string;
    products: any[];

    constructor(_billid, _user, _role, _products) {
        this.billid = _billid;
        this.user = _user;
        this.role = _role;
        this.products = _products; // { productid:"", qty:0, price: 0 }
    }
}
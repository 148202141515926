import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../../services/messagingservice';
import { ProductsFilterPipe } from '../../../pipes/productsfilter';

declare var jquery: any;
declare var $: any;

@Component({
    selector: "receiptproducts-component",
    templateUrl: "receiptproducts.component.html",
    styleUrls: ["receiptproducts.component.css"]
})
export class ReceiptProductsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() receiptProducts: any = [];
    @Input() availableProducts: any = [];
    @Input() updateRequestBody: any = {};
    @Input() abbotProducts: boolean = true;
    @Input() availableNonAbbotProducts: any = []
    @Output() nonAbbotTextSearch = new EventEmitter()

    public addNewProduct: boolean = false;
    public newProduct: Object = {
        "ID_Producto": "",
        "Cantidad": 0,
        "Precio": 0,
        "Producto": ""
    };
    public newProductNoAbox: Object = {
        "Producto_Otros": "",
        "Cantidad_Otros": 0,
        "Precio_Otros": 0
    }
    public newProductNoAboxSuggestion : string = "";
    public validationMessages: any = [];
    public showSuggestions: boolean = false;
    public showSuggestionsAbbott: boolean = false;

    public searchtext: string = "";
    public searchtextAbbott: string = "";

    debounce: any  = 0;
    isSearchingNonAbbot = false;

    constructor(private ms: MessagingService) {
        this.ms.validateProductsEvent.subscribe(() => {
            // this.DoValidation();
        });

        this.ms.triggerDrawPolygonsEvent.subscribe(() => {
            if (this.receiptProducts !== undefined) {
                this.receiptProducts.forEach((p) => {
                    if (p["hasMatches"]) {
                        this.ms.DrawPolygonsOnCanvas(p["matched_words"]);
                        this.updateRequestBody.ProductosAbbott.forEach((pr,i) => {
                            if (pr.ID_Producto == p.ID_Producto) {
                                this.updateRequestBody.ProductosAbbott[i]['hasMatches'] = true
                            }
                        });
                        this.updateRequestBody.ProductosNoAbbott.forEach((pro,i) => {
                            if (pro.ID_Producto == p.ID_Producto) {
                                this.updateRequestBody.ProductosAbbott[i]['hasMatches'] = true
                            }
                        });
                        if (this.updateRequestBody.ProductosAbbott_Suggestions === undefined) {
                            this.updateRequestBody["ProductosAbbott_Suggestions"] = [];    
                        }
                    }
                    else {
                        this.updateRequestBody["ProductosAbbott_Suggestions"] = [];
                    }
                });
            }
            else {
                this.updateRequestBody["ProductosAbbott_Suggestions"] = [];
            }
        });
    }

    // Component Methods
    // --------------------------------------------------

    SelectProduct(product) {

        if (!product["origin"]) {
            let found = this.receiptProducts.filter(p => p["ID_Producto"] === product["ID_Producto"]);
            if (found.length > 0) {

                if (found[0]["matched_words"].length > 0) {
                    this.ms.DrawPolygonOnCanvas(found[0]["matched_words"][0]);
                }

            }
        }
        else {
            this.ms.DrawPolygonOnCanvas(product["matched_words"][0]);
        }
    }

    SelectSuggestedProduct(product) {
       
        this.ms.DrawPolygonOnCanvas(product["matched_words"][0]);
    }

    checkValue(checked: any, product: any) {
        product["checked"] = checked.currentTarget.checked;
    }

    checkInReceiptProds(id) {
        let bool = false;
        this.receiptProducts.forEach(p => {
            if (p['ID_Producto'] == id) {
                bool = true
            }
        });

        return bool;
    }

    ShowSuggestions() {
        this.showSuggestions = !this.showSuggestions;
        if (this.showSuggestions) {
            $("#btnToggleSuggestions").html("Ocultar Sugerencias"); 
        }
        else {
            $("#btnToggleSuggestions").html("Ver Sugerencias"); 
        }   
    }

    ShowSuggestionsAbbott() {
        this.showSuggestionsAbbott = !this.showSuggestionsAbbott;
        if (this.showSuggestionsAbbott) {
            $("#btnToggleSuggestionsAbbott").html("Ocultar Sugerencias"); 
        }
        else {
            $("#btnToggleSuggestionsAbbott").html("Ver Sugerencias"); 
        }   
    }

    addToNonAbbottProducts(prod) {
        prod["origin"] = "suggested";
        this.updateRequestBody["ProductosNoAbbott"].push(JSON.parse(JSON.stringify(prod)));

        // Pops the selected product from the suggested list.
        this.updateRequestBody["ProductosNoAbbott_Suggestions"] = this.updateRequestBody["ProductosNoAbbott_Suggestions"].filter(f => 
            f["Producto_Otros"] != prod["Producto_Otros"]
        );

        this.searchtext = "";
        this.showSuggestions = false;
        $("#btnToggleSuggestions").html("Ver Sugerencias"); 
    }

    addToAbbottProducts(prod) {
        prod["origin"] = "suggested";
        this.updateRequestBody["ProductosAbbott"].push(JSON.parse(JSON.stringify(prod)));

        // Pops the selected product from the suggested list.
        this.updateRequestBody["ProductosAbbott_Suggestions"] = this.updateRequestBody["ProductosAbbott_Suggestions"].filter(f => 
            f["Producto"] != prod["Producto"]
        );

        this.searchtextAbbott = "";
        this.showSuggestionsAbbott = false;
        $("#btnToggleSuggestionsAbbott").html("Ver Sugerencias"); 
    }

    // debounce = (func, wait) => {
    // 	let timeout;

    // 	// This is the function that is returned and will be executed many times
    // 	// We spread (...args) to capture any number of parameters we want to pass
    // 	return function executedFunction(...args) {

    // 	// The callback function to be executed after 
    // 	// the debounce time has elapsed
    // 	const later = () => {
    // 		// null timeout to indicate the debounce ended
    // 		timeout = null;

    // 		// Execute the callback
    // 		func(...args);
    // 	};
    // 	// This will reset the waiting every function execution.
    // 	// This is the step that prevents the function from
    // 	// being executed because it will never reach the
    // 	// inside of the previous setTimeout
    // 	clearTimeout(timeout);

    // 	// Restart the debounce waiting period.
    // 	// setTimeout returns a truthy value (it differs in web vs Node)
    // 	timeout = setTimeout(later, wait);
    // 	};
    // }

    searchNonAbbot(text){
        if (text == "") {
            this.newProductNoAboxSuggestion = text;
            this.isSearchingNonAbbot = false;
        }
        else {
            this.newProductNoAboxSuggestion = text;
            this.isSearchingNonAbbot = true;
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => { this.nonAbbotTextSearch.emit(text.toUpperCase()) }, 500);
        }
    }

    // New Product Methods -----------------------------

    toggleAddNewProduct() {
        this.addNewProduct = !this.addNewProduct;
    }

    setNewProduct(id) {
        let p = null;
        this.availableProducts.forEach(prod => {
            if (prod.id == id) {
                p = {
                    "ID_Producto": prod.id,
                    "Cantidad": 0,
                    "Precio": 0,
                    "Producto": prod.name,
                }
            }
        });

        this.newProduct = p;

    }

    pushNewProduct() {
        this.updateRequestBody.ProductosAbbott.push(JSON.parse(JSON.stringify(this.newProduct)));
        this.addNewProduct = false;
        this.newProduct = {
            "ID_Producto": "",
            "Cantidad": 0,
            "Precio": 0,
            "Producto": ""
        };
    }

    deleteProduct(id: string) {
        let toDelete = null;
        this.updateRequestBody.ProductosAbbott.forEach((p, index) => {
            if (p['ID_Producto'] == id) {
                toDelete = index;
            }
        });

        let prod = this.updateRequestBody["ProductosAbbott"][toDelete];
        if (prod["origin"]) {
            this.updateRequestBody["ProductosAbbott_Suggestions"].push(prod);
        }

        if (toDelete != null && this.updateRequestBody.ProductosAbbott.length == 1) {
            this.updateRequestBody.ProductosAbbott = []
        }
        else if (toDelete != null) {
            this.updateRequestBody.ProductosAbbott.splice(toDelete, 1);
        }
    }

    pushNewNoAboxProduct() {
        this.updateRequestBody.ProductosNoAbbott.push(JSON.parse(JSON.stringify(this.newProductNoAbox)));
        this.addNewProduct = false;
        this.newProductNoAbox = {
            "Producto_Otros": "",
            "Cantidad_Otros": 0,
            "Precio_Otros": 0
        };
    }

    pushNewAboxProductSuggestion(product){
        if (product != "") {
            this.updateRequestBody.ProductosNoAbbott.push({
                "Producto_Otros": product,
                "Cantidad_Otros": 1,
                "Precio_Otros": 1
            });
            this.newProductNoAboxSuggestion = "";
        }

    }

    deleteNoAboxProduct(id: string) {
        let toDelete = null;
        this.updateRequestBody.ProductosNoAbbott.forEach((p, index) => {
            if (p['Producto_Otros'] == id) {
                toDelete = index;
            }
        });
        
        let prod = this.updateRequestBody["ProductosNoAbbott"][toDelete];
        if (prod["origin"]) {
            this.updateRequestBody["ProductosNoAbbott_Suggestions"].push(prod);
        }
        
        this.updateRequestBody.ProductosNoAbbott.splice(toDelete, 1);
    }

    //Angular Methods

    async ngOnInit() {
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.availableNonAbbotProducts != undefined) {
            if (changes.availableNonAbbotProducts.previousValue !== changes.availableNonAbbotProducts.currentValue) {
                this.isSearchingNonAbbot = false;
            }
        }
    }
}
import { Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AbbottProduct } from '../../models/abbott_product';
import { ReceiptService } from '../../services/receipt.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "ap_search_box",
	templateUrl: "ap_search_box.component.html",
	styleUrls: ["ap_search_box.component.css"]
})
export class Abbott_Products_Search_Box implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	public model: any;
	clickedItem:string;

	private factura: any = {};

	@Output() selectedItemChanged = new EventEmitter();

	private productsList: any = [{"name":"No hay productos encontrados."}];

    constructor(
		private messagingService: MessagingService, 
		private receiptService: ReceiptService,
		private shared_data_service: SharedDataService
	) {
    }

	// When the user selects an item from the drop down, this method sets the item and the value in the search box
	// Invoking the message suscription "selectedItemChanged"
	selectedItem(item){
		item.preventDefault();
		if (item["item"]["found"] === undefined || item["item"]["found"]) {
			this.clickedItem = item.item;
			this.selectedItemChanged.emit(item["item"]);
		}
		this.model = null;
	}

	// Searches within the input item list, any item that matches the input from the searchbox
	find_term(term) {
		let _this = this;
		return term.length < 2 ? [] : function() {
			let found_items = _this.productsList.filter(v => {
				if (
					(v["id"] !== -1) && (
						(v["categoria"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
						(v["description"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
						(v["familia"].toLowerCase().indexOf(term.toLowerCase()) > -1) ||
						(v["name"].toLowerCase().indexOf(term.toLowerCase()) > -1)
					)
				) {
					return v["name"];
				}

			});
			if (found_items.length > 0) {
				return found_items.slice(0, 20); // Returns the top 20 results found.
			}
			else {
				return [
					{ name: "No se encontró un producto con esa descripción.", found:false, other: term }
				]
			}
		}();
	}

	// Sets the value that is shown in the list of the type ahead drop down
	formatter = (product: AbbottProduct) => product["name"];

	// Main search function that receives the input from the searchbox
	search = (text$: Observable<string>) => 
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this.find_term(term))
		);


	// Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		let country = await this.shared_data_service.fac_country;
		this.factura = await this.shared_data_service.fac_details;

		if (country !== undefined && country !== '') {
			let ap_search = await this.receiptService.GetAboxProductList(country);

			if (ap_search["result"]) {
				/*
				categoria: "CARDIOVASCULAR"
				description: "AMLODIPINA 5 MG + VALSARTAN 160 MG X 28 TABLETAS"
				division: null
				familia: "AMVAL"
				id: "12530"
				name: "AMVAL 5 / 160"
				*/
				this.productsList = ap_search["data"];
			}	
			else {
				// Handle search error...
				console.log(ap_search);
			}
		}
	}

    async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.init_operations();
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
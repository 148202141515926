import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: 'rejection-form',
	templateUrl: './rejectionform.component.html',
	styleUrls: ["rejectionform.component.css"]
})
export class RejectionForm implements OnInit, OnChanges {
	
	@Input() updateUserId: string = "";
	@Input() receiptId: string = "";
	@Output() updateDynamicsCrm = new EventEmitter<any>();
	@Output() loadingEvent = new EventEmitter<string>();
	@Output() returnResponse = new EventEmitter<any>();

	_showSpinner: boolean = false;
	private errorMessage: string = "";
	_showErrorMessage: boolean = false;

	private selectedReason: string = "";
	validationErors: any[] = [];

	constructor(private messagingService: MessagingService, private receiptService: ReceiptService) {
		this.messagingService.resetRejectionReasonFormEvent.subscribe((message) => {
			this.resetForm();
		});
    }
	
	// UI Methods

	resetForm() {
		this._showErrorMessage = false;
		this._showSpinner = false;
		this.selectedReason = "";
		this.errorMessage = "";
		this.validationErors = [];
	}

    // Events Methods

    selectedReasonChanged(val) {
		this.selectedReason = val;
		this.validationErors = [];
	}

    // Internal Methods
	
	validate() {
		let errorsFound = [];
		if (this.selectedReason === "") {
			errorsFound.push("Por favor seleccione una razón para el rechazo.");
		}
		return errorsFound;
	}

	async reject() {

		this.validationErors = this.validate();
		if (this.validationErors.length > 0) {
			return;
		}

		this.loadingEvent.emit("loading");
		this._showErrorMessage = false;
		this._showSpinner = true;

		try {
			this.updateDynamicsCrm.emit({reason:this.selectedReason});
			this.receiptService.RejectReceipt(
				this.receiptId,
				this.updateUserId,
				this.selectedReason
			)
			.then(() => {
				this._showSpinner = false; 
				this._showErrorMessage = false;

				this.returnResponse.emit({result: true, message:"La factura se rechazó con éxito."});
			})
			.catch((err) => {

				this._showSpinner = false; 
				this._showErrorMessage = false;

				this.returnResponse.emit({result: false, message:"La factura no se pudo rechazar."});
			});
		}
		catch(err) {
			this._showSpinner = false;  
			this._showErrorMessage = true;
			this.errorMessage = "Ocurrió un problema. No se pudo rechazar la factura.";

			this.returnResponse.emit({result: false, message:this.errorMessage});
		}
	}

    // Component Required Methods

	ngOnInit() {
    }

	ngOnChanges(changes: SimpleChanges) {
	}
}

import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "nap_validator",
	templateUrl: "nap_validator.component.html",
	styleUrls: ["nap_validator.component.css"]
})
export class Non_Abbott_Validator implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private selected_product_list: any = [];
	private valid_count: number = 0;
	private invalid_count: number = 0;

    constructor(private messagingService: MessagingService, private shared_data_service: SharedDataService) {
		this.messagingService.nap_product_list_changed_event.subscribe((products_list) => {
			this.selected_product_list = this.shared_data_service.fac_nap_products;
			this.selected_product_list = this.validate_products(this.selected_product_list);
			this.valid_count = this.selected_product_list.filter(s => s["status"]).length;
			this.invalid_count = this.selected_product_list.filter(s => !s["status"]).length;
		});
    }

	validate_products(products_to_validate) {
		products_to_validate.forEach(p => {
			let status = true;
			if (p["qty"] === undefined || p["qty"] === "" || p["qty"] === 0) {
				status = false;
			}
			if (p["price"] === undefined || p["price"] === "" || p["price"] === 0) {
				status = false;
			} 
			if (p["product"] === undefined || p["product"] === "") {
				status = false;
			}
			p["status"] = status;
		});
		return products_to_validate;
	}

    // Component basic methods...
	// --------------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { ReceiptApprovalRequest, ReceiptApprovalCallCenterRequest, ReceiptApprovalCallCenterRequest_Product } from '../models/receipt-approval-request';
import { ReceiptRejectionRequest } from '../models/receipt-rejection-request';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Injectable({ providedIn: 'root', })
export class DemoService {
	// API Urls:
    private _authUrl: string = 	 "https://apidev.aboxplan.com/security/authocrdemo"; // "http://localhost:9000/authocrdemo";	 	// 
    
	constructor(private cacheService: CacheService, private route: ActivatedRoute,
		private router: Router) {
    }

    BuildDemoToken

	DemoLogin() {
		return new Promise(async (resolve, reject) => {
			try {
                let demoUser = "110210909";
                let demoPwd = "Password.01";
                let _this = this;

				let response = await fetch(this._authUrl, {
                        method: 'POST',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': ''
                    },
                    body: JSON.stringify(
                        { "user": demoUser,"password": demoPwd }
                    )
                })
                .then(function(res){ 
                    return res.json();
                })
                .then(function(data){

                    let token = data["response"]["details"]["token"];
                    
                    // Invalid token.
			        _this.router.navigate(['/receipt/' + token]); return;

                    resolve(token);
                });
			}
			catch(err) {
				reject(err);
			}
		});
	}
}
import { Injectable, EventEmitter } from '@angular/core';
import { Factura_SearchResults_Object } from '../models/facturas_search';

@Injectable({ providedIn: 'root', })
export class MessagingService {

	messageEvent = new EventEmitter();
	newProductAddedEvent = new EventEmitter();
	setSelectedProductInFormEvent = new EventEmitter();
	errorWhileAddingProductToListEvent = new EventEmitter();
	wordPolygonSelectedEvent = new EventEmitter();
	drawPolygonsOnCanvasEvent = new EventEmitter();
	drawPolygonOnCanvasEvent = new EventEmitter();

	resetRejectionReasonFormEvent = new EventEmitter();
	reloadImageInImageRevisionComponentEvent = new EventEmitter();

	clearProductSearchInputEvent = new EventEmitter();

	updateCartEvent = new EventEmitter();
	clearCartEvent = new EventEmitter();

	updateReceiptDataEvent = new EventEmitter();
	showPageLoaderEvent = new EventEmitter();

	reanalizeReceiptEvent = new EventEmitter();

	// Validation Messagaes
	validateProductsEvent = new EventEmitter();

	updateReceiptInfoComponentEvent = new EventEmitter();

	triggerDrawPolygonsEvent = new EventEmitter();

	triggerDrawSinglePolygonEvent = new EventEmitter();

	// Facturas Modal Events

	triggerOpenFacturaModal = new EventEmitter();

	// Nap (Non Abbott) Messages
	nap_add_product_to_list_event = new EventEmitter();
	nap_product_list_changed_event = new EventEmitter();

	// Ap (Abbott Products) Messages
	ap_add_product_to_list_event = new EventEmitter();
	ap_product_list_changed_event = new EventEmitter();

	// Facturas Operations
	fac_loaded_event = new EventEmitter();
	fac_approve_event = new EventEmitter();
	fac_reject_event = new EventEmitter();
	fac_save_event = new EventEmitter();
	fac_stop_timer_event = new EventEmitter();
	fac_start_timer_event = new EventEmitter();
	fac_controller_show_please_wait_event = new EventEmitter();
	fac_controller_hide_please_wait_event = new EventEmitter();
	fac_field_clicked_event = new EventEmitter();
	fac_request_fac_reload_event = new EventEmitter();
	fac_request_fac_reload_ocr_info_event = new EventEmitter();
	fac_ocr_info_updated_event = new EventEmitter();
	fac_sync_with_dynamics_event = new EventEmitter();
	fac_generate_dynamics_json_event = new EventEmitter();
	fac_generate_factura_json_event = new EventEmitter();
	fac_new_image_loaded_event = new EventEmitter();
	fac_nullify_factura_event = new EventEmitter();

	constructor() {
	}

	// Generic Global Message
	SendMessage(data) {
		this.messageEvent.emit(data);
	}

	// Custom Events for more secure integrations between
	// --------------------------------------------------------

	AddNewProductMessage(product) {
		this.newProductAddedEvent.emit(product);
	}

	SetSelectedProductInFormMessage(product) {
		this.setSelectedProductInFormEvent.emit(product);
	}

	ErrorWhileAddingProductToList(message) {
		this.errorWhileAddingProductToListEvent.emit(message);
	}

	WordPolygonSelectedMessage(word) {
		this.wordPolygonSelectedEvent.emit(word);
	}

	ResetRejectionReasonFormEvent(val) {
		this.resetRejectionReasonFormEvent.emit(val);
	}

	DrawPolygonsOnCanvas(polygons) {
		this.drawPolygonsOnCanvasEvent.emit(polygons);
	}

	DrawPolygonOnCanvas(polygons) {
		this.drawPolygonOnCanvasEvent.emit(polygons);
	}

	ReloadImageInImageRevisionComponent() {
		this.reloadImageInImageRevisionComponentEvent.emit();
	}

	ClearProductSearchInput() {
		this.clearProductSearchInputEvent.emit();
	}

	UpdateCart() {
		this.updateCartEvent.emit();
	}

	ClearCart() {
		this.clearCartEvent.emit();
	}

	UpdateReceiptDataEvent() {
		this.updateReceiptDataEvent.emit();
	}

	ShowPageLoader() {
		this.showPageLoaderEvent.emit();
	}

	ReanalizeReceipt() {
		console.log("Calling...")
		this.reanalizeReceiptEvent.emit();
	}

	// Validation Messages
	ValidateProducts() {
		this.validateProductsEvent.emit();
	}

	UpdateReceiptInfoComponent(obj) {
		this.updateReceiptInfoComponentEvent.emit(obj);
	}

	TriggerDrawPolygons() {
		this.triggerDrawPolygonsEvent.emit();
	}

	TriggerDrawSinglePolygon(polygon) {
		this.triggerDrawSinglePolygonEvent.emit(polygon);
	}

	// Facturas Modal
	TriggerOpenFacturaModal(factura: Factura_SearchResults_Object) {
		this.triggerOpenFacturaModal.emit(factura);
	}

	// Nap Messages
	Nap_Add_Product_To_List(obj) {
		this.nap_add_product_to_list_event.emit(obj);
	}

	Nap_Product_List_Changed(obj) {
		this.nap_product_list_changed_event.emit(obj);
	}

	// Ap Messages
	Ap_Add_Product_To_List(obj) {
		this.ap_add_product_to_list_event.emit(obj);
	}

	Ap_Product_List_Changed(obj) {
		this.ap_product_list_changed_event.emit(obj);
	}

	// Facturas Events
	Fac_Loaded(obj) {
		this.fac_loaded_event.emit(obj);
	}

	Fac_Approve(obj) {
		this.fac_approve_event.emit(obj);
	}
	Fac_Reject(obj) {
		this.fac_reject_event.emit(obj);
	}
	Fac_Save(obj) {
		this.fac_save_event.emit(obj);
	}

	Fac_Stop_Timer(obj) {
		this.fac_stop_timer_event.emit(obj);
	}

	Fac_Start_Timer(obj) {
		this.fac_start_timer_event.emit(obj);
	}

	Fac_Controller_Show_Please_Wait(obj) {
		this.fac_controller_show_please_wait_event.emit(obj);
	}

	Fac_Controller_Hide_Please_Wait(obj) {
		this.fac_controller_hide_please_wait_event.emit(obj);
	}

	Fac_Field_Clicked(obj) {
		this.fac_field_clicked_event.emit(obj);
	}

	Fac_Request_Fac_Reload(obj) {
		this.fac_request_fac_reload_event.emit(obj);
	}

	Fac_Request_Fac_Reload_Ocr_Info(obj) {
		this.fac_request_fac_reload_ocr_info_event.emit(obj);
	}

	Fac_Ocr_Info_Updated(obj) {
		this.fac_ocr_info_updated_event.emit(obj);
	}

	Fac_Sync_With_Dynamics(obj) {
		this.fac_sync_with_dynamics_event.emit(obj);
	}

	Fac_Generate_Dynamics_Json(obj) {
		this.fac_generate_dynamics_json_event.emit(obj);
	}

	Fac_Generate_Factura_Json(obj) {
		this.fac_generate_factura_json_event.emit(obj);
	}

	Fac_New_Image_Loaded(obj) {
		this.fac_new_image_loaded_event.emit(obj);
	}

	Fac_Nullify(obj) {
		this.fac_nullify_factura_event.emit(obj);
	}

}
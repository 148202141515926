import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding, Input, Output, HostListener } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessagingService } from '../../services/messagingservice';
import { TokenService } from '../../services/token.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery: any;
declare var $: any;

@Component({
	selector: "revision-image",
	templateUrl: "revisionimage.component.html",
	styleUrls: ["revisionimage.component.css"]
})
export class ReceiptRevisionImageComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@ViewChild('bigPdfViewer', { static: true }) public bigPdfViewer;

	// Canvas variables
	// ------------------------------------------------
	@ViewChild('visbleCanvas', { static: true })
	visbleCanvas: ElementRef<HTMLCanvasElement>;
	private ctx: CanvasRenderingContext2D;

	constructor(private receiptService: ReceiptService, 
		private messagingService: MessagingService, 
		private cs: CacheService,
		private shared_data_service: SharedDataService) {
		this.messagingService.drawPolygonsOnCanvasEvent.subscribe((polygons) => {
			console.log("Message: drawPolygonsOnCanvasEvent #1");
			this.DrawPolygons(polygons);
		});

		this.messagingService.drawPolygonOnCanvasEvent.subscribe((polygon) => {
			console.log("Message: drawPolygonOnCanvasEvent #2");
			this.DrawPolygon(polygon);
		});

		this.messagingService.reloadImageInImageRevisionComponentEvent.subscribe(async () => {
			console.log("Message: reloadImageInImageRevisionComponentEvent #3");
			this.ResetImage_WithPolygons();
		});

		this.messagingService.triggerDrawSinglePolygonEvent.subscribe(async (words) => {
			console.log("Message: triggerDrawSinglePolygonEvent #4");

			this.ResetImage(() => {
				this.DrawPolygons(words);
			});
		});
	}

	// Component I/O Parameters
	// ------------------------------------------------
	@Input() imageUrl: string = "";
	@Input() receiptObject: any = {};
	@Input() encodedToken: any = null;
	@Input() ToggleModal: any = null;
	@Output() analizeClicked = new EventEmitter();
	@Output() reAnalizeClicked = new EventEmitter();
	@Output() imageLoaded = new EventEmitter();
	@Output() newImageUrl = new EventEmitter();

	newFile: any = null;
	reanalizeFileUrl = null;

	rot: number = 0;
	ratio: number = 0.6; // By default opens the bill with a 20% zoom out
	CanvasCrop: any;
	_highlightColor: string = "rgba(255, 0, 0, 0.2)";

	private selectedItemPolygons: any = [];
	imageLoadError: boolean = false;
	imageIsPDF: boolean = false;
	showReAnalizeButton: boolean = false;

	// Main Methods

	LoadImage(callbackFx) {
		return new Promise(async (resolve, reject) => {

			if (this.imageUrl === "" || this.imageUrl === undefined || this.imageUrl === "undefined") {
				// Hides the Spinner
				$(".spinner").hide();
				
				return;
			}
			this.imageLoadError = false;

			try {
				let url = this.imageUrl;
				let _this = this;

				$(".spinner").show();

				this.CanvasCrop = $.CanvasCrop({
					cropBox: ".imageBox",
					imgSrc: url,
					limitOver: 0,
					imageLoadedFx: () => {

						// Hides the Spinner
						$(".spinner").hide();

						// Adds the click Events to the Canvas
						var canvas = document.getElementById("visbleCanvas");
						canvas.addEventListener('click', function (e) {
						});

						_this.CanvasCrop.scale(this.ratio);
						_this.CanvasCrop.rotate(this.rot);

						$(".spinner").hide();

						if (callbackFx) {
							callbackFx();
							resolve({});
						}
						else {
							resolve({});
						}
					},
					imageLoadedErrorFx: (err) => {
						console.log("Error Fx Reached!", err);
						console.log("-> Image URL: ", _this.imageUrl);
						_this.imageLoadError = true;
					}
				});

			} catch (err) {
				console.log(err);
			}
		});
	}

	async ReAnalizeReceipt() {

		console.log("ReAnalizeReceipt: is PDF? ", this.imageIsPDF);

		if (this.imageIsPDF) {
			$("#reanalizeModal").modal("hide");
			return;
		}

		// This code interacts with the DOM.
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
		let uri = this.GetImageURI(canvas);

		$(".spinner").show();
		this.reAnalizeClicked.emit(uri);
		$(".spinner").hide();

		$("#reanalizeModal").modal("hide");
	}

	GetCanvas() {
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		return canvas;
	}

	GetImageURI(canvas) {
		if (!canvas || canvas === "") return "";

		// Converts the contents on the canvas to a serialized text that will be sent to the server
		// to be interpreted and analyzed.

		// get image URI from canvas object
		var imageURI = canvas.toDataURL("image/jpg");

		return imageURI;
	}

	OpenInOtherWindow() {
		window.open(this.imageUrl, "_blank");
	}

	OpenOriginalInOtherWindow() {
		window.open(this.receiptObject["imageUrl"], "_blank");
	}

	//Image Functions

	SetNewFile(e){
		this.newFile = e.currentTarget.files[0];
		$('#uploadFileModal').modal('show')
	}

	async UploadNewImgFile(e) {
		this.newFile = e.currentTarget.files[0];
		try {
			this.ToggleModal('pleaseWaitDialog',true);
			let r = await this.receiptService.UpdateReceiptImage(this.newFile, this.encodedToken)
			if(r['result']){
				this.newImageUrl.emit(r['data']['filename']);
				this.ToggleModal('pleaseWaitDialog',false);
				this.ToggleModal('reanalizeModal', true)
			}
		} catch (err) {
			console.log(err);
		}

	}

	async UploadNewRecieptImage(file) {
		// this.OpenPleaseWaitModal();
		// let r = await this.rs.UpdateReceiptImage(file, this.encodedToken);

	}

	// Polygon Methods

	DrawPolygon = (polygon) => {
		let color = this._highlightColor;
		let vs = polygon.vertices;
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		if (canvas != null) {
			let ctx = canvas.getContext("2d");
			let vArray = [];
			vs.forEach(v => { vArray.push(v.x); vArray.push(v.y); });
			var poly = vArray;
			ctx.fillStyle = color;
			ctx.lineWidth = 1;

			ctx.beginPath();
			ctx.moveTo(poly[0] * this.ratio, poly[1] * this.ratio);
			for (let item = 2; item < poly.length - 1; item += 2) { ctx.lineTo(poly[item] * this.ratio, poly[item + 1] * this.ratio) }

			ctx.closePath();
			ctx.fill();
		}
	}

	async DrawPolygons(wordMatches) {
		wordMatches.forEach(w => {
			this.DrawPolygon(w);
		});
	}

	// Image rotation + zoom in/out methods

	Rotate_Left() {
		this.rot -= 90;
		this.rot = this.rot < 0 ? 270 : this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.showReAnalizeButton = true;

	}

	Rotate_Right() {
		this.rot += 90;
		this.rot = this.rot > 360 ? 90 : this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.showReAnalizeButton = true;
	}

	Zoom_Out() {
		this.ratio = this.ratio - 0.3;
		if (this.ratio <= 0) this.ratio = 0.1; // Mínimo 10%
		this.CanvasCrop.scale(this.ratio);
		this.DrawPolygons(this.selectedItemPolygons);
		this.showReAnalizeButton = true;
		this.messagingService.TriggerDrawPolygons();
	}

	Zoom_In() {
		this.ratio = this.ratio + 0.3;
		this.CanvasCrop.scale(this.ratio);
		this.DrawPolygons(this.selectedItemPolygons);
		this.showReAnalizeButton = true;
		this.messagingService.TriggerDrawPolygons();
	}

	ReAnalize() {
		if (!this.imageIsPDF) {
			$('#reanalizeModal').modal('show')
			this.LoadImage(async () => {
				let r = await this.ReAnalizeReceipt();
			});
		}
	}

	async ResetImage_WithPolygons() {
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		await this.LoadImage(null);
		this.ngAfterViewInit();
	}

	async ResetImage(callbackfx) {
		// Redraws the image with no polygons.

		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		if (canvas) {
			let ctx = canvas.getContext("2d");
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			await this.LoadImage(callbackfx);
		}
	}

	// Component Methods
	// --------------------------------------------------

	async ngOnInit() {
		// Verify if the image is a PDF
		if (this.imageUrl != undefined && this.imageUrl.toUpperCase().indexOf(".PDF") >= 0) {
			this.imageIsPDF = true;
		}
		else {
			this.imageIsPDF = false;
		}

		if (this.receiptObject["OcrInfo"] !== undefined && (this.receiptObject["OcrInfo"]["imageText"] === null || this.receiptObject["OcrInfo"]["imageText"] === "")) {
			this.showReAnalizeButton = true;
		}
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.imageLoadError = false;

		// Verify if the image is not a PDF
		if (this.imageUrl != undefined && this.imageUrl.toUpperCase().indexOf(".PDF") < 0) {

			this.LoadImage(() => {
				// Image was loaded.
				var canvas = document.getElementById("visbleCanvas");
				var _imageURI = this.GetImageURI(canvas);
				this.imageLoaded.emit(_imageURI);
			});

		}
	}

	ngOnChanges(changes: SimpleChanges) {
	}
}
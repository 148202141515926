import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptComponent } from '../../pages/receipt/receipt.component';

import { Product } from '../../models/product';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: 'ngbd-typeahead-basic',
	templateUrl: './typeahead-basic.html',
	styles: [`.form-control { width: 300px; }`]
})
export class NgbdTypeaheadBasic implements OnChanges {
	public model: any;
	clickedItem:string;

	private productsList: any = [];

	@Output() selectedItemChanged = new EventEmitter();

	constructor(private receiptComponent: ReceiptComponent) {
		// Event Listener
		this.receiptComponent.dataUpdateEvent.subscribe(
			(data: any) => {
			  this.productsList = data;
			}
		);
	}

	getLookup(term) {
		let _this = this;
		return term.length < 2 ? [] : function() {
			return _this.productsList.filter(v => {
				if (v["name"].toLowerCase().indexOf(term.toLowerCase()) > -1) {
					return v["name"];
				}
			}).slice(0, 20);
		}();
	}

	selectedItem(item){
		this.clickedItem=item.item;
		this.selectedItemChanged.emit(item["item"]);
	}

	formatter = (product: Product) => product.name;
	
	clearInput($event) {
		$event.preventDefault();
		this.model = null;
		this.selectedItemChanged.emit(null);
		$("#typeahead-basic").focus();
	}

	search = (text$: Observable<string>) => 
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this.getLookup(term))
		);

	ngOnChanges(changes: SimpleChanges) {
		console.log("Changes hit!");
	}
}

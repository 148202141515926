import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Routes, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";

import { NgbdTypeaheadBasic } from "./components/autocomplete/typeahead-basic";
import { AppRoutingModule } from "./app-routing.module";

import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { ReceiptPosComponent } from "./pages/receiptpos/receiptpos.component";
import { ReceiptDetailsPageComponent } from "./pages/receiptdetails/receiptdetailspage.component";
import { Facturas_SearchPageComponent } from "./pages/facturas_search_page/facturas_search_page.component";

import { ReceiptImageComponent } from "./components/receiptimage/receiptimage.component";
import { ProductsListComponent } from "./components/productslist/productslist.component";
import { ProductFormComponent } from "./components/productform/productform.component";
import { AddedProductListComponent } from "./components/addedproductlist/addedproductlist.component";
import { RejectionReasonDropDown } from "./components/rejectionreasonsdropdown/rejectoionreasondropdown.component";
import { RejectionForm } from "./components/rejectionform/rejectionform.component";
import { Header } from "./components/header/haeder.component";
import { ReceiptRevisionComponent } from "./pages/receiptrevision/receiptrevision.component";
import { ReceiptRevisionImageComponent } from "./components/revisionimage/revisionimage.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { CartSimulator } from "./pages/cartsimulator/cartsimulator.component";
import { ReceiptInfoComponent } from "./components/receiptdetails/receiptinfo/receiptinfo.component";
import { ReceiptProductsComponent } from "./components/receiptdetails/receiptproducts/receiptproducts.component";

import { ProductSearch } from "./components/productsearch/productsearch.component";
import { SelectedProductsTable } from "./components/selectedproductstable/selectedproductstable.component";
import { CartSummary } from "./components/cartsummary/cartsummary.component";
import { SuggestedProductsTable } from "./components/suggestedproductstable/suggestedproductstable.component";

import { ProductsFilterPipe } from "./pipes/productsfilter";
import { OtherProductsFilterPipe } from "./pipes/otherproductsfilter";

// Facturas Search
import { facturas_search_boxesComponent } from "./components/facturas_search/facturas_search_boxes/facturas_search_boxes.component";
import { generic_dropdown_Component } from "./components/facturas_search/search_boxes/generic_dropdown/generic_dropdown.component";
import { country_dropdown_Component } from "./components/facturas_search/search_boxes/country_dropdown/country_dropdown.component";
import { pharmacy_dropdown_Component } from "./components/facturas_search/search_boxes/pharmacy_dropdown/pharmacy_dropdown.component";
import { date_picker_Component } from "./components/facturas_search/search_boxes/date_picker/date_picker.component";
import { priority_dropdown_Component } from "./components/facturas_search/search_boxes/priority_dropdown/priority_dropdown.component";
import { status_dropdown_Component } from "./components/facturas_search/search_boxes/status_dropdown/status_dropdown.component";
import { facturas_search_results_Component } from "./components/facturas_search/facturas_search_results/facturas_search_results.component";

// Facturas Review
import { factura_modal_Component } from "./components/facturas_search/facturas_review/factura-modal/factura_modal.component";
import { factura_modal_content_Component } from "./components/facturas_search/facturas_review/factura-modal/factura_modal_content.component";
import { factura_detail_form_Component } from "./components/facturas_search/facturas_review/factura_detail_form/factura_detail_form.component";
import { input_textbox_Component } from "./components/facturas_search/facturas_review/input_boxes/input_textbox/input_textbox.component";
import { input_dropdown_Component } from "./components/facturas_search/facturas_review/input_boxes/input_dropdown/input_dropdown.component";

// Main Controller (Factura operations)
import { Fac_Controller } from "./components/fac_controller/fac_controller.component";
import { Fac_Editor_Stats } from "./components/fac_editor_stats/fac_editor_stats.component";

// Factura Components
import { Fac_Header } from "./components/fac_header/fac_header.component";
import { Fac_Editables_Card } from "./components/fac_editables_card/fac_editables_card.component";
import { Fac_Editables_Form } from "./components/fac_editables_form/fac_editables_form.component";
import { Fac_Farmacias_Search_Box } from "./components/fac_farmacias_search_box/fac_farmacias_search_box.component";
import { Fac_Image } from "./components/fac_image/fac_image.component";
import { Fac_Analyzer } from "./components/fac_analyzer/fac_analyzer.component";
import { Fac_Dynamics_Sync } from "./components/fac_dynamics_sync/fac_dynamics_sync.component";
import { Fac_New_Image } from "./components/fac_new_image/fac_new_image.component";

// Non Abbott Products Components
import { Non_Abbott_Products_Card } from "./components/nap_card/nap_card.component";
import { Non_Abbott_Products_Search_Box } from "./components/nap_search_box/nap_search_box.component";
import { Non_Abbott_Selected_ProductsList } from "./components/nap_selected_list/nap_selected_list.component";
import { Non_Abbott_Validator } from "./components/nap_validator/nap_validator.component";

// Abbott Products Components
import { Abbott_Products_Card } from "./components/ap_card/ap_card.component";
import { Abbott_Products_Search_Box } from "./components/ap_search_box/ap_search_box.component";
import { Abbott_Selected_ProductsList } from "./components/ap_selected_list/ap_selected_list.component";
import { Abbott_Validator } from "./components/ap_validator/ap_validator.component";

// Revision de Factura Page
import { Revision_Factura_Page } from "./pages/revision_factura/revision_factura_page.component";

@NgModule({
  	imports: [
		BrowserModule,
		FormsModule,
		NgbModule,
		AppRoutingModule,
		RouterModule,
		NgxExtendedPdfViewerModule
	],
	declarations: [
		AppComponent,
		// Main global components
		Header,
		// Pages
		WelcomeComponent,
		ReceiptComponent,
		ReceiptPosComponent,
		CartSimulator,
		ReceiptDetailsPageComponent,
		Facturas_SearchPageComponent,
		// Pipes
		ProductsFilterPipe,
		OtherProductsFilterPipe,
		// Components & Libraries
		RejectionForm,
		NgbdTypeaheadBasic,
		ReceiptImageComponent,
		ProductsListComponent,
		ProductFormComponent,
		AddedProductListComponent,
		ReceiptRevisionComponent,
		RejectionReasonDropDown,
		ReceiptRevisionImageComponent,
		ProductSearch,
		SelectedProductsTable,
		CartSummary,
		SuggestedProductsTable,
		ReceiptInfoComponent,
		ReceiptProductsComponent,
		// Facturas Search
		facturas_search_boxesComponent,
		generic_dropdown_Component,
		country_dropdown_Component,
		pharmacy_dropdown_Component,
		date_picker_Component,
		priority_dropdown_Component,
		status_dropdown_Component,
		facturas_search_results_Component,
		// Facturas Review
		factura_modal_Component,
		factura_modal_content_Component,
		factura_detail_form_Component,
		input_textbox_Component,
		input_dropdown_Component,
		// Non Abbott Products
		Non_Abbott_Products_Card,
		Non_Abbott_Products_Search_Box,
		Non_Abbott_Selected_ProductsList,
		Non_Abbott_Validator,
		// Abbott Products
		Abbott_Products_Card,
		Abbott_Products_Search_Box,
		Abbott_Selected_ProductsList,
		Abbott_Validator,
		// Main Controller
		Fac_Controller,
		Fac_Editor_Stats,
		// Factura Revision Components
		Fac_Header,
		Fac_Editables_Card,
		Fac_Editables_Form,
		Fac_Farmacias_Search_Box,
		Fac_Image,
		Fac_Analyzer,
		Fac_Dynamics_Sync,
		Fac_New_Image,
		// Main Page
		Revision_Factura_Page
	],
	exports: [],
	bootstrap: [AppComponent],
	entryComponents: [factura_modal_content_Component],
})
export class AppModule {}

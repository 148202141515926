import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { Product } from '../../models/product';
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from "src/app/models/productadded";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "product-search",
	templateUrl: "productsearch.component.html",
	styleUrls: ["productsearch.component.css"]
})
export class ProductSearch implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	public model: any;
	clickedItem:string;

	@Input() productsList: any = [];
	@Output() selectedItemChanged = new EventEmitter();

	formatter = (product: Product) => product.name;

	constructor(private receiptService: ReceiptService, private messagingService: MessagingService) {
	}

	selectedItem(item){
		item.preventDefault();
		this.clickedItem=item.item;
		this.selectedItemChanged.emit(item["item"]);
		this.model = null;
	}

	getLookup(term) {
		let _this = this;
		return term.length < 2 ? [] : function() {
			return _this.productsList.filter(v => {
				if (v["name"].toLowerCase().indexOf(term.toLowerCase()) > -1) {
					return v["name"];
				}
			}).slice(0, 20);
		}();
	}

	search = (text$: Observable<string>) => 
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this.getLookup(term))
		);

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		// Gets the Products list
		let productsR = await this.receiptService.GetProducts('CR');
		this.productsList = productsR;
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
export const environment = {
    production: true,
    nodeServer: 'https://apipub.aboxplan.com',
    crmServer: 'https://aboxcrmapi.aboxplan.com',
    env: 'prod',
    receiptsBase: '/receipts',
    purchasesBase: '/purchases',
    generalBase: '/general',
    crmBase: '/crm',
    api_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImxveW1hcmthcGkiLCJhcGkiOnRydWUsImlhdCI6MTYwNTA3NjE0NX0.ADfqKywDLWCC5TZd18Mix7_GQPFwwEZJvtFa_roafL8',
    dynamics_api_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluIiwibmJmIjoxNjI0OTM0ODc2LCJleHAiOjE2MjQ5Mzg0NzYsImlhdCI6MTYyNDkzNDg3NiwiaXNzIjoiaHR0cHM6Ly9hYm94Y3JtYXBpLmFib3hwbGFuLmNvbSIsImF1ZCI6Imh0dHBzOi8vYWJveGNybWFwaS5hYm94cGxhbi5jb20vIn0.m9cHpEZPQCHxmy5RJqFSVPZLTWlD2zlZU1Ohndcb6ic'
};



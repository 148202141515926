import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ModalDismissReasons, NgbDateStruct, NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from '../../../../services/messagingservice';
import { Factura_SearchResults_Object } from '../../../../models/facturas_search';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "factura_detail_form",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "factura_detail_form.component.html",
	styleUrls: ["factura_detail_form.component.css"]
})
export class factura_detail_form_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() factura: Factura_SearchResults_Object;

	selectedStatusChanged(status) {
		console.log(status);
	}

    // Component Methods
	// --------------------------------------------------

	constructor(
		private ref: ChangeDetectorRef, 
		private messagingService: MessagingService) {
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';
import { timeStamp } from "console";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-analyzer",
	templateUrl: "fac_analyzer.component.html",
	styleUrls: ["fac_analyzer.component.css"]
})
export class Fac_Analyzer implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private factura: any = {};
	private factura_ocr_details: any = {};

	private analyzing: boolean = false;
	private analyzed: boolean = false;
	private analysis_error: boolean = false;
	private show_analyzer_status: boolean = true;

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
    }

	async analyze_image() {
		try {
			this.analyzing = true;

			// Sends the image to Google for analysis
			let analyze_response = await this.receipt_services.AnalizaReceiptUsingIdAndURL(this.factura["idFactura"], this.factura["imageUrl"]);
			
			// Updates the factura with the OCR details.
			let updateResponse = await this.receipt_services.UpdateOCRAnalysis(this.factura["idFactura"], this.factura["imageUrl"], escape(JSON.stringify(analyze_response["data"])));
			
			this.messagingService.Fac_Request_Fac_Reload_Ocr_Info({});

			this.analyzed = true;
			this.analyzing = false;
		}
		catch(err) {
			console.log(err);
			this.analysis_error = true;
			this.analyzing = false;
			this.messagingService.Fac_Controller_Hide_Please_Wait({});
		}
	}

    // Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		// Verifies if there is already a factura loaded in the shared data service
		// and extracts and converts the non abbott products to the current list.
		this.factura = await this.shared_data_service.fac_details;

		if (this.factura) {

			let url_imagen_de_factura = this.factura["imageUrl"];

			// Verifies if the factura is a pdf, in that case, no analysis is performed.
			if (url_imagen_de_factura != undefined && url_imagen_de_factura.toUpperCase().indexOf(".PDF") >= 0) {
				// The factura is a PDF, do nothing
				this.show_analyzer_status = false;
				this.analyzed = true;
			}
			else {
				// Verifies if the factura needs to be analyzed to extract the polygons from the image
				if (this.factura["OcrInfo"] !== undefined && this.factura["OcrInfo"]["imageText"] === null) {
					// Needs to be analyzed
					this.analyze_image();
				}
				else {
					if (this.factura["OcrInfo"] !== undefined) {
						this.factura_ocr_details = await this.shared_data_service.fac_ocr_details; //JSON.parse(unescape(this.factura["OcrInfo"]["imageText"]));
						this.analyzed = true;
					}
				}
			}
		}
	}

    async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
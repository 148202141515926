export class Factura_Search_Object {
    country: string;
    factura: string;
    pharmacy: string;
    nationalid: string;
    startdate: string;
    enddate: string;
    priority: string;
    status: string;
    page: number = 1;
    pagesize: number = 10;

    constructor(_country, _factura, _pharmacy, _nationalid, _startdate, _enddate, _priority, _status, _page, _pagesize) {
        this.country = _country;
        this.factura = _factura;
        this.pharmacy = _pharmacy;
        this.nationalid = _nationalid;
        this.startdate = _startdate;
        this.enddate = _enddate;
        this.priority = _priority;
        this.status = _status;
        this.page = _page;
        this.pagesize = _pagesize;
    }
}

export class Factura_SearchResults_Object {
    ID_Factura: string;
    Factura: string;
    Cedula: string;
    Estado: string;
    Farmacia: string;
    Fecha: string;
    Registrada: string;

    constructor(_ID_Factura, _Factura, _Cedula, _Estado, _Farmacia, _Fecha, _Registrada) {
        this.ID_Factura = _ID_Factura;
        this.Factura = _Factura;
        this.Cedula = _Cedula;
        this.Estado = _Estado;
        this.Farmacia = _Farmacia;
        this.Fecha = _Fecha;
        this.Registrada = _Registrada;
    }
}
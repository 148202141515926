export class ProductAdded {
    id: string = "";
    name: string = "";
    qty: number = 0;
    price: number = 0;

    constructor(_id, _name, _qty, _price) {
        this.id = _id;
        this.name = _name;
        this.qty = _qty;
        this.price = _price;
    }
}
import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding, ChangeDetectionStrategy } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessagingService } from '../../services/messagingservice';
import { TokenService } from '../../services/token.service';

import { environment } from '../../../environments/environment';

declare var jquery:any
declare var $ :any;

@Component({
	selector: "receipt-revision",
	templateUrl: "receiptrevision.component.html",
	styleUrls: ["receiptrevision.component.css"]
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptRevisionComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	
	@ViewChild('visbleCanvas', { static: true })
	visbleCanvas: ElementRef<HTMLCanvasElement>;
	private ctx: CanvasRenderingContext2D;

	dataLoaded: boolean = false;
	dataLoadError: boolean = false;

	private receiptData: any = {};
	receiptId: string = "";
	private encodedToken: string = "";
	private token: string = "";
	private userid: string = "";
	private imageUrl:string = "";
	private products:any = [];
	private receiptStatus: string = "";
	private receiptRejectinReason: string = "";

	rejectionProcessing: boolean = false;
	rejectionResponseMessage: string = "";
	private reloadToSelfUrl: string = "";

	_errorsFound: any = [];

	message: any = {
		title: "",
		body: ""
	};

	private redrawPolygonsOnScale:boolean = true;

	rot:number = 0;
    ratio:number = 0.6; // By default opens the bill with a 20% zoom out
	CanvasCrop: any;

	_highlightColor:string = "rgba(255, 0, 0, 0.2)";

	private selectedItemPolygons: any = [];

	constructor(
		private receiptService: ReceiptService, 
		private cacheService: CacheService,
		private route: ActivatedRoute,
		private router: Router,
		private messagingService: MessagingService) {

			this.receiptId = this.route.snapshot.params["id"];
			this.encodedToken = this.route.snapshot.params["token"];
	}

	// Public Methods
	// --------------------------------------------------

	async showInCanvas(source, element) {
		let wordMatches = [];
		switch(source) {
			case "factura":
				wordMatches = this.receiptData["factura"]["matches"];
				break;
			case "farmacia":
				wordMatches = this.receiptData["farmacia"]["matches"];
				break;
			case "fecha":
				wordMatches = this.receiptData["fecha"]["matches"];
				break;
			default:
				if (source.indexOf("producto-") >= 0) {
					let index = Number(source.replace("producto-",""));
					wordMatches = this.receiptData["products"][index]["found"];
				}
				break;
		}

		if (wordMatches.length > 0) {
			$(".table-row").removeClass("table-row-clicked");
			$("#" + element).addClass("table-row-clicked");

			this.selectedItemPolygons = wordMatches;
			this.messagingService.DrawPolygonsOnCanvas(this.selectedItemPolygons);
		}
	}


	// Internal Methods
	// --------------------------------------------------
	
	async GetReceiptData(receiptId) {
		try {
			this.rot = 0;
			this.ratio = 0.6;
			this.dataLoadError = false;
			this.dataLoaded = false;

			this.receiptService.SetToken(this.token);

			let r = await this.receiptService.GetReceiptDataWithProducts(receiptId);

			
			// Validates no error came from the service.
			if (r["header"]["code"] !== 0) {
				this.dataLoadError = true;
				return;
			}

			let responseBody = r["response"]["details"];
			this.receiptStatus = responseBody["BillData"]["Estado"];
			this.receiptRejectinReason = responseBody["BillData"]["Motivo"];

			let textSearchResults = responseBody["TextSearchResults"];
			this.imageUrl = responseBody["BillData"]["imageUrlProcessed"] === "" ? responseBody["BillData"]["imageUrl"] : responseBody["BillData"]["imageUrlProcessed"];
			this.products = textSearchResults["products"];

			this.products.forEach(p => { p["qty"] = p["cantidad"]; });

			this.receiptData = textSearchResults;
			
			this.dataLoaded = true;
		}
		catch(err) {
			this.dataLoaded = false;
			this.dataLoadError = true;
			console.log(err);
		}
	}

	ValidateItem(field, message1, message2) {
		let errorsFound = [];
		if (this.receiptData[field].matches.length === 0) {
			if (!this.receiptData[field]["checked"]||this.receiptData[field]["checked"] === undefined) {
				errorsFound.push({message:message1});
			}
		}
		else {
			if (!this.receiptData[field]["checked"]||this.receiptData[field]["checked"] === undefined) {
				errorsFound.push({message:message2});
			}
		}
		return errorsFound;
	}

	ValidateBeforeApproval() {
		this._errorsFound = [];
		let errorsFound = [];
		let response = [];

		response = this.ValidateItem("factura",
			"El número de factura no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que el número de factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		response = this.ValidateItem("fecha",
			"La fecha no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que la fecha de la factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		response = this.ValidateItem("farmacia",
			"La farmacia no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que la farmacia de la factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		this.products.forEach(p => {
			if (p.found.length === 0 && (!p["checked"]||p["checked"] === undefined)) { errorsFound.push({message:"'" + p.producto + "' no se encontró en la imagen."}); }
			if (p.found.length > 0 && !p["checked"]) { errorsFound.push({message:"Tiene que chequear el producto'" + p.producto + "' en la imagen."}); }
			if (p["qty"] === undefined || p["qty"] === 0 || p["qty"] === "") {
				errorsFound.push({message:"Tiene que chequear la cantidad del producto'" + p.producto + "' en la imagen."});
			}
			if (p["price"] === undefined || p["price"] === 0 || p["price"] === "") {
				errorsFound.push({message:"Tiene que chequear el precio del producto'" + p.producto + "' en la imagen."});
			}
		});


		this._errorsFound = errorsFound;

		if (errorsFound.length > 0) {
			$("#validationsResultsDialog").modal('show');
			return false;
		}
		return true;
	}

	ValidateBeforeRejection() {
		this._errorsFound = [];
		let errorsFound = [];
		let response = [];

		response = this.ValidateItem("factura",
			"El número de factura no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que el número de factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		response = this.ValidateItem("fecha",
			"La fecha no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que la fecha de la factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		response = this.ValidateItem("farmacia",
			"La farmacia no se encontró en la imagen, por favor chequear manualmente en la imagen.",
			"Tiene que chequear que la farmacia de la factura se encuentre en la imagen.");
		errorsFound = errorsFound.concat(response);

		this.products.forEach(p => {
			if (p.found.length === 0 && (!p["checked"]||p["checked"] === undefined)) { errorsFound.push({message:"'" + p.producto + "' no se encontró en la imagen."}); }
			if (p.found.length > 0 && !p["checked"]) { errorsFound.push({message:"Tiene que chequear el producto'" + p.producto + "' en la imagen."}); }
			if (p["qty"] === undefined || p["qty"] === 0 || p["qty"] === "") {
				errorsFound.push({message:"Tiene que chequear la cantidad del producto'" + p.producto + "' en la imagen."});
			}
			if (p["price"] === undefined || p["price"] === 0 || p["price"] === "") {
				errorsFound.push({message:"Tiene que chequear el precio del producto'" + p.producto + "' en la imagen."});
			}
		});


		this._errorsFound = errorsFound;

		if (errorsFound.length > 0) {
			$("#validationsResultsDialog").modal('show');
			return false;
		}
		return true;
	}

	checkValue(checked:any, source:string) {
		switch(source) {
			case "factura":
				this.receiptData.factura["checked"] = checked;
				break;
			case "fecha":
				this.receiptData.fecha["checked"] = checked;
				break;
			case "farmacia":
				this.receiptData.farmacia["checked"] = checked;
				break;
			default:
				if (source.indexOf("producto-") >= 0) {
					let index = Number(source.replace("producto-",""));
					this.receiptData["products"][index]["checked"] = checked;
				}
				break;
		}
	}

	// Image Operations
	// ------------------------------------------------
	
	reAnalizeBtnClicked(e) {
		this.ReAnalizeReceipt();
	}

	GetImageURI(canvas) {
		if (!canvas || canvas === "") return "";

		// Converts the contents on the canvas to a serialized text that will be sent to the server
		// to be interpreted and analyzed.

		// get image URI from canvas object
		var imageURI = canvas.toDataURL("image/jpg");

		return imageURI;
	}

	async ReAnalizeReceipt() {
		// This code interacts with the DOM.
		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
		let uri = this.GetImageURI(canvas);

		$("#pleaseWaitDialog").modal('show');

		let r = await this.receiptService.AnalizeReceipt(this.receiptId, uri);

		if (r["result"]) {

			location.reload();

		}
		else {
			// Handle error when re-analizing the receipt
			$("#pleaseWaitDialog").modal('hide');

			this.message.title = "Espere";
			this.message.body = "Ocurrió un problema al re-analizar la factura.";
			$('#messageModal').modal('show');
		}
	}

	// UI Operations
	// ------------------------------------------------

	DisableAllButtons() {
		$(".btn-fx").attr("disabled", true);
	}

	OpenRejectModal() {
		this.rejectionResponseMessage = "";
		this.messagingService.ResetRejectionReasonFormEvent("");
		$("#rechazarFaturaModal").modal('show');
	}

	HandleRejectionLoading(e) {
		this.rejectionProcessing = true;
	}

	HandleRejectionResponse(e) {
		this.rejectionProcessing = false;
		this.rejectionResponseMessage = "";

		if (e["result"]) {
			$("#rechazarFaturaModal").modal('hide');
			this.message.title = "Listo!";
			this.message.body = e["message"];
			$('#messageModal').modal('show');
			this.GetReceiptData(this.receiptId);
		}
		else {
			// Handle Error
			this.rejectionResponseMessage = e["message"];
		}
		
	}

	// Button Operations
	// --------------------------------------------------

	async Approve() {
		try {

			if (!this.ValidateBeforeApproval()) { return; }

			$("#pleaseWaitDialog").modal('show');

			let r = await this.receiptService.ApproveReceipt(this.receiptId, this.userid, this.products);

			$("#pleaseWaitDialog").modal('hide');

			// Validates the response
			if (r["response"]["code"] != "0") {
				// Handle service internal error
				this.message.title = "Ocurrió un problema";
				this.message.body = r["response"]["message"];
				$('#messageModal').modal('show');
			}
			else {
				this.message.title = "Listo!";
				this.message.body = r["response"]["message"];
				$('#messageModal').modal('show');
				this.GetReceiptData(this.receiptId);
			}
		}
		catch(err) {
			console.log("Error approving the receipt:", err);
		}
	}

	async Reject() {
		try {

			if (!this.ValidateBeforeApproval()) { return; }

			$("#pleaseWaitDialog").modal('show');

			let r = await this.receiptService.RejectReceipt(this.receiptId, this.userid, this.products);

			$("#pleaseWaitDialog").modal('hide');

			// Validates the response
			if (r["response"]["code"] != "0") {
				// Handle service internal error
				this.message.title = "Ocurrió un problema";
				this.message.body = r["response"]["message"];
				$('#messageModal').modal('show');
			}
			else {
				this.message.title = "Listo!";
				this.message.body = r["response"]["message"];
				$('#messageModal').modal('show');
				this.GetReceiptData(this.receiptId);
			}

			this.DisableAllButtons();
		}
		catch(err) {
			console.log("Error approving the receipt:", err);
		}
	}
	
	// Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		
		// Validates if there is a token and receipt
		if (!this.encodedToken || this.encodedToken === "") { this.router.navigate(['/']); return; }

		let ts = new TokenService();
		let tokenresponse = ts.Decode(this.encodedToken);
		if (tokenresponse.result) {
			this.receiptId = tokenresponse["data"]["receiptid"];
			this.token = tokenresponse["data"]["token"];
			this.userid = tokenresponse["data"]["user"];
			this.GetReceiptData(this.receiptId);
		}
		else {
			// Invalid token.
			this.router.navigate(['/']); return;
		}
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
		this.route.params.subscribe(params => {
			this.reloadToSelfUrl = params["token"];
		});
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { Product } from '../../models/product';
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from "src/app/models/productadded";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "selectedproducts-table",
	templateUrl: "selectedproductstable.component.html",
	styleUrls: ["selectedproductstable.component.css"]
})
export class SelectedProductsTable implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() selectedProducts: any = [];

	constructor(private messagingService: MessagingService) {
	}

	BorrarProducto(idx) {
		this.selectedProducts.splice(idx, 1)
		this.messagingService.UpdateCart();
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "date_picker",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "date_picker.component.html",
	styleUrls: ["date_picker.component.css"]
})
export class date_picker_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() selectedDate: string = "";
	@Input() defaultDate: string = "";
	@Output() selectedDateChangedEvent = new EventEmitter<NgbDateStruct>();

	public onDateSelect(selectedDate) {
		this.selectedDateChangedEvent.emit(selectedDate);
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		if (this.defaultDate != '') {
			this.selectedDate = this.defaultDate;
			this.onDateSelect(this.defaultDate);
		}
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
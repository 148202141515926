import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: 'view-header',
	templateUrl: './header.component.html',
	styleUrls: ["header.component.css"]
})
export class Header implements OnInit, OnChanges {

	@Input() title: string = "";

	constructor() {
    }

    // Component Required Methods

	ngOnInit() {
    }

	ngOnChanges(changes: SimpleChanges) {
	}
}

import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-editables-card",
	templateUrl: "fac_editables_card.component.html",
	styleUrls: ["fac_editables_card.component.css"]
})
export class Fac_Editables_Card implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
    }

    // Component basic methods...
	// --------------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ModalDismissReasons, NgbDateStruct, NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from '../../../../../services/messagingservice';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "input_dropdown",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "input_dropdown.component.html",
	styleUrls: ["input_dropdown.component.css"]
})
export class input_dropdown_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() title: string;
    @Input() placeholder: string;
	@Input() value: string;
	@Input() datalist: any[];
	@Input() labelfield: string = "";
	@Input() keyfield: string = "";
	@Output() selectedItemChangedEvent = new EventEmitter<string>();

	Select(selectedItem) {
		this.value = selectedItem[this.labelfield];
		this.selectedItemChangedEvent.emit(selectedItem);
	}

    // Component Methods
	// --------------------------------------------------

	constructor(
		private ref: ChangeDetectorRef, 
		private messagingService: MessagingService) {
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { Injectable } from '@angular/core';

// Environment Setup
import { environment } from '../../environments/environment.prod';

import { } from 'axios';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { resolve } from 'url';

@Injectable({ providedIn: 'root', })
export class DynamicsService {

    generate_dynamics_json(factura) {
        try {
            let parseMedioCompra = (medio) => {
                switch(medio){
                    case "Farmacia": return 1;
                    case "Domicilio": return 2;
                    default: return 1;
                }
            }
    
            let mapAbbotProds = (abbotProds) => {
                let arr = [];
                abbotProds.forEach(prod => {
                    let p = {id: prod.ID_Producto , quantity: prod.Cantidad , price: prod.Precio}
                    arr.push(p);
                });
    
                return arr;
            }
    
            let mapNonAbbotProds = (nonAbbotProds) => {
                let arr = [];
                nonAbbotProds.forEach(prod => {
                    let p = {name: prod.Producto_Otros , quantity: prod.Cantidad_Otros , price: prod.Precio_Otros}
                    arr.push(p);
                });
    
                return arr;
            }
    
            // console.log('Request Body: ',this.updateRequestBody);
            var updateDynamicsJson: any = {
                "country": factura.country, 
                "idFromDatabase": factura.idFactura, 
                "patientId": -1, 
                "pharmacyId": factura.Farmacia.ID, 
                "billId": factura.Factura, 
                "billDate": factura.Fecha, 
                "billImageUrl": "", 
                "products": mapAbbotProds(factura.Products), 
                "nonAboxProducts": mapNonAbbotProds(factura.NonAbbottProducts), 
                "status": null, 
                "statusReason": null, 
                "totalAmount": factura.MontoTotal, 
                "revisionTime1": 0, 
                "revisionTime2": null, 
                "purchaseMethod": parseMedioCompra(factura.MedioCompra)
            }
    
            var x = JSON.parse(JSON.stringify(updateDynamicsJson));
            
            return {
                result: true,
                message: "",
                data: x
            }
        }
        catch(err) {
            return {
                result: false, 
                message: "Error al generar el json para dynamics.",
                data: err
            }
        }
    }

}
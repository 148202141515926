import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery: any;
declare var $: any;

@Component({
	selector: "fac-header",
	templateUrl: "fac_header.component.html",
	styleUrls: ["fac_header.component.css"]
})
export class Fac_Header implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private factura: any = {};

	constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService,
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
	}

	// Actions
	// --------------------------------------------------------

	invoke_factura_approve() {
		this.messagingService.Fac_Approve({});
	}

	invoke_factura_reject() {
		this.messagingService.Fac_Reject({});
	}

	invoke_factura_save() {
		this.messagingService.Fac_Save({});
	}

	invoke_nullify_factura() {
		this.messagingService.Fac_Nullify(this.factura);
	}

	close() {
		window.close();
	}

	// Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		this.factura = await this.shared_data_service.fac_details;
	}

	async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
	}
}
import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "receipt-pos",
	templateUrl: "receiptpos.component.html",
	styleUrls: ["receiptpos.component.css"]
})
export class ReceiptPosComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	// Page Parameters
	private receiptId: string = "";
	private token: string = "";

	constructor(
		private route: ActivatedRoute,
		private router: Router) {
			this.receiptId = this.route.snapshot.params["id"];
			this.token = this.route.snapshot.params["token"];
	}

	// Public Methods
	// --------------------------------------------------


	// Internal Methods
	// --------------------------------------------------
    
    
	// Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		// Validates if there is a token and receipt
		if (!this.token || this.token === "") {  this.router.navigate(['/']); return;  }

		console.log("Auth Token %s", this.token);
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
        console.log("ReceiptComponent Changes:", changes);
    }
}
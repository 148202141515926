import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, HostListener  } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { MessagingService } from '../../services/messagingservice';
import { Subject, interval } from "rxjs";
// Environment Setup
import { environment } from '../../../environments/environment.prod';

@Component({
	selector: "facturas_search-page",
	templateUrl: "facturas_search_page.component.html",
	styleUrls: ["facturas_search_page.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Facturas_SearchPageComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    private encodedToken: string = "";
	public receiptId: string = "";
	private token: string = "";
	private userid: string = "";

    constructor(
		private rs: ReceiptService,
		private route: ActivatedRoute,
		private router: Router,
		private ms: MessagingService,
		private changeDetector: ChangeDetectorRef
	) {

		// this.encodedToken = this.route.snapshot.params["token"];
    }
    
    async ngOnInit() {

		// Validates if there is a token and receipt
		if (!this.encodedToken || this.encodedToken === "") {

			console.log("No Token Received.")
			return;
		}

		let ts = new TokenService();
		let tokenresponse = ts.Decode(this.encodedToken);
		if (tokenresponse.result) {
			this.receiptId = tokenresponse["data"]["receiptid"];
			this.token = tokenresponse["data"]["token"];
			this.userid = tokenresponse["data"]["user"];

			console.log("Auth Token: ", this.token);
			console.log("Fact ID: ", this.receiptId);

			this.rs.SetToken(this.token);
		}
		else {
			// Invalid token.
			console.log("Invalid Token.")
		}
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
    }
    
    ngOnChanges(changes: SimpleChanges) {
	}
}
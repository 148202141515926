import { Input, Component, OnInit, OnDestroy, AfterViewInit, ViewChild, SimpleChanges, ElementRef, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-new-image",
	templateUrl: "fac_new_image.component.html",
	styleUrls: ["fac_new_image.component.css"]
})
export class Fac_New_Image implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	newFile: any = null;

	private factura: any = {};
	private token: string = "";

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
    }

	// Image File Input methods...
	// --------------------------------------------------------

	async UploadNewImgFile(e) {
		this.newFile = e.currentTarget.files[0];

		try {
			this.token = await this.shared_data_service.fac_token;
			$("#confirm_new_image_modal").modal('show');
		} catch (err) {
			console.log(err);
		}
	}

	async approve_new_image() {
		$("#confirm_new_image_modal").modal('hide');

		let r = await this.receipt_services.UpdateReceiptImage(this.newFile, this.token);

		console.log(r);

		if(r['result']){

			let new_image_file = r['data']['filename'];
			this.messagingService.Fac_New_Image_Loaded(new_image_file);

			// this.newImageUrl.emit();
			// this.ToggleModal('pleaseWaitDialog',false);
			// this.ToggleModal('reanalizeModal', true)
		}
		else {
			// Handle Image Upload Error
		}

		$('#new_image').val('');
	}

	async cancel_new_image() {
		$('#new_image').val('');
		$("#confirm_new_image_modal").modal('hide');
	}

    // Component basic methods...
	// --------------------------------------------------------

    async init_operations() {
		this.factura = await this.shared_data_service.fac_details;
	}

    async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.factura = await this.shared_data_service.fac_details;
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root', })
export class Utilities {

	constructor() {
    }
    
    public Replace(stringToAnalize, charToFind, charReplaceValue) {
        if (stringToAnalize.indexOf(charToFind) >= 0) {
            stringToAnalize = stringToAnalize.replace(charToFind, charReplaceValue);
            return this.Replace(stringToAnalize, charToFind, charReplaceValue);
        }
        else {
            return stringToAnalize;
        }
    }

    public JSONDateParse(jsondate: string, defaultvalue: string) {
        let d: Date;
        if (jsondate === undefined || jsondate === null) return defaultvalue;
        d = new Date(jsondate);
        return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    }

    public is_object_empty(empty) {
        if (Object.keys(empty).length === 0 && empty.constructor === Object) return true;
        return false;
    }
}
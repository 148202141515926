import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { ReceiptService } from '../../services/receipt.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "ap_selected_list",
	templateUrl: "ap_selected_list.component.html",
	styleUrls: ["ap_selected_list.component.css"]
})
export class Abbott_Selected_ProductsList implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private selected_products_list: any = []; // {product:"PRODUCTO DE PRUEBA"}
	private factura: any = {};
	private factura_ocr_details: any = {};
	private undo_heap: any = [];

	update_shared_products() {
		this.shared_data_service.fac_ap_products = this.selected_products_list;
		this.messagingService.Ap_Product_List_Changed(this.selected_products_list);
	}

    constructor(private messagingService: MessagingService, private receiptService: ReceiptService, private shared_data_service: SharedDataService) {
		this.messagingService.ap_add_product_to_list_event.subscribe((product_to_add) => {

			/* 
			categoria: "NUTRICION Y BIENESTAR"
			description: "Nutrición líquida completa y balanceada formulada con Nutrivigor para apoyar la salud muscular"
			division: "ANI"
			familia: "ENSURE"
			id: "056482034034126"
			name: "ENSURE ADVANCE LÍQUIDO FRESA 237 mL"
			*/

			let found = this.selected_products_list.filter(s => s["name"] === product_to_add["name"]);

			if (found.length > 0) {
				// Alredy exists.
			}
			else {
				this.selected_products_list.push(product_to_add);
				this.update_shared_products();
			}
        });
    }
    
	delete_item(item_to_delete) {
		this.undo_heap.push(item_to_delete);
		this.selected_products_list = this.selected_products_list.filter(s => s["name"] !== item_to_delete["name"] );
		this.update_shared_products();
	}

	undo() {
		if (this.undo_heap.length > 0) {
			let item_to_add = this.undo_heap[0];
			this.undo_heap = this.undo_heap.slice(1);
			this.selected_products_list.push(item_to_add);
			this.update_shared_products();
		}
	}

	delete_all() {
		this.undo_heap = [];
		this.selected_products_list = [];
		this.update_shared_products();
	}

	trigger_change(event) {
		this.update_shared_products();
	}

	focus(dom_object) {
		let click_object = {
			'obj_type': 'abbott-product',
			'object': dom_object
		}
		this.messagingService.Fac_Field_Clicked(click_object);
	}

	eval_ocr_field(id_producto) {
		if (this.factura_ocr_details === null || this.factura_ocr_details === undefined) {
			return "";
		}

		if (this.factura_ocr_details["products"] && Array.isArray(this.factura_ocr_details["products"])) {
			for (var i=0; i<this.factura_ocr_details["products"].length; i++) {
				let p = this.factura_ocr_details["products"][i];
				if (p["ID_Producto"] === id_producto) {
					if (p["hasMatches"]) {
						return true;
					}
				}
			}
		}

		return false;
	}

	valuechange(e, field, product) {
		let v = product[field];
		product["is_valid"] = !isNaN(Number(v));
	}

    // Component basic methods...
	// --------------------------------------------------------

	async reload_ocr_info() {
		// Pulls the OCR details from the shared data service
		this.factura_ocr_details = await this.shared_data_service.fac_ocr_details;

		this.selected_products_list.forEach(p => {
			p['ocr_producto'] = this.eval_ocr_field(p["id"]);
		});
	}

	async init_operations() {
		// Verifies if there is already a factura loaded in the shared data service
		// and extracts and converts the non abbott products to the current list.
		let abbott_products_in_factura = [];
		this.factura = await this.shared_data_service.fac_details;
		this.selected_products_list = [];
		if (this.factura) {
			abbott_products_in_factura = this.factura["Products"];

			if (abbott_products_in_factura !== undefined) {
				abbott_products_in_factura.forEach(p => {
					this.selected_products_list.push({
						"id": p["ID_Producto"],
						"name": p["Producto"],
						"qty": p["Cantidad"],
						"price": p["Precio"],
					});
					this.update_shared_products();
				});
			}
			
			this.reload_ocr_info();
		}
	}

    async ngOnInit() {

		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.messagingService.fac_ocr_info_updated_event.subscribe(message => {
			this.reload_ocr_info();
		});

		this.init_operations();
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "generic_dropdown",
	templateUrl: "generic_dropdown.component.html",
	styleUrls: ["generic_dropdown.component.css"]
})
export class generic_dropdown_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() placeholdertext: string = "";
	@Input() datalist: string = "";
	@Input() labelfield: string = "";
	@Input() keyfield: string = "";
	@Input() defaultindex: number = 0;
	@Output() selectedItemChangedEvent = new EventEmitter<string>();

	ItemClicked(selectedItem) {
		this.placeholdertext = selectedItem[this.labelfield];
		this.selectedItemChangedEvent.emit(selectedItem);
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		if (this.defaultindex !== -1) {
			this.placeholdertext = this.datalist[this.defaultindex][this.labelfield];
		}
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { ReceiptApprovalRequest, ReceiptApprovalCallCenterRequest, ReceiptApprovalCallCenterRequest_Product } from '../models/receipt-approval-request';
import { ReceiptRejectionRequest } from '../models/receipt-rejection-request';
import { Factura_Search_Object, Factura_SearchResults_Object } from '../models/facturas_search';
import { LimitConsumption, ResponseLimitConsumptionMonthly, ResponseLimitConsumptionYearly } from '../interface/ILimitConsumption';

// Environment Setup

import { environment } from '../../environments/environment.prod';
import { } from 'axios';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { resolve } from 'url';


@Injectable({ providedIn: 'root', })
export class ReceiptService {

	public cacheDataKey: string = "ReceiptDataCache";
	public cacheAnalizeKey: string = "AnalizeDataCache";
	public cacheProducts: string = "ProductsCache";
	public cacheNonAbbotProducts: string = "NonAbbotProductsCache";

	private _useCache: boolean = false;
	private authorizationToken: string = "";

	private _baseServerUrl: string = environment.nodeServer;
	private _baseCRMServerUrl: string = environment.crmServer;

	// Receipts: 	9006
	// Purchases: 	9008
	// CRM:			9012

	// API Urls:

	// Receipts Ops
	private _analizeUrl: string = this._baseServerUrl + environment.receiptsBase + "/uploadmodifiedreceipt";
	private _uploadAndReAnalizeUrl: string = this._baseServerUrl + environment.receiptsBase + "/uploadandreanalize";
	private _getReceiptDataUrl: string = this._baseServerUrl + environment.receiptsBase + "/billtext";
	private _getReceiptDetailsWithProductsArray: string = this._baseServerUrl + environment.receiptsBase + "/receipts/details";
	private _getOCRAnalysis: string = this._baseServerUrl + environment.receiptsBase + "/ocr/anlysis";
	private _updateOCRAnalysis: string = this._baseServerUrl + environment.receiptsBase + "/ocr/update";
	private _getReceiptDataWithProductsUrl: string = this._baseServerUrl + environment.receiptsBase + "/billtextandproducts";
	private _readTextOnReceiptUrl: string = this._baseServerUrl + environment.receiptsBase + "/receipts/readtext";
	private _processReceiptUrl: string = this._baseServerUrl + environment.receiptsBase + "/processreceipt";
	private _uploadReceiptImage: string = this._baseServerUrl + environment.receiptsBase + "/receipts/uploadmodifiedreceipt";
	private _getConsumptionLimitMonthlyUrl: string = this._baseServerUrl + environment.purchasesBase + "/consumptionlimitcheck/monthly";
	private _getConsumptionLimitYearlyUrl: string = this._baseServerUrl + environment.purchasesBase + "/consumptionlimitcheck/yearly";
	// General Ops
	private _getProductsUrl: string = this._baseServerUrl + environment.generalBase + "/productstoredeem/";
	private _getDrugStoresByCountryId = this._baseServerUrl + environment.generalBase + '/pharmacies/';
	private _getAboxProductList = this._baseServerUrl + environment.generalBase + '/drugs/';
	private _getNonAboxProductList = this._baseServerUrl + environment.receiptsBase + '/ocr/nonabbottproducts/';

	// Purchases Ops
	private _approveReceiptServiceURL: string = this._baseServerUrl + environment.purchasesBase + "/approve_callcenter";
	private _rejectReceiptURL: string = this._baseServerUrl + environment.purchasesBase + "/reject";

	// CRM Ops
	private _updateCRMFactura: string = this._baseServerUrl + environment.crmBase + "/crmops/updatefactura";
	private _updateCRMFacturaImage: string = this._baseServerUrl + environment.receiptsBase + "/image/uploadandreplace";

	//Mycrosoft Dynamics Ops
	private _dynamicsUpdateURL: string = this._baseCRMServerUrl + "/api/invoices/update";
	// Factura Search Objects
	private factura_search_object: Factura_Search_Object;



	constructor(private cacheService: CacheService) {
	}

	// Properties
	_cacheDataKey() { return this.cacheDataKey; }
	_cacheAnalizeKey() { return this.cacheAnalizeKey; }
	_cacheProducts() { return this.cacheProducts; }

	SetToken(token) {
		this.authorizationToken = token;
	}

	ClearCache(key) {
		localStorage.removeItem(key);
	}

	GetReceiptData(receiptid) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				let _this = this;
				let cacheKey = this.cacheDataKey + "-" + receiptid;

				let cachedData = await this.cacheService.Get(cacheKey, null);

				if (cachedData != null && cachedData != "" && this._useCache) {
					console.log("--> Caché");

					resolve(cachedData);
				}
				else {
					console.log("--> Service");

					let response = await fetch(this._getReceiptDataUrl, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						body: JSON.stringify({
							"billId": receiptid
						})
					})
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {
							// Cleans up the response data
							_this.cacheService.Store(cacheKey, data);
							resolve(data);
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	AnalizeReceipt(receiptid, imageURI) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				let cacheKey = this.cacheAnalizeKey + "-" + receiptid;
				let cachedData = await this.cacheService.Get(cacheKey, "");

				if (cachedData != null && cachedData != "" && this._useCache) {
					resolve({ result: true, data: cachedData });
				}
				else {
					var apiURl = this._analizeUrl;

					let _this = this;

					fetch(apiURl,
						{
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + this.authorizationToken
							},
							body: JSON.stringify({
								"receiptid": receiptid,
								"receiptimage": imageURI
							})
						}
					)
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {
							// Extracts the words found (polygons)
							if (data.header.code === 0) {
								_this.cacheService.Store(cacheKey, data.response);
								resolve({ result: true, data: data.response });
							}
							else {
								resolve({ result: false, data: data, message: "Error al traer los datos." });
							}
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	GetProducts(countryId) {
		return new Promise(async (resolve, reject) => {
			try {
				let _this = this;
				let cacheKey = this.cacheProducts;

				let cachedData: any = [];
				cachedData = await this.cacheService.Get(cacheKey, null);

				if (cachedData != null && cachedData != "" && this._useCache) {
					resolve(cachedData);
				}
				else {
					let response = await fetch(this._getProductsUrl + countryId, {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}
					})
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {
							// Cleans up the response data
							if (data["response"]["code"] === 0) {
								let cleanedData = data["response"]["details"]["products"];
								_this.cacheService.Store(cacheKey, cleanedData);
								resolve(cleanedData);
							}
							else {
								resolve([]);
							}
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	GetReceiptDataWithProducts(receiptid) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				let _this = this;
				let cacheKey = this.cacheDataKey + "-" + receiptid;

				let cachedData = await this.cacheService.Get(cacheKey, null);

				if (cachedData != null && cachedData != "" && this._useCache) {
					resolve(cachedData);
				}
				else {

					let response = await fetch(this._getReceiptDataWithProductsUrl, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						body: JSON.stringify({
							"billId": receiptid
						})
					})
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {
							// Cleans up the response data
							_this.cacheService.Store(cacheKey, data);
							resolve(data);
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	UploadAndReAnalizeReceipt(receiptid, imageURI) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				let cacheKey = this.cacheAnalizeKey + "-" + receiptid;
				let cachedData = await this.cacheService.Get(cacheKey, "");

				if (cachedData != null && cachedData != "" && this._useCache) {
					resolve({ result: true, data: cachedData });
				}
				else {
					var apiURl = this._uploadAndReAnalizeUrl;

					let _this = this;

					fetch(apiURl,
						{
							method: 'POST',
							mode: 'no-cors',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + this.authorizationToken
							},
							body: JSON.stringify({
								"billId": receiptid,
								"receiptimage": imageURI
							})
						}
					)
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {

							// Extracts the words found (polygons)
							if (data.header.code === 0) {
								_this.cacheService.Store(cacheKey, data.response);
								resolve({ result: true, data: data.response });
							}
							else {
								resolve({ result: false, data: data, message: "Error al traer los datos." });
							}
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	UploadResizedReceiptImage(receiptid, imageURI) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				var apiURl = this._uploadReceiptImage;

				axios(apiURl,
					{
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						data: {
							"receiptid": receiptid,
							"receiptimage": imageURI
						}
					}
				)
					.then(function (res) {
						return res;
					})
					.then(function (response) {

						if (response.data.header.code === 0) {
							resolve({ result: true, data: response.data.response, message: "" });
						}
						else {
							resolve({ result: false, data: response.data, message: "Error al traer los datos." });
						}
					});
			}
			catch (err) {
				reject(err);
			}
		});
	}

	AnalizaReceiptUsingIdAndURL(receiptId, imageURL) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				var apiURl = `${environment.nodeServer}${environment.receiptsBase}/ocr/anlysis`;

				axios(
					apiURl,
					{
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						data: {
							"billId": receiptId,
							"urlToProcess": imageURL
						}
					}
				)
					.then(function (res) {
						return res;
					})
					.then(function (response) {

						if (response.status === 200) {
							resolve({ result: true, data: response.data, message: "" });
						}
						else {
							resolve({ result: false, data: response.data, message: "Error al traer los datos." });
						}
					});
			}
			catch (err) {
				reject(err);
			}
		});
	}

	// Receipt Information Operations

	GetReceiptDetailsWithProductsArray(receiptid) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				let _this = this;
				let cacheKey = this.cacheDataKey + "-" + receiptid;

				let cachedData = await this.cacheService.Get(cacheKey, null);

				if (cachedData != null && cachedData != "" && this._useCache) {
					resolve(cachedData);
				}
				else {

					await fetch(this._getReceiptDetailsWithProductsArray, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						body: JSON.stringify({
							"billId": receiptid
						})
					})
						.then(function (res) {
							return res.json();
						})
						.then(function (data) {
							// Cleans up the response data
							_this.cacheService.Store(cacheKey, data);
							resolve(data);
						});
				}
			}
			catch (err) {
				reject(err);
			}
		});
	}

	GetDrugStoresData(countryId) {
		return new Promise(async (resolve, reject) => {
			try {
				var apiURl = this._getDrugStoresByCountryId + countryId;

				axios(apiURl,
					{
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
						}
					}
				)
					.then(function (res) {
						return res;
					})
					.then(function (response) {

						if (response.status === 200) {
							resolve({ result: true, data: response.data.response.details.usertypes, message: "" });
						}
						else {
							resolve({ result: false, data: response.data, message: "Error al traer los datos." });
						}
					});
			}
			catch (err) {
				reject(err);
			}
		});
	}

	GetAboxProductList(countryId) {
		return new Promise(async (resolve, reject) => {
			try {
				var apiURl = this._getAboxProductList + countryId;

				axios(apiURl,
					{
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
						}
					}
				)
					.then(function (res) {
						return res;
					})
					.then(function (response) {

						if (response.status === 200) {
							resolve({ result: true, data: response.data.response.details.drugs, message: "" });
						}
						else {
							resolve({ result: false, data: response.data, message: "Error al traer los datos." });
						}
					});
			}
			catch (err) {
				reject(err);
			}
		});
	}

	GetNonAbbotProductList(countryid) {
		return new Promise(async (resolve, reject) => {
			try {
				// console.log('Country Id:',countryid)
				// console.log('Search:',searchtext)
				var apiURl = this._getNonAboxProductList;
				let _this = this;
				let cacheKey = this.cacheNonAbbotProducts;

				let cachedData: any = [];
				cachedData = await this.cacheService.Get(cacheKey, null);

				if (cachedData != null && cachedData != "" && this._useCache) {
					console.log('From Cache')
					resolve(cachedData);
				}
				else {
					axios(apiURl,
						{
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + this.authorizationToken
							},
							data: {
								"countryid": countryid,
								"searchtext": ''
							}
						}
					)
						.then(function (res) {
							return res;
						})
						.then(function (response) {

							if (response.status === 200) {
								let cleanedData = response.data.data.products;
								_this.cacheService.Store(cacheKey, cleanedData);
								resolve({ result: true, data: cleanedData, message: "" });
							}
							else {
								resolve({ result: false, data: response.data, message: "Error al traer los datos." });
							}
						});
				}


			}
			catch (err) {
				reject(err);
			}
		});
	}

	// Receipt Operations

	ApproveReceipt(receiptid, updateuser, products) {
		return new Promise((resolve, reject) => {
			try {
				// Builds the list of products
				let _productsToApprove = [];
				products.forEach(p => {
					let newP = new ReceiptApprovalCallCenterRequest_Product(p["idProducto"], p["qty"], p["price"]);
					_productsToApprove.push(newP);
				});

				// Builds the request object
				let requestObject = new ReceiptApprovalCallCenterRequest(receiptid, updateuser, "", _productsToApprove);
				let url = `${environment.nodeServer}${environment.purchasesBase}/approve_callcenter`;
				let body = JSON.stringify(requestObject);
				let token = `Bearer ${this.authorizationToken}`;

				// console.log("URL: ", url);
				// console.log("Body: ", body);
				// console.log("Token:", token);

				fetch(
					url,
					{
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': token
						},
						body: body
					}
				)
					.then(response => response.json())
					.then(data => {
						resolve({ result: true, message: "", data: data });
					})
					.catch((err) => {
						console.log("Catched Error.");
						console.log(err);

						reject({ result: false, message: "", data: err });
					});
			}
			catch (err) {
				reject({ result: false, message: "", data: err });
			}
		});
	}

	RejectReceipt(receiptid, updateuser, reason) {
		return new Promise((resolve, reject) => {
			try {
				// Builds the request object
				let requestObject = new ReceiptRejectionRequest(receiptid, updateuser, reason, 0);

				fetch(this._rejectReceiptURL, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.authorizationToken
					},
					body: JSON.stringify(requestObject)
				})
					.then(function (res) {
						return res.json();
					})
					.then(function (data) {
						resolve({ result: true, message: "", data: data });
					})
					.catch((err) => {
						reject({ result: false, message: "", data: err });
					});
			}
			catch (err) {
				reject({ result: false, message: "", data: err });
			}
		});
	}

	UpdateReceipt(updateObject) {
		return new Promise(async (resolve, reject) => {
			let response = await fetch(this._approveReceiptServiceURL, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + this.authorizationToken
				},
				body: JSON.stringify(updateObject)
			})
				.then(function (res) {
					return res.json();
				})
				.then(function (data) {
					resolve(data);
				})
				.catch((err) => {
					console.log("Fetch Error:", err);
				});
		})
	}

	UpdateOCRAnalysis(receiptid, urlToProcess, ocrAnalysis) {
		return new Promise(async (resolve, reject) => {

			if (this.authorizationToken === "") {
				reject("Invalid authorization token.");
				return;
			}

			try {
				var apiURl = this._updateOCRAnalysis;

				axios(apiURl,
					{
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.authorizationToken
						},
						data: {
							"billId": receiptid,
							"urlToProcess": urlToProcess,
							"ocrAnalysis": ocrAnalysis
						}
					}
				)
					.then(function (res) {
						return res;
					})
					.then(function (response) {

						resolve({ result: true, data: response.data.response, message: "" });
					});
			}
			catch (err) {
				reject(err);
			}
		});
	}

	UpdateCRMReceipt(updateObject, encodedToken) {
		return new Promise(async (resolve, reject) => {
			try {
				// console.log("URL:", this._updateCRMFactura);
				// console.log("Body:", JSON.stringify(updateObject));
				// console.log("Token:", encodedToken);

				await fetch(this._updateCRMFactura, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': encodedToken
					},
					body: JSON.stringify(updateObject)
				})
					.then(function (res) {
						return res.json();
					})
					.then(function (data) {
						resolve(data);
					})
					.catch((err) => {
						console.log("Fetch Error:", err);
						reject(err);
					});
			}
			catch (err) {
				reject(err);
			}
		})
	}

	UpdateReceiptImage(file, encodedToken) {
		return new Promise(async (resolve, reject) => {
			try {
				var data = new FormData()
				data.append('samplefile', file);
				data.append('token', encodedToken);
				await fetch(this._updateCRMFacturaImage, {
					method: 'POST',
					// mode:'no-cors',
					headers: {},
					body: data
				})
					.then(function (res) {
						console.log(res);
						return res.json();
					})
					.then(function (data) {
						resolve(data);
					})
					.catch((err) => {
						console.log("Fetch Error:", err);
					});

				resolve({});
			}
			catch (err) {
				reject(err);
			}
		})
	}

	async NullifyReceipt(receiptid) {
		try {
			let token = `Bearer ${this.authorizationToken}`;
			let url = `${environment.nodeServer}${environment.purchasesBase}/nullify_callcenter`;
			return fetch(
				url,
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': token
					},
					body: JSON.stringify({ idFactura: receiptid })
				}
			)
				.then(response => response.json())
				.then(data => {
					return ({ result: true, message: "", data: data });
				})
				.catch((err) => {
					console.log("Catched Error.");
					console.log(err);

					return ({ result: false, message: "", data: err });
				});
		}
		catch (err) {
			return ({ result: false, message: "Error while nullifying the receipt.", data: err });
		}
	}

	// Search Methods

	Factura_Search_ProcessSearch(searchObject: Factura_Search_Object) {
		console.log(searchObject);

		return new Promise(async (resolve, reject) => {
			try {
				var apiURl = "http://localhost:9008/lectorfacturas/facturas_search";

				let response = await fetch(apiURl, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.authorizationToken
					},
					body: JSON.stringify(searchObject)
				})
					.then(function (res) {
						return res.json();
					})
					.then(function (data) {
						resolve(data);
					});
			}
			catch (err) {
				reject(err);
			}
		});

	}

	async Factura_Search_GetAuthToken(id_factura, callcenteruserid) {
		// Gets the token.
		return new Promise(async (resolve, reject) => {
			axios('http://localhost:9000/authcrmocr',
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.authorizationToken
					},
					data: {
						"user": "aboxocradmin",
						"password": "4b0x0cr4dm1n",
						"receiptid": id_factura,
						"operatoruserid": callcenteruserid
					}
				}
			)
				.then(function (res) {
					return res;
				})
				.then(function (response) {
					if (response.status === 200) {
						resolve({ result: true, data: response.data, message: "" });
					}
					else {
						resolve({ result: false, data: response.data, message: "Error al traer los datos." });
					}
				});
		});
	}

	// Non Abbott Products

	async get_non_abbott_products(country) {
		try {
			var apiURl = `${environment.nodeServer}${environment.receiptsBase}/ocr/imsproducts`; // `http://localhost:9008/ocr/imsproducts`; // 

			let response = await axios(apiURl,
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${environment.api_token}`
					},
					data: {
						"country": country,
						"search_text": "",
						"cache_reset": 1
					}
				}
			);

			// Processes the response
			let response_body = response.data;
			if (!response_body.result) {
				return {
					result: false,
					message: response_body.message,
					data: response_body.data
				}
			}
			else {

				// Extracts the products from the response
				let products = response_body.data.data;
				let count = response_body.data.count;

				return {
					result: true,
					message: "",
					data: {
						count: count,
						products: products
					}
				}
			}
		}
		catch (err) {
			return {
				result: false,
				message: "",
				data: err
			}
		}
	}

	//Microsoft Dynamics Methods
	DynamicsUpdateReceipt(requestObject) {
		return new Promise((resolve, reject) => {
			try {

				// Debug Logs
				let url = `${environment.crmServer}/api/invoices/update`;
				let token = `Bearer ${environment.dynamics_api_token}`
				let body = JSON.stringify(requestObject);

				// console.log("Dynamics Update Service Call:");
				// console.log(url);
				// console.log(token);
				// console.log(body);

				fetch(
					url,
					{
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': token
						},
						body: body
					}
				)
					.then(response => response.json())
					.then(data => {
						if (data.IsSuccessful) {
							resolve({ result: true, message: "", data: data });
						}
						else {
							resolve({ result: false, message: "", data: data });
						}

					})
					.catch((err) => {
						console.log("Catched Error.");
						console.log(err);

						reject({ result: false, message: "", data: err });
					});
			}
			catch (err) {
				reject({ result: false, message: "", data: err });
			}
		});
	}


	async validateLimitConsumptionMonthly(obj: LimitConsumption): Promise<ResponseLimitConsumptionMonthly> {

		try {



			const limitConsumptionUrl: string = this._getConsumptionLimitMonthlyUrl
			const token = `Bearer ${environment.api_token}`;
			const { data } = await axios(limitConsumptionUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': token
				},
				data: obj
			});
			console.log({ data })
			return Promise.resolve(data);

		} catch (error) {
			return Promise.reject({ result: false, message: "", data: error });
		}
	}

	//---

	async validateLimitConsumptionYearly(obj: LimitConsumption): Promise<ResponseLimitConsumptionYearly> {

		try {

			const limitConsumptionUrl: string = this._getConsumptionLimitYearlyUrl
			const token = `Bearer ${environment.api_token}`;
			const { data } = await axios(limitConsumptionUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': token
				},
				data: obj
			});
			return Promise.resolve(data);

		} catch (error) {
			return Promise.reject({ result: false, message: "", data: error });
		}
	}


	// Process the receipt:
	// - Resizes the image and stores it within the processed folder
	// - Reades the text within the image
	// - Compares the words found with the list of products available
	// - Marks any word with potential matches
}
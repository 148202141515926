import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { Product } from '../../models/product';
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from "src/app/models/productadded";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "productslist",
	templateUrl: "productslist.component.html",
	styleUrls: ["productslist.component.css"]
})
export class ProductsListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    // Input Parameters

    @Input() selectedWord: string = ""; // Selected Polygon Text
    @Input() foundMatches: any = [];    // All products that matched the selected text
    @Input() productsList: any = [];    // List of Products available in the database (for text search)
    @Input() countryId: string = "";    // Receipt Country Id, for future use

    // Output Parameters

    private selectedProduct = new EventEmitter(); // Selected product clicked by the user
    private selectedProduct_fromSearch: Product = new Product('', '');

    addProductTolistEvent = new EventEmitter();

	constructor(private messagingService: MessagingService) {
    }

    // Public Methods
    // --------------------------------------------------
    useProductFromList(product) {
        // When using the ProductForm Component
        /*
        let newProduct = new Product(
            product["item"]["idProducto"],
            product["item"]["Producto"]
        );
        this.messagingService.SetSelectedProductInFormMessage(newProduct);
        */

        // Builds the new Product to be added
        let newProductAdded = new ProductAdded(
            product["item"]["idProducto"], 
            product["item"]["Producto"], 
            0, 
            0
        );
        this.messagingService.AddNewProductMessage(newProductAdded);
    }

    addToListDirectly() {
        // Builds the new Product to be added
        let newProductAdded = new ProductAdded(
            "N/A", 
            this.selectedWord, 
            0, 
            0
        );
        this.messagingService.AddNewProductMessage(newProductAdded);
    }

    // Event listener from the Auto Complete Search
    selectedItemChanged(e) {
        this.selectedProduct_fromSearch = e;

        // Builds the new Product to be added
        let newProductAdded = new ProductAdded(
            this.selectedProduct_fromSearch.id, 
            this.selectedProduct_fromSearch.name, 
            0, 
            0
        );
        this.messagingService.AddNewProductMessage(newProductAdded);
    }

    setAsSelected() {
        if (!this.selectedProduct_fromSearch || this.selectedProduct_fromSearch === null) return;
        let newProduct = new Product(
            this.selectedProduct_fromSearch.id, 
            this.selectedProduct_fromSearch.name);
        this.messagingService.SetSelectedProductInFormMessage(newProduct);
    }

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
        console.log("ProductsListComponent Changes:", changes);
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { Product } from '../../models/product';
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from "src/app/models/productadded";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "suggestedproducts-table",
	templateUrl: "suggestedproductstable.component.html",
	styleUrls: ["suggestedproductstable.component.css"]
})
export class SuggestedProductsTable implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() selectedProducts: any = [];
	@Input() productsList: any = [];
	suggestedProducts: any = [];
	suggestedFamilies: any = [];

	BuildSuggestedList() {
		if (this.selectedProducts.length === 0) {
			this.suggestedProducts = [];
			return;
		}

		// Calculate the points
		let pointsAccrued = 0;
		this.selectedProducts.forEach(p => pointsAccrued += p.points);

		// Extracts the Families that are within the selected products

		let families = [];
		this.selectedProducts.map(s => {
			let found = families.filter(f => f.familly === s.familly);
			if (found.length === 0) {
				families.push({
					familly: s.familly,
					products: []
				});
			}
		});

		// Gets the suggested products per familly

		families.forEach(f => {
			f.products = this.productsList.filter(p => p.familly === f.familly && p.redeem <= pointsAccrued)
		});

		// Removes the families that don't have any products
		this.suggestedFamilies = families.filter(f => f.products.length > 0);

		// // Filters the products
		// let maxItemsToReturn = 10;
		// let sameCatFamProds = this.productsList.filter(p => {
		// 	let f = this.selectedProducts.filter(s => s.category === p.category || s.familly === p.familly);
		// 	if (f.length > 0 && p.redeem <= pointsAccrued && p.redeem > 0) return p;
		// });
		// this.suggestedProducts = sameCatFamProds.sort(this.sorterJsonArrayByKey("redeem", "desc")).slice(0, maxItemsToReturn);
		
		// let tempArray = this.productsList.filter(p => p.redeem <= pointsAccrued);
		// this.suggestedProducts = tempArray.sort(this.sorterJsonArrayByKey("redeem", "desc")).slice(0, maxItemsToReturn);
	}

	constructor(private messagingService: MessagingService) {
		this.messagingService.clearCartEvent.subscribe((message) => {
			this.suggestedFamilies = [];
			this.selectedProducts = [];
			this.BuildSuggestedList();
		});

		this.messagingService.updateCartEvent.subscribe((message) => {
			this.BuildSuggestedList();
		});
	}

	sortByKey(array, key) {
		return array.sort(function(a, b) {
			var x = a[key]; var y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}

	sorterJsonArrayByKey (key, order: string = "asc") {
		if (order)
			return function innerSort(a, b) {
		  		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				// property doesn't exist on either object
				return 0;
		  	}
	  
			const varA = (typeof a[key] === 'string')
			? a[key].toUpperCase() : a[key];
			const varB = (typeof b[key] === 'string')
			? b[key].toUpperCase() : b[key];

			let comparison = 0;
			if (varA > varB) {
			comparison = 1;
			} else if (varA < varB) {
			comparison = -1;
			}
			return (
			(order === 'desc') ? (comparison * -1) : comparison
			);
		}
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
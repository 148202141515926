import { Injectable } from '@angular/core';
import { createInject } from '@angular/compiler/src/core';
import { CATCH_STACK_VAR } from '@angular/compiler/src/output/output_ast';

@Injectable({ providedIn: 'root', })
export class TokenService {

	constructor() {
    }
    
    Decode(_encodedtoken) {

        // console.log("Encoded Token: ", _encodedtoken);

        try {
            var decodedData = atob(_encodedtoken);

            if (decodedData.indexOf("/") > 0) {
                let s = decodedData.split("/");
                let response = {
                    result: true,
                    message: "Token válido.",
                    data: {
                        receiptid: s[0],
                        token: s[1],
                        user: s[2]
                    }
                }
                return response;
            } else {
                return {
                    result: false,
                    message: "Token inválido.",
                    data: {}
                }
            }
        }
        catch(err) {
            console.log("Error: ", err);
        }
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ReceiptService } from '../../../services/receipt.service';
import { Factura_Search_Object, Factura_SearchResults_Object } from '../../../models/facturas_search';
import { Utilities } from '../../../utilities';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "facturas_search_boxes",
	templateUrl: "facturas_search_boxes.component.html",
	styleUrls: ["facturas_search_boxes.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class facturas_search_boxesComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	public factura: string = "";
	public nationalid: string = "";

	public loading_data: boolean = false;
	public facturas_list: Factura_SearchResults_Object[] = [];

	public searchObject: Factura_Search_Object = {
		country: "",
		factura: "",
		pharmacy: "",
		nationalid: "",
		startdate: "",
		enddate: "",
		priority: "",
		status: "",
		page: 1,
		pagesize: 10
	};

	// Helper method
	public ProcessNgbDate(selecteddate, defaultvalue) {
		if (selecteddate === undefined) return defaultvalue;
		if (selecteddate["day"] === undefined || selecteddate["month"] === undefined || selecteddate["year"] === undefined) {
			return defaultvalue;
		}
		return selecteddate["year"] + "-" + selecteddate["month"] + "-" + selecteddate["day"];
	}

	public GetDefaultStartDate() {
		let date = new Date();
		return { 'year': date.getFullYear(), 'month': 1, 'day': 1 }
	}

	public GetDefaultEndDate() {
		let date = new Date();
		return { 'year': date.getFullYear(), 'month': date.getMonth()+1, 'day': date.getDate() }
	}

	// Component events listeners...

	public selectedCountryChanged(country) {
		
		this.searchObject.country = country["id"];
	}

	public selectedPharmacyChanged(pharmacy) {
		
		this.searchObject.pharmacy = pharmacy["id"];
	}

	public selectedStartDateChanged(startdate) {
		
		this.searchObject.startdate = this.ProcessNgbDate(startdate, '');
	}

	public selectedEndDateChanged(enddate) {
		
		this.searchObject.enddate = this.ProcessNgbDate(enddate, '');
	}

	public selectedPriorityChanged(priority) {
		
		this.searchObject.priority = priority["id"];
	}

	public selectedStatusChanged(status) {
		
		this.searchObject.status = status["id"];
	}

	// Main Button Method:
	public async DoSearch() {

		// Sets the non-component fields
		this.searchObject.nationalid = this.nationalid;
		this.searchObject.factura = this.factura;

		try {
			this.loading_data = true; 
			let search_response = await this.receiptServices.Factura_Search_ProcessSearch(this.searchObject);
			
			if (Array.isArray(search_response)) {
				this.facturas_list = this.DataCleanUp(search_response);
			}

			this.loading_data = false;
			this.ref.detectChanges();
		}
		catch(err) {
			this.loading_data = false;
			console.log(err);
			this.ref.detectChanges();
		}
	}

	public DataCleanUp(facturas: Factura_SearchResults_Object[]) {
		facturas.forEach(f => {
			f.Fecha = this.utilities.JSONDateParse(f.Fecha, 'N/A');
			f.Registrada = this.utilities.JSONDateParse(f.Registrada, 'N/A');
		});
		return facturas;
	}

    // Component Methods
	// --------------------------------------------------

	constructor(private receiptServices: ReceiptService, private ref: ChangeDetectorRef, private utilities: Utilities) {
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log(changes)
    }

}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "country_dropdown",
	templateUrl: "country_dropdown.component.html",
	styleUrls: ["country_dropdown.component.css"]
})
export class country_dropdown_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Output() selectedItemChangedEvent = new EventEmitter<string>();
    
    // Data:
    public datalist: any = [
        { 'id':'CR', 'name':'Costa Rica' },
        { 'id':'GT', 'name':'Guatemala' }
    ];

	selectedCountryChanged(selectedItem) {
		this.selectedItemChangedEvent.emit(selectedItem);
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
        // Sets the default Country
        this.selectedCountryChanged(this.datalist[0]);
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Farmacia } from '../../models/farmacia';
import { ReceiptService } from '../../services/receipt.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-farmacias-search-box",
	templateUrl: "fac_farmacias_search_box.component.html",
	styleUrls: ["fac_farmacias_search_box.component.css"]
})
export class Fac_Farmacias_Search_Box implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	public model: string = "";
	clickedItem:string;

	@Output() selectedItemChanged = new EventEmitter();

	private farmaciasList: any = [{"name":"No hay farmacias encontrados."}];

    constructor(
		private messagingService: MessagingService, 
		private receiptService: ReceiptService,
		private shared_data_service: SharedDataService,
		private cRef: ChangeDetectorRef
		) {
    }

	// HTML Controls events...
	// --------------------------------------------------------

	focus(dom_object) {
		this.messagingService.Fac_Field_Clicked(dom_object);
	}

	// When the user selects an item from the drop down, this method sets the item and the value in the search box
	// Invoking the message suscription "selectedItemChanged"
	selectedItem(item){
		if (item["item"]["other"] != undefined) item.preventDefault();
		this.clickedItem = item.item;
		this.selectedItemChanged.emit(item["item"]);
		if (item["item"]["other"] != undefined) this.model = null;
	}

	// Searches within the input item list, any item that matches the input from the searchbox
	find_term(term) {
		let _this = this;
		return term.length < 2 ? [] : function() {
			let found_items = _this.farmaciasList.filter(v => {

				if (v["name"].toLowerCase().indexOf(term.toLowerCase()) > -1) {
					return v["name"];
				}

			});
			if (found_items.length > 0) {
				return found_items.slice(0, 20); // Returns the top 20 results found.
			}
			else {
				return [
					{ name: "No se encontró. Agregar como otra farmacia.", other: term }
				]
			}
		}();
	}

	// Sets the value that is shown in the list of the type ahead drop down
	formatter = (farmacia: Farmacia) => farmacia["name"];

	// Main search function that receives the input from the searchbox
	search = (text$: Observable<string>) => 
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this.find_term(term))
		);


	// Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		let country = await this.shared_data_service.fac_country;
		let factura = await this.shared_data_service.fac_details;
		
		if (country !== undefined && country !== "") {
			let farmacias_search = await this.receiptService.GetDrugStoresData(country);
			if (farmacias_search["result"]) {
				this.farmaciasList = farmacias_search["data"].filter(f => f["status"] != "IN");
			}
		}
		this.model = factura["Farmacia"];

		if (factura["Estado"] !== "Aprobada") {
			this.cRef.detectChanges();
		}
	}

    async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.init_operations();
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
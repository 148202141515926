import { Input, Component, OnInit, OnDestroy, AfterViewInit, ViewChild, SimpleChanges, ElementRef, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';
import { DomSanitizer} from '@angular/platform-browser';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-image",
	templateUrl: "fac_image.component.html",
	styleUrls: ["fac_image.component.css"]
})
export class Fac_Image implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@ViewChild('visbleCanvas', { static: true })
	visbleCanvas: ElementRef<HTMLCanvasElement>;
	private ctx: CanvasRenderingContext2D;

	private factura_is_loaded: boolean = false;

	private factura: any = {};
	private url_imagen_de_factura: string = "";
	private token: string = "";
	private factura_ocr_details: any = {};
	private factura_is_pdf: boolean = false;

	pdf_url_sanitized: any;

	rot: number = 0;
	ratio: number = 0.2; // By default opens the bill with a 20% zoom out
	CanvasCrop: any;
	_highlightColor:string = "rgba(255, 0, 0, 0.2)";

	private selected_polygons_words: any [];

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService,
		private sanitizer: DomSanitizer
	) {
    }

	// Handle clicked fields...
	// --------------------------------------------------------

	show_field_polygons_on_image(clicked_field_name) {
		let words_with_polygons = null;

		$('.toast').toast({});

		if (Object.keys(this.factura_ocr_details).length === 0 && this.factura_ocr_details.constructor === Object) return;

		if (clicked_field_name === "Factura") {
			words_with_polygons = this.factura_ocr_details["receiptnumber"]["matched_words"];
		}

		if (clicked_field_name === "Farmacia") {
			words_with_polygons = this.factura_ocr_details["pharmacyname"]["matched_words"];
		}

		if (clicked_field_name === "Fecha") {
			words_with_polygons = this.factura_ocr_details["receiptdate"]["matched_words"];
		}

		if (clicked_field_name["obj_type"] === "abbott-product") {
			let product = clicked_field_name["object"];
			let found = this.factura_ocr_details["products"].filter(p => p["ID_Producto"] === product["id"]);
			if (found.length > 0) {
				words_with_polygons = found["matched_words"];
			}
		}

		if (words_with_polygons != null) {
			if (this.selected_polygons_words !== words_with_polygons) {
				this.selected_polygons_words = words_with_polygons;
				this.DrawPolygons(words_with_polygons);
			}
		}
	}

	// Image Operations...
	// --------------------------------------------------------

	load_image_in_canvas(image_url) {

		if (image_url === undefined) return;

		try {
			$(".spinner").show();
			let _this = this;
			this.CanvasCrop = $.CanvasCrop({
				cropBox: ".imageBox",
				imgSrc: image_url,
				limitOver: 0,
				imageLoadedFx: () => {
					// Hides the Spinner
					$(".spinner").hide();
					_this.CanvasCrop.scale(_this.ratio);
					_this.CanvasCrop.rotate(_this.rot);
				},
				imageLoadedErrorFx: (err) => {
					console.log(err);
				}
			});
		}
		catch(err) {
			console.log(err);
		}
	}

	Rotate_Left() {
		this.rot -= 90;
		this.rot = this.rot < 0 ? 270 : this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.DrawPolygons(this.selected_polygons_words);

	}

	Rotate_Right() {
		this.rot += 90;
		this.rot = this.rot > 360 ? 90 : this.rot;
		this.CanvasCrop.rotate(this.rot);
		this.DrawPolygons(this.selected_polygons_words);
	}

	Zoom_Out() {
		this.ratio = this.ratio - 0.3;
		if (this.ratio <= 0) this.ratio = 0.1; // Mínimo 10%
		this.CanvasCrop.scale(this.ratio);
		this.DrawPolygons(this.selected_polygons_words);
		// this.showReAnalizeButton = true;
		// this.messagingService.TriggerDrawPolygons();
	}

	Zoom_In() {
		this.ratio = this.ratio + 0.3;
		this.CanvasCrop.scale(this.ratio);
		this.DrawPolygons(this.selected_polygons_words);
	}

	async reset_image() {

		// Nota:
		// Se le agregó una función al plugin de $.canvascrop al objeto que retorna:
		// get_image: function() { return img; }

		let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this.CanvasCrop.get_image(), 0, 0, canvas.width, canvas.height);
	}

	// Polygon Operations...
	// --------------------------------------------------------

	DrawPolygon = (polygon) => {
        let color = this._highlightColor;
        let vs = polygon.vertices;
        let canvas = document.getElementById("visbleCanvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d");
        let vArray = [];
        vs.forEach(v => { vArray.push(this.scaleValue(v.x)); vArray.push(this.scaleValue(v.y)); });
        var poly = vArray;
        ctx.fillStyle = color;
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.moveTo(poly[0], poly[1]);
        for( let item=2 ; item < poly.length-1 ; item+=2 ){ctx.lineTo( poly[item] , poly[item+1] )}

        ctx.closePath();
        ctx.fill();
	}

	async DrawPolygons(wordMatches) {
		if (wordMatches != undefined) {
			this.reset_image();
			wordMatches.forEach(w => {
				this.DrawPolygon(w);
			});
		}
	}

	scaleValue(value){
		//scale a provided value by the scalefactor
		//this way we can define the polygons at the native image size
		//but it scales the values relative to the hidden image element
		return value*this.ratio;
	}

    // Component basic methods...
	// --------------------------------------------------------

	async reload_ocr_info() {
		// Pulls the OCR details from the shared data service
		this.factura_ocr_details = await this.shared_data_service.fac_ocr_details;
	}

	async reload_factura_imagen(new_url) {
		this.factura = await this.shared_data_service.fac_details;
		if (this.factura) {
			this.url_imagen_de_factura = new_url;
			
			// Verifies if the factura is a pdf, in that case, no analysis is performed.
			if (this.url_imagen_de_factura != undefined && this.url_imagen_de_factura.toUpperCase().indexOf(".PDF") >= 0) {
				this.factura_is_pdf = true;
			}
			else {
				this.factura_is_pdf = false;
			}

			this.factura_is_loaded = true;
			
			if (!this.factura_is_pdf) {
				setTimeout(() => {
					this.load_image_in_canvas(this.url_imagen_de_factura);	
				}, 1500);
			}
		}
	}

    async init_operations() {
		// Verifies if there is already a factura loaded in the shared data service
		// and extracts and converts the non abbott products to the current list.
		this.factura = await this.shared_data_service.fac_details;
		this.token = await this.shared_data_service.fac_token;

		if (this.factura) {
			// Verifies if the factura needs to be analyzed to extract the polygons from the image
			if (this.factura["OcrInfo"] !== undefined && this.factura["OcrInfo"]["imageText"] === null) {
				// Needs to be analyzed
			}
			else {
				if (this.factura["OcrInfo"] !== undefined) {
					this.factura_ocr_details = JSON.parse(unescape(this.factura["OcrInfo"]["imageText"]));
				}
			}

			this.url_imagen_de_factura = this.factura["imageUrl"];

			// Verifies if the factura is a pdf, in that case, no analysis is performed.
			if (this.url_imagen_de_factura != undefined && this.url_imagen_de_factura.toUpperCase().indexOf(".PDF") >= 0) {
				this.factura_is_pdf = true;
			}
			else {
				this.factura_is_pdf = false;
			}

			this.factura_is_loaded = true;
			
			if (!this.factura_is_pdf) {
				setTimeout(() => {
					this.load_image_in_canvas(this.url_imagen_de_factura);	
				}, 1500);
			}
			else {
				this.pdf_url_sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_imagen_de_factura);
			}
		}
	}

    async ngOnInit() {

		this.messagingService.fac_field_clicked_event.subscribe(factura_field_clicked => {
			this.show_field_polygons_on_image(factura_field_clicked);
		});

		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.messagingService.fac_ocr_info_updated_event.subscribe(message => {
			this.reload_ocr_info();
		});

		this.messagingService.fac_new_image_loaded_event.subscribe(new_url => {
			this.reload_factura_imagen(new_url);
		});
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
export class ReceiptRejectionRequest {
    billId: string;
    user: string;
    reason: string;
    duration: number = 0;

    constructor(_billId, _user, _reason, _duration) {
        this.billId = _billId;
        this.user = _user;
        this.reason = _reason;
        this.duration = _duration;
    }
}
import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding } from "@angular/core";
import { DemoService } from '../../services/demo.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: "revision-factura",
	templateUrl: "revision_factura_page.component.html",
	styleUrls: ["revision_factura_page.component.css"]
})
export class Revision_Factura_Page implements OnInit, OnDestroy, AfterViewInit {

	constructor(private demoService: DemoService, private router: Router) {
	}
	
	// Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}
}
import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy} from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';
import { ThrowStmt } from "@angular/compiler";
import { Subject, interval } from "rxjs";

declare var jquery:any;
declare var $ :any;

export interface TimeSpan {
	hours: number;
	minutes: number;
	seconds: number;
}

@Component({
	selector: "fac-editor-stats",
	templateUrl: "fac_editor_stats.component.html",
	styleUrls: ["fac_editor_stats.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Fac_Editor_Stats implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private factura: any = {};
	private editor_user: string = "";

	public timerInit: boolean = false;
	timerCount: Date;
	timerString: string = '';
	timerFunction: any;
	timerSubscription = null;
	finalElapseTime: any = null;

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService,
		private changeDetector: ChangeDetectorRef
	) {
    }

	// Timer operations...
	// --------------------------------------------------------

	stop_timer() {
		this.timerInit = false;
		this.timerSubscription.unsubscribe(); 
		this.factura["TiempoRevision"] = this.getElapsedTime_seconds(this.timerCount);
	}

	start_timer() {
		// Starts the time.
		// ------------------------------------------------
		this.timerInit = true;
		this.timerCount = new Date();
		this.timerSubscription = interval(1000).subscribe(() => {
			if (!this.changeDetector['destroyed']) {
				this.changeDetector.detectChanges();
			}
		});
	}
	
	getElapsedTime(entry: Date): TimeSpan {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);

		let hours = 0;
		let minutes = 0;
		let seconds = 0;

		if (totalSeconds >= 3600) {
			hours = Math.floor(totalSeconds / 3600);
			totalSeconds -= 3600 * hours;
		}

		if (totalSeconds >= 60) {
			minutes = Math.floor(totalSeconds / 60);
			totalSeconds -= 60 * minutes;
		}

		seconds = totalSeconds;

		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds
		};
	}

	getElapsedTime_seconds(entry: Date): number {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);
		return totalSeconds;
	}

	getFinalTime(entry: Date) {
		if(entry == null){return}
		let totalSeconds = Math.floor((new Date().getTime() - entry.getTime()) / 1000);

		return totalSeconds;
	}

    // Component basic methods...
	// --------------------------------------------------------

    async init_operations() {
		this.factura = await this.shared_data_service.fac_details;
		this.editor_user = await this.shared_data_service.fac_editor_user;
		
		if (this.factura && this.factura["Estado"] !== undefined && this.factura["Estado"] !== 'Aprobada') {
			this.start_timer();
		}

		this.changeDetector.detectChanges();
	}

    async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.messagingService.fac_stop_timer_event.subscribe(message => {
			this.stop_timer();
		});

		this.messagingService.fac_start_timer_event.subscribe(message => {
			this.stop_timer();
		});

		this.init_operations();
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
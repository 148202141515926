import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "fac-dynamics-sync",
	templateUrl: "fac_dynamics_sync.component.html",
	styleUrls: ["fac_dynamics_sync.component.css"]
})
export class Fac_Dynamics_Sync implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService, 
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
    }

	// View Methods
	// --------------------------------------------------------

	sync_to_dynamics() {
		this.messagingService.Fac_Sync_With_Dynamics({});
	}

	generate_dynamics_json() {
		this.messagingService.Fac_Generate_Dynamics_Json({});
	}

	generate_factura_json() {
		this.messagingService.Fac_Generate_Factura_Json({});
	}

    // Component basic methods...
	// --------------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ModalDismissReasons, NgbDateStruct, NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from '../../../../services/messagingservice';
import { Factura_SearchResults_Object } from '../../../../models/facturas_search';
import { factura_modal_content_Component } from "./factura_modal_content.component";


declare var jquery:any;
declare var $ :any;

@Component({
	selector: "factura_modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "factura_modal.component.html",
	styleUrls: ["factura_modal.component.css"]
})
export class factura_modal_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private factura: Factura_SearchResults_Object = new Factura_SearchResults_Object('','','','','','','',);
	@Output() selectedEvent = new EventEmitter<NgbDateStruct>();
	
	OpenModal() {
		const modalRef = this.modalService.open(
			factura_modal_content_Component, 
			{size: 'xl', backdropClass: 'light-blue-backdrop'}
		);

		modalRef.componentInstance["factura"] = this.factura;
		this.ref.detectChanges();
	}

    // Component Methods
	// --------------------------------------------------

	constructor(
		private modalService: NgbModal, 
		private ref: ChangeDetectorRef, 
		private messagingService: MessagingService) {
		
		// When a user selects an item from the product list component.
        this.messagingService.triggerOpenFacturaModal.subscribe(
            (factura) => {
				this.factura = factura;
				this.OpenModal();
            }
        );
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from "src/app/services/messagingservice";
import { TokenService } from "src/app/services/token.service";
import { Factura_SearchResults_Object } from "../../../../models/facturas_search";
import { ReceiptService } from '../../../../services/receipt.service';

@Component({
	selector: "factura_modal_content",
	templateUrl: "factura_modal_content.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class factura_modal_content_Component implements OnInit {
	
  	@Input() factura: Factura_SearchResults_Object; 

	// Data Methods

	async GetFacturaData() {
		// Gets the Token from the server
		let token_response = await this.rs.Factura_Search_GetAuthToken(this.factura.ID_Factura, "loymark");
		if (token_response["result"]) {
			let token = token_response["data"]["response"]["details"]["token"];

			let ts = new TokenService();
			let tokenresponse = ts.Decode(token);
			if (tokenresponse.result) {
				token = tokenresponse["data"]["token"];
				this.rs.SetToken(token);
			
				// Pulls the data from the server
				let factura_data = await this.rs.GetReceiptDataWithProducts(this.factura.ID_Factura);
				console.log(factura_data);
			}
			else {
				// Non authorized...
			}
		}
		else {
			console.log("Cannot retrieve token.");
		}
	}

	// Component methods...

	constructor( 
		public activeModal: NgbActiveModal, 
		private ref: ChangeDetectorRef, 
		private messagingService: MessagingService,
		private rs: ReceiptService
	) {
		
	}
	
	ngOnInit() {
		console.log("Reached!")
		console.log(this.factura);
		this.GetFacturaData();
  	}
}

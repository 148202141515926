import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery: any;
declare var $: any;

@Component({
	selector: "fac-editables-form",
	templateUrl: "fac_editables_form.component.html",
	styleUrls: ["fac_editables_form.component.css"]
})
export class Fac_Editables_Form implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private factura: any = {};
	private factura_ocr_details: any = null;

	// Field Validators
	private fval_monto: boolean = true;

	constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService,
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService
	) {
	}

	// HTML Controls events...
	// --------------------------------------------------------

	focus(dom_object) {
		this.messagingService.Fac_Field_Clicked(dom_object);
	}

	eval_ocr_field(field) {
		if (this.factura_ocr_details === null || this.factura_ocr_details === undefined) {
			return "";
		}

		if (field === "factura") {
			if (this.factura_ocr_details["receiptnumber"] && this.factura_ocr_details["receiptnumber"]["hasMatches"]) {
				this.factura["ocr_factura"] = true;
			}
		}
		if (field === "farmacia") {
			if (this.factura_ocr_details["pharmacyname"] && this.factura_ocr_details["pharmacyname"]["hasMatches"]) {
				this.factura["ocr_farmacia"] = true;
			}
		}
		if (field === "fecha") {
			if (this.factura_ocr_details["receiptdate"] && this.factura_ocr_details["receiptdate"]["hasMatches"]) {
				this.factura["ocr_fecha"] = true;
			}
		}
	}

	valuechange(e, field) {
		switch (field) {
			case 'monto':
				let m = this.factura['MontoTotal'];
				this.fval_monto = !isNaN(Number(m));
				break;
		}
	}

	// Angular binding methods...
	// --------------------------------------------------------

	extract_nombre_farmacia(factura) {
		if (factura["Farmacia"] === undefined || factura["Farmacia"] === null) return "";
		return factura["Farmacia"] !== "" && factura["Farmacia"]["ID"] === undefined ? factura["Farmacia"] : factura["Farmacia"]["Nombre"];
	}

	// Used to select the farmacia
	async selected_item_changed(item) {
		if (item["other"] !== undefined) {
			this.factura["Farmacia"] = { "ID": null, "Nombre": item["other"] };
			this.factura["OtraFarmacia"] = item["other"];
		}
		else {
			this.factura["Farmacia"] = { "ID": item["id"], "Nombre": item["name"] };
		}
	}

	// Component basic methods...
	// --------------------------------------------------------

	async reload_ocr_info() {
		// Pulls the OCR details from the shared data service
		this.factura_ocr_details = await this.shared_data_service.fac_ocr_details;

		this.eval_ocr_field('factura');
		this.eval_ocr_field('farmacia');
		this.eval_ocr_field('fecha');
	}

	async init_operations() {
		// Verifies if there is already a factura loaded in the shared data service
		// and extracts and converts the non abbott products to the current list.
		this.factura = await this.shared_data_service.fac_details;

		if (this.factura) {
			// Sets the date with a format that is readable by the date picker control
			let new_date = new Date(this.factura["Fecha"]);
			let m: string = "";
			m = String(new_date.getMonth() + 1);
			let d: string = "";
			d = String(new_date.getDate());
			this.factura["Fecha"] = `${new_date.getFullYear()}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`;

			let update_date = new Date(this.factura["UpdateDate"]);
			this.factura["UpdateDate"] = `${update_date.getFullYear()}-${(update_date.getMonth() + 1).toString().padStart(2, '0')}-${update_date.getDate().toString().padStart(2, '0')} ${update_date.getHours().toString().padStart(2, '0')}:${update_date.getMinutes().toString().padStart(2, '0')} `

			// Pulls the OCR details from the shared data service
			this.factura_ocr_details = await this.shared_data_service.fac_ocr_details;

			this.eval_ocr_field('factura');
			this.eval_ocr_field('farmacia');
			this.eval_ocr_field('fecha');
		}
	}

	async ngOnInit() {
		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.messagingService.fac_ocr_info_updated_event.subscribe(message => {
			this.reload_ocr_info();
		});

		this.init_operations();
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
	}
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { Product } from '../../models/product';
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from "src/app/models/productadded";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReceiptService } from '../../services/receipt.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "cartsummary",
	templateUrl: "cartsummary.component.html",
	styleUrls: ["cartsummary.component.css"]
})
export class CartSummary implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@Input() selectedProducts: any = [];
	
	pointsRequired: number = 0;

	constructor(private messagingService: MessagingService) {
		this.messagingService.updateCartEvent.subscribe((message) => {
			// Calculate the points
			this.pointsRequired = 0;
			this.selectedProducts.forEach(p => this.pointsRequired += p.points);
		});
	}

	ClearCart() {
		this.selectedProducts = [];
		this.messagingService.ClearCart();
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { ReceiptService } from '../../services/receipt.service';
import { SharedDataService } from '../../services/shared_data.service';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "nap_selected_list",
	templateUrl: "nap_selected_list.component.html",
	styleUrls: ["nap_selected_list.component.css"]
})
export class Non_Abbott_Selected_ProductsList implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private selected_products_list: any = []; // {product:"PRODUCTO DE PRUEBA"}

	private undo_heap: any = [];

	private factura: any = {};

	update_shared_products() {
		this.shared_data_service.fac_nap_products = this.selected_products_list;
		this.messagingService.Nap_Product_List_Changed(this.selected_products_list);
	}

    constructor(private messagingService: MessagingService, private receiptService: ReceiptService, private shared_data_service: SharedDataService) {
		this.messagingService.nap_add_product_to_list_event.subscribe((product_to_add) => {

			// console.log(product_to_add)
			// console.log(this.selected_products_list)

			let found = this.selected_products_list.filter(s => s["idproducto_no_abbott"] === product_to_add["idproducto_no_abbott"]);

			if (found.length > 0) {
				// Alredy exists.
			}
			else {
				// Its a new product.

				// Validates the product is a OTHER
				if (product_to_add["corp"] === "OTRO" && product_to_add["lab"] === "OTRO" && product_to_add["product_package"] === "OTRO") {
					product_to_add["product"] = product_to_add["other"];
					product_to_add["idproducto_no_abbott"] = -1;
				}
				else {
					product_to_add["added_as_new"] = false;
				}

				this.selected_products_list.push(product_to_add);
				this.update_shared_products();
			}
        });
    }
    
	delete_item(item_to_delete) {
		this.undo_heap.push(item_to_delete);
		this.selected_products_list = this.selected_products_list.filter(s => s["product"] !== item_to_delete["product"] );
		this.update_shared_products();
	}

	undo() {
		if (this.undo_heap.length > 0) {
			let item_to_add = this.undo_heap[0];
			this.undo_heap = this.undo_heap.slice(1);
			this.selected_products_list.push(item_to_add);
			this.update_shared_products();
		}
	}

	delete_all() {
		this.undo_heap = [];
		this.selected_products_list = [];
		this.update_shared_products();
	}

	trigger_change(event) {
		this.update_shared_products();
	}

	focus(dom_object) {
		let click_object = {
			'obj_type': 'non-abbott-product',
			'object': dom_object
		}
		this.messagingService.Fac_Field_Clicked(click_object);
	}

	valuechange(e, field, product) {
		let v = product[field];
		product["is_valid"] = !isNaN(Number(v));
	}

    // Component basic methods...
	// --------------------------------------------------------

	async init_operations() {
		// Verifies if there is already a factura loaded in the shared data service
		// and extracts and converts the non abbott products to the current list.
		let non_abbott_products_in_factura = [];
		this.factura = await this.shared_data_service.fac_details;
		this.selected_products_list = [];
		if (this.factura) {
			non_abbott_products_in_factura = this.factura["NonAbbottProducts"];

			if (non_abbott_products_in_factura !== undefined) {
				non_abbott_products_in_factura.forEach(p => {
					this.selected_products_list.push({
						"product": p["Producto_Otros"],
						"qty": p["Cantidad_Otros"],
						"price": p["Precio_Otros"],
						"idproducto_no_abbott": p["idproducto_no_abbott"]
					});
					this.update_shared_products();
				});
			}
			
		}
	}

    async ngOnInit() {

		this.messagingService.fac_loaded_event.subscribe(message => {
			this.init_operations();
		});

		this.init_operations();
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from  '../../services/messagingservice';
import { Product } from '../../models/product';
import { ProductAdded } from '../../models/productadded'; 

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "nap_card",
	templateUrl: "nap_card.component.html",
	styleUrls: ["nap_card.component.css"]
})
export class Non_Abbott_Products_Card implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    constructor(private messagingService: MessagingService) {
    }

	selected_item_changed(item) {
		this.messagingService.Nap_Add_Product_To_List(item);
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
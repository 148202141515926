import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { ProductAdded } from '../../models/productadded';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "addedproductlist",
	templateUrl: "addedproductlist.component.html",
	styleUrls: ["addedproductlist.component.css"]
})
export class AddedProductListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    addedProductList: ProductAdded[] = [];
    private selectedInputFromTable: string = "";

    processNewProduct(product) {
        let found = this.addedProductList.filter(a => a.id === product["id"]);

        if (found.length > 0 && product["id"] != "N/A") {
            this.messagingService.ErrorWhileAddingProductToList("El producto ya existe en la lista.");
            return; // Product already exists.
        }

        if (product && product["id"]) {
            this.addedProductList.push(product);
        }
    } 

	constructor(private messagingService: MessagingService) {
        this.messagingService.newProductAddedEvent.subscribe(
            (data) => {

                console.log("New Product Received:", data);

                this.processNewProduct(data);
            }
        );

        this.messagingService.wordPolygonSelectedEvent.subscribe(
            (word) => {
                console.log("Word Selected:", word);
                
                if (this.selectedInputFromTable !== "" && $.isNumeric(word)) {
                    $("#" + this.selectedInputFromTable).val(word);
                }
            }
        );
    }

    // Public Methods
    // --------------------------------------------------

    deleteItem(item, index) {
        console.log("Item to delete: ", item);
        // this.addedProductList = this.addedProductList.filter(a => a.id !== item.id);
        this.addedProductList.splice(index, 1);
    }

    focusPrice(index) {
        console.log("focusPrice", index);
        this.selectedInputFromTable = "p" + index;
    }

    focusQty(index) {
        console.log("focusQty", index);
        this.selectedInputFromTable = "q" + index;
    }

    blur() {
        this.selectedInputFromTable = "";
    }

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
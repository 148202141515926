import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'otherproductsfilter',
    pure: false
})
export class OtherProductsFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter(item => {
            let f: string = "";
            f = String(filter);
            return item["Producto"].toUpperCase().indexOf(f.toUpperCase()) !== -1
        });
    }
}
import { Input, Component, OnInit, OnDestroy, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { MessagingService } from '../../services/messagingservice';
import { SharedDataService } from '../../services/shared_data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { ReceiptService } from '../../services/receipt.service';
import { DynamicsService } from '../../services/dynamics.service';
import { Utilities } from '../../utilities';
import { LimitConsumption } from '../../interface/ILimitConsumption';
import DateDiff from 'date-diff';

declare var jquery: any;
declare var $: any;

@Component({
	selector: "fac-controller",
	templateUrl: "fac_controller.component.html",
	styleUrls: ["fac_controller.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Fac_Controller implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	private is_token_valid: boolean = false;
	private message: string = "";
	private modal_message: any = {};
	private list_of_messages: any = [];
	private list_of_error_validation_limits: Array<String> = [];
	// Received token from Querystring
	private token: string = "";

	// Decoded token
	private receipt_id: string = "";
	private abox_services_api_token: string = "";
	private abox_services_api_user: string = "";

	// Factura object
	private factura_details_object: any = {};

	// Sync with CRM flag
	private sync_with_dynamics: boolean = false; // Flag that defines if the app will sync with Dynamics.

	private rejection_processing: boolean = false;
	constructor(
		private receipt_services: ReceiptService,
		private messagingService: MessagingService,
		private shared_data_service: SharedDataService,
		private route: ActivatedRoute,
		private router: Router,
		private token_service: TokenService,
		private dynamics_service: DynamicsService,
		private changeDetector: ChangeDetectorRef,
		private utilities: Utilities
	) {
		this.trigger_constructor();
	}

	// Shared Data Operations...
	// --------------------------------------------------------

	async pull_shared_data() {
		let factura = await this.shared_data_service.fac_details;
		factura["NonAbbottProducts"] = this.transform_nap_products(await this.shared_data_service.fac_nap_products);
		factura["Products"] = this.transform_ap_products(await this.shared_data_service.fac_ap_products);
		return factura;
	}

	// Data transformation operations...
	// --------------------------------------------------------

	format_factura(factura) {

		// This method is used to add, remove or clean any field required by the api services

		// Factura ID field is required by the update service
		factura["ID"] = factura["idFactura"];

		// Evaluates if the farmacia is not setup as an object as required by the update service
		if (factura["Farmacia"] !== undefined && factura["Farmacia"]["ID"] === undefined) {
			let farmacia = factura["Farmacia"] === undefined ? "N/A" : factura["Farmacia"];
			factura["Farmacia"] = {
				"ID": factura["ID_Farmacia"] !== -1 ? factura["ID_Farmacia"] : null,
				"Nombre": farmacia
			}
		}

		// Verifies that the time of review is setup
		if (factura["TiempoRevision"] === undefined) {
			factura["TiempoRevision"] = 0;
		}

		// Adds the products fields as required by the update service
		factura["ProductosAbbott"] = factura["Products"];
		factura["ProductosNoAbbott"] = factura["NonAbbottProducts"];

		return factura;
	}

	transform_nap_products(nap_products) {
		if (nap_products === undefined || nap_products === []) return [];
		let non_abbott_products_list = [];
		nap_products.forEach(n => {

			let product_name: string = n["product"];

			// Verifies if the product is "other"
			if (n["product_package"] !== undefined && n["product_package"] === "OTRO") {
				product_name = n["product"];
			}

			if (n["product_package"] !== undefined && n["product_package"] !== "OTRO") {
				product_name = n["product_package"];
			}

			non_abbott_products_list.push({
				"Cantidad_Otros": Number(n["qty"]),
				"Precio_Otros": Number(n["price"]),
				"Producto_Otros": product_name.toUpperCase(),
				"idproducto_no_abbott": n["idproducto_no_abbott"]
			});
		});
		return non_abbott_products_list;
	}

	transform_ap_products(ap_products) {

		if (ap_products === undefined || ap_products === []) return [];
		let abbott_products_list = [];
		ap_products.forEach(n => {

			/* 
			Destino:
			Cantidad: 1
			ID_Producto: "100N080002015"
			Precio: 0
			Producto: "CONTROLIP 250 MG"

			Fuente:
			id: "100N080002015"
			name: "CONTROLIP 250 MG"
			price: 0
			qty: 1
			status: false
			*/

			abbott_products_list.push({
				"ID_Producto": n["id"],
				"Cantidad": Number(n["qty"]),
				"Precio": Number(n["price"]),
				"Producto": n["name"]
			});
		});
		return abbott_products_list;
	}

	// UI Operations...
	// --------------------------------------------------------

	show_please_wait_dialog() {
		$("#pleaseWaitDialog").modal('show');
	}

	hide_please_wait_dialog() {
		$("#pleaseWaitDialog").modal('hide');
	}

	show_message_dialog(message_title, message_body, callback_function) {
		this.modal_message["title"] = message_title;
		this.modal_message["body"] = message_body;
		this.changeDetector.detectChanges();
		this.modal_message["callbackfx"] = () => {
			callback_function();
		}
		$('#messageModalWithAction').modal('show');
	}

	show_validation_messages_dialog() {
		this.changeDetector.detectChanges();
		$('#validationResultModal').modal('show');
	}

	show_reject_modal_dialog() {
		this.changeDetector.detectChanges();
		$('#rechazarFaturaModal').modal('show');
	}

	hide_reject_modal_dialog() {
		this.changeDetector.detectChanges();
		$('#rechazarFaturaModal').modal('hide');
	}

	show_approve_modal_dialog() {
		this.changeDetector.detectChanges();
		$('#aprobarFaturaModal').modal('show');
	}

	show_validation_limit_consumption_modal() {
		this.changeDetector.detectChanges();
		$('#validationsLimitConsumptions').modal('show');
	}

	// Controller Operations...
	// --------------------------------------------------------

	validate_token(token) {
		let decoded_token = this.token_service.Decode(token);
		if (decoded_token.result) {
			return decoded_token.data;
		}
		else {
			return null;
		}
	}

	async get_factura(receipt_id) {
		let response = await this.receipt_services.GetReceiptDetailsWithProductsArray(receipt_id);
		return response;
	}

	async get_farmacias(country) {
		try {
			let response = await this.receipt_services.GetDrugStoresData(country);
			if (!response["result"]) {
				return [];
			}
			else {
				return response["data"];
			}
		}
		catch (err) {
			return [];
		}
	}

	// Factura Operations...
	// --------------------------------------------------------

	async approve_factura() {
		try {

			let factura = await this.pull_shared_data();
			const response = await this.validateLimitConsumption(factura);
			//return;
			if (response.length > 0) {
				this.show_validation_limit_consumption_modal();
				return;
			}

			factura = this.format_factura(factura);

			// Validates the factura
			let validation_result = await this.validate_factura(factura);
			if (!validation_result["result"]) {
				this.list_of_messages = validation_result["data"];
				this.show_validation_messages_dialog();
				return;
			}

			// Stops the timer
			this.messagingService.Fac_Stop_Timer({});

			// Shows please wait dialog
			this.show_please_wait_dialog();

			// Removes the OCR Info part of the object to avoid large package on the post to the API
			let factura_with_no_ocr = {}
			Object.assign(factura_with_no_ocr, factura);
			delete factura_with_no_ocr["OcrInfo"];

			let update_response = await this.receipt_services.UpdateCRMReceipt(factura_with_no_ocr, this.token);

			// Verifies the update was performed
			if (!update_response["result"] || !this.utilities.is_object_empty(update_response["data"])) {
				// Closes the please wait dialog
				this.hide_please_wait_dialog();

				// Shows the saved message
				this.show_message_dialog("Espera.", "Ocurrió un problema al guardar la factura.", () => { });
				return;
			}

			// Syncs with Dynamics
			if (this.sync_with_dynamics) {
				let dynamics_sync_response = await this.sync_to_dynamics_approved(factura_with_no_ocr, factura_with_no_ocr["TiempoRevision"]);
				// console.log(dynamics_sync_response);
			}

			let approval_response = await this.receipt_services.ApproveReceipt(factura["ID"], this.abox_services_api_user, []);

			// Verifies the update was performed
			if (!approval_response["result"] || (approval_response["data"]["header"]["code"] !== 0 && approval_response["data"]["header"]["code"] !== '')) {

				// Closes the please wait dialog
				this.hide_please_wait_dialog();

				// Shows the saved message
				this.show_message_dialog("Espera.", "Ocurrió un problema al aprobar la factura. Los cambios se guardaron, pero no se pudo aprobar.", () => { });
				return;

			}

			// Closes the please wait dialog
			this.hide_please_wait_dialog();

			// Reloads the factura in the background
			this.reload_factura();

			// Shows the saved message
			this.show_message_dialog("Listo!", "La factura se aprobó.", () => {
				window.close();
			});

		}
		catch (err) {
			// Shows the saved message
			this.show_message_dialog("Espera.", "Ocurrió un problema al salvar la factura.", () => { });
			console.log(err);

			// Closes the please wait dialog
			this.hide_please_wait_dialog();
		}
	}

	async reject_factura() {
		this.rejection_processing = false;
		this.show_reject_modal_dialog();
	}

	async save_factura() {
		try {

			let factura = await this.pull_shared_data();
			const response = await this.validateLimitConsumption(factura);
			if (response.length > 0) {
				this.show_validation_limit_consumption_modal();
				return;
			}

			factura = this.format_factura(factura);

			// Validates the factura
			let validation_result = await this.validate_factura(factura);
			if (!validation_result["result"]) {
				console.log(validation_result);
				this.list_of_messages = validation_result["data"];
				this.show_validation_messages_dialog();
				return;
			}

			// Stops the timer
			this.messagingService.Fac_Stop_Timer({});

			// Shows please wait dialog
			this.show_please_wait_dialog();

			let dynamics_json = this.dynamics_service.generate_dynamics_json(factura);

			// Removes the OCR Info part of the object to avoid large package on the post to the API
			let factura_with_no_ocr = {}
			Object.assign(factura_with_no_ocr, factura);
			delete factura_with_no_ocr["OcrInfo"];

			// Syncs with Dynamics
			if (this.sync_with_dynamics) {
				await this.sync_to_dynamics_approved(factura_with_no_ocr, factura_with_no_ocr["TiempoRevision"]);
			}

			let update_response = await this.receipt_services.UpdateCRMReceipt(factura_with_no_ocr, this.token);

			// Verifies the update was performed
			if (!update_response["result"] || !this.utilities.is_object_empty(update_response["data"])) {

				// Closes the please wait dialog
				this.hide_please_wait_dialog();

				// Shows the saved message
				this.show_message_dialog("Espera.", "Ocurrió un problema al guardar la factura.", () => { });
				return;
			}

			// Closes the please wait dialog
			this.hide_please_wait_dialog();

			// Shows the saved message
			this.show_message_dialog("Listo!", "La factura se guardó.", () => { });

		}
		catch (err) {
			// Closes the please wait dialog
			this.hide_please_wait_dialog();

			// Shows the saved message
			this.show_message_dialog("Espera.", "Ocurrió un problema al salvar la factura.", () => { });
			console.log(err);
		}
	}

	async reload_factura() {
		this.show_please_wait_dialog();

		this.factura_details_object = await this.get_factura(this.receipt_id);

		let farmacias = await this.get_farmacias(this.factura_details_object["country"]);

		// Uploads the factura objecto to the shared data service
		this.shared_data_service.fac_country = this.factura_details_object["country"];
		this.shared_data_service.fac_details = this.factura_details_object;
		this.shared_data_service.fac_farmacias = farmacias;


		// Uploads the OCR detail if available
		let factura_ocr_details = undefined;
		if (this.factura_details_object["OcrInfo"] !== undefined) {
			factura_ocr_details = JSON.parse(unescape(this.factura_details_object["OcrInfo"]["imageText"]));
		}
		this.shared_data_service.fac_ocr_details = factura_ocr_details;

		// Uploads the required data to the shared data service
		this.shared_data_service.fac_editor_user = this.abox_services_api_user;
		this.shared_data_service.fac_token = this.token;

		// Triggers the factura loaded event so every component gets updated
		this.messagingService.Fac_Loaded({});

		this.hide_please_wait_dialog();
	}

	async reload_factura_pull_ocr_info() {
		let factura = await this.get_factura(this.receipt_id);
		// Uploads the OCR detail if available
		let factura_ocr_details = undefined;
		if (factura["OcrInfo"] !== undefined) {
			factura_ocr_details = JSON.parse(unescape(factura["OcrInfo"]["imageText"]));
		}
		this.shared_data_service.fac_ocr_details = factura_ocr_details;
		this.messagingService.Fac_Ocr_Info_Updated(factura_ocr_details);
	}

	async nullify_factura() {
		try {
			let factura = await this.pull_shared_data();
			const { idFactura } = factura;
			this.show_please_wait_dialog();
			let response = await this.receipt_services.NullifyReceipt(idFactura);

			// Closes the please wait dialog
			this.hide_please_wait_dialog();

			if (response.result) {
				let _this = this;
				// Shows the saved message
				this.show_message_dialog("Espera.", "Listo la factura se anuló.", () => {
					// Reloads the factura in the background
					_this.reload_factura();
				});
			}
			else {
				// Shows the saved message
				this.show_message_dialog("Espera.", "Ocurrió un problema al anular la factura.", () => { });
			}

		}
		catch (err) {
			// Closes the please wait dialog
			this.hide_please_wait_dialog();

			// Shows the saved message
			this.show_message_dialog("Espera.", "Ocurrió un problema al anular la factura.", () => { });
			console.log(err);
		}
	}

	// Rejection operations...
	// --------------------------------------------------------

	async RejectDynamicsCRM(e) {

		let factura = await this.pull_shared_data();
		factura = this.format_factura(factura);

		// Removes the OCR Info part of the object to avoid large package on the post to the API
		let factura_with_no_ocr = {}
		Object.assign(factura_with_no_ocr, factura);
		delete factura_with_no_ocr["OcrInfo"];

		// Syncs with Dynamics
		if (this.sync_with_dynamics) {
			let dynamics_sync_response = await this.sync_to_dynamics_rejected(factura_with_no_ocr, factura_with_no_ocr["TiempoRevision"], e["reason"]);
			// console.log(dynamics_sync_response);
		}
	}

	HandleRejectionLoading(e) {
		this.rejection_processing = true;
	}

	HandleRejectionResponse(e) {
		this.hide_reject_modal_dialog();
		this.show_message_dialog("Listo", "La factura se rechazó.", async () => {
			window.close();
		});
	}

	// Anullment Operations...

	async NullifyFactura(factura: any) {
		this.changeDetector.detectChanges();
		$('#anularFacturaModal').modal('show');
	}

	// Dynamics Operations...
	//

	async sync_to_dynamics_approved(factura, revision_time) {
		// Microsoft Dynamics CRM Integration START ------
		// Dynamics Microsoft Integration
		let dynamics_json_response = this.dynamics_service.generate_dynamics_json(factura);

		if (!dynamics_json_response["result"]) {
			// Handle error.
			return {
				result: false,
				message: "Error al sincronizar factura con el CRM.",
				data: dynamics_json_response["data"]
			}
		}

		let dynamics_json = dynamics_json_response["data"];
		dynamics_json["status"] = "Aprobada"
		dynamics_json["revisionTime1"] = revision_time;

		try {
			let rDynamics = await this.receipt_services.DynamicsUpdateReceipt(dynamics_json);
			if (!rDynamics["result"]) {
				// Handle error.
				return {
					result: false,
					message: "Error al sincronizar factura con el CRM.",
					data: rDynamics["data"]
				}
			}

			return {
				result: true,
				message: "Factura sincronizada con el CRM.",
				data: rDynamics
			}
		}
		catch (err) {
			return {
				result: false,
				message: "Error al sincronizar factura con el CRM.",
				data: err
			}
		}

		// Microsoft Dynamics CRM Integration END ------
	}

	async sync_to_dynamics_rejected(factura, revision_time, reason) {
		// Microsoft Dynamics CRM Integration START ------
		// Dynamics Microsoft Integration
		let dynamics_json_response = this.dynamics_service.generate_dynamics_json(factura);

		if (!dynamics_json_response["result"]) {
			// Handle error.
			return {
				result: false,
				message: "Error al sincronizar factura con el CRM.",
				data: dynamics_json_response["data"]
			}
		}

		let dynamics_json = dynamics_json_response["data"];
		dynamics_json["status"] = "Rechazada"
		dynamics_json["revisionTime1"] = revision_time;
		dynamics_json["statusReason"] = reason;

		try {
			let rDynamics = await this.receipt_services.DynamicsUpdateReceipt(dynamics_json);
			if (!rDynamics["result"]) {
				// Handle error.
				return {
					result: false,
					message: "Error al sincronizar factura con el CRM.",
					data: rDynamics["data"]
				}
			}

			return {
				result: true,
				message: "Factura sincronizada con el CRM.",
				data: rDynamics
			}
		}
		catch (err) {
			return {
				result: false,
				message: "Error al sincronizar factura con el CRM.",
				data: err
			}
		}

		// Microsoft Dynamics CRM Integration END ------
	}


	// Validation Operations
	// --------------------------------------------------------

	async validate_factura(factura) {

		let error_messages = [];
		let validation_result: boolean = true;
		try {

			console.log('FACTURA:', factura);

			if (!factura["Factura"] || factura["Factura"] === "" || factura["Factura"] === undefined) {
				error_messages.push({ "message": "El número de factura es requerido." });
				validation_result = false;
			}

			if (!factura["Fecha"] || factura["Fecha"] === "" || factura["Fecha"] === undefined) {
				error_messages.push({ "message": "La fecha de la factura es requerida." });
				validation_result = false;
			}

			let facturaDateToValidate = new Date(factura["Fecha"]);
			var diff = new DateDiff(new Date(), facturaDateToValidate);
			if (diff.months() > 1) {
				error_messages.push({ "message": "La fecha de la factura no puede ser mayor a 1 mes." });
				validation_result = false;
			}

			if (!factura["MontoTotal"] || factura["MontoTotal"] === "" || factura["MontoTotal"] === undefined) {
				error_messages.push({ "message": "El monto total de la factura es requerido." });
				validation_result = false;
			}

			if (isNaN(Number(factura["MontoTotal"]))) {
				error_messages.push({ "message": "El monto total de la factura es inválido." });
				validation_result = false;
			}

			// Validates that the products all have quantity and amount
			factura["Products"].forEach(p => {
				if (!p["Precio"] || p["Precio"] === "" || p["Precio"] === undefined) {
					error_messages.push({ "message": `El precio para el producto ${p["Producto"]} es requerido.` });
					validation_result = false;
				}
				if (isNaN(Number(p["Precio"]))) {
					error_messages.push({ "message": `El precio para el producto ${p["Producto"]} no es válido.` });
					validation_result = false;
				}
				if (Math.floor(Number(p["Precio"])) === 0) {
					error_messages.push({ "message": `El precio para el producto ${p["Producto"]} es requerido.` });
					validation_result = false;
				}
				if (!p["Cantidad"] || p["Cantidad"] === "" || p["Cantidad"] === undefined) {
					error_messages.push({ "message": `La cantidad para el producto ${p["Producto"]} es requerido.` });
					validation_result = false;
				}
				if (Math.floor(Number(p["Cantidad"])) === 0) {
					error_messages.push({ "message": `El precio para el producto ${p["Producto"]} es requerido.` });
					validation_result = false;
				}
			});

			// Validates that the products all have quantity and amount
			factura["ProductosNoAbbott"].forEach(p => {
				if (!p["Producto_Otros"] || p["Producto_Otros"] === "" || p["Producto_Otros"] === undefined) {
					error_messages.push({ "message": `Hay un producto No Abbott sin nombre.` });
					validation_result = false;
				}
				else {
					if (!p["Precio_Otros"] || p["Precio_Otros"] === "" || p["Precio_Otros"] === undefined || isNaN(p["Precio_Otros"])) {
						error_messages.push({ "message": `El precio para el producto ${p["Producto_Otros"]} es requerido.` });
						validation_result = false;
					}
					if (!p["Cantidad_Otros"] || p["Cantidad_Otros"] === "" || p["Cantidad_Otros"] === undefined || isNaN(p["Cantidad_Otros"])) {
						error_messages.push({ "message": `La cantidad para el producto ${p["Producto_Otros"]} es requerido.` });
						validation_result = false;
					}
				}
			});



			return {
				result: validation_result,
				message: "",
				data: error_messages
			}
		}
		catch (err) {
			return {
				result: false,
				message: "",
				data: error_messages
			}
		}
	}

	async getLimitMonthly(obj: LimitConsumption) {

		return this.receipt_services.validateLimitConsumptionMonthly(obj);
	}

	async getLimitYearly(obj: LimitConsumption) {

		return this.receipt_services.validateLimitConsumptionYearly(obj);
	}

	async validateLimitConsumptionYearly(objRequest: LimitConsumption) {

		this.list_of_error_validation_limits = [];

		try {

			//let errorsFound = [];
			// se valida primeramente el limite anual

			const { response: {
				details: {
					productos: productosMonthly
				}
			} } = await this.getLimitYearly(objRequest);

			for (const producto of productosMonthly) {

				if (producto.resultado_validaciones[0].mensaje && producto.resultado_validaciones[0].mensaje.length > 0) {
					this.list_of_error_validation_limits.push(producto.resultado_validaciones[0].mensaje);
				}
			}

			return this.list_of_error_validation_limits;

		} catch (error) {

			this.list_of_error_validation_limits.push('Ocurrió un error al obtener los límites de consumo');
			return this.list_of_error_validation_limits;
		}
	}



	// ------------------------------------------------------------------------------------------------------
	async validateLimitConsumptionMonthly(objRequest: LimitConsumption) {

		this.list_of_error_validation_limits = [];

		try {

			//let errorsFound = [];
			// se valida primeramente el limite anual

			let { response: {
				details: {
					detalles
				}
			} } = await this.getLimitMonthly(objRequest);

			let limitePorMarca = 0;
			let extensionPorMarca = 0;
			let cantidadTotalPorMarcaSolicitada = 0;
			let consumoAcumuladoPorMarca = 0;
			let primeraVez = false;
			let familia = '';
			let totalPorMarcaMasExtension = 0;

			for (const producto1 of detalles) {

				familia = producto1.familia;
				primeraVez = true;

				for (const producto2 of detalles) {

					if (producto2.familia === familia) {

						if (primeraVez) {
							limitePorMarca = producto1.maximo_permitido_por_mes_por_familia;
							extensionPorMarca = producto1.extension_por_familia;
							consumoAcumuladoPorMarca = producto1.consumido_este_mes_por_familia;
							totalPorMarcaMasExtension = limitePorMarca + extensionPorMarca;
							primeraVez = false;
						}

						cantidadTotalPorMarcaSolicitada += producto2.resultado_de_validaciones[0].cantidad_reportada;
						detalles = detalles.filter(detalle => detalle.id_producto !== producto2.id_producto);
					}
				}

				console.log({ familia });
				console.log({ consumoAcumuladoPorMarca });
				console.log({ totalPorMarcaMasExtension });
				console.log({ cantidadTotalPorMarcaSolicitada });

				if (limitePorMarca > 0 && ((cantidadTotalPorMarcaSolicitada + consumoAcumuladoPorMarca) > totalPorMarcaMasExtension)) {
					this.list_of_error_validation_limits.push(`El límite de consumo mensual para los productos de la familia ${familia} es ${totalPorMarcaMasExtension}. El consumo actual de la familia ${familia} es de ${consumoAcumuladoPorMarca}. Consumo restante disponible para la Familia ${familia} es de  ${(totalPorMarcaMasExtension - consumoAcumuladoPorMarca)}`);
				}

				cantidadTotalPorMarcaSolicitada = 0;
			}

			return this.list_of_error_validation_limits;

		} catch (error) {

			this.list_of_error_validation_limits.push('Ocurrió un error al obtener los límites de consumo');
			return this.list_of_error_validation_limits;
		}
	}
	// ------------------------------------------------------------------------------------------------------

	buildObjectRequest(factura: any) {

		let objRequest: LimitConsumption = {
			id_paciente: null,
			productos: []
		};

		objRequest.id_paciente = factura.idPaciente

		for (let product of factura.Products) {

			objRequest.productos.push({
				ID_Producto: product.ID_Producto,
				cantidad: product.Cantidad
			})

			//Cuando se use codigo asincrono dentro dentro de un ciclo, usar forof que respeta el flujo dentro de su scope 			
		}

		return objRequest;
	}

	async validateLimitConsumption(factura: any) {

		const objRequest = this.buildObjectRequest(factura);
		//limite anual
		const responseLimitYearly = await this.validateLimitConsumptionYearly(objRequest);
		//if((await responseLimitYearly).length) resolver una promesa con un await y leer el resultado al mismo tiempo
		if (responseLimitYearly.length > 0) {
			return responseLimitYearly;
		}

		//superó la validación del limite anual, viene el limite mensual

		const responseLimitMonthly = await this.validateLimitConsumptionMonthly(objRequest);


		if (responseLimitMonthly.length > 0) {
			return responseLimitMonthly;
		}

		return [];

	}

	// Component basic methods...
	// --------------------------------------------------------

	async trigger_constructor() {

		this.messagingService.fac_controller_show_please_wait_event.subscribe(message => {
			this.show_please_wait_dialog();
		});

		this.messagingService.fac_controller_hide_please_wait_event.subscribe(message => {
			this.hide_please_wait_dialog();
		});

		this.messagingService.fac_request_fac_reload_event.subscribe(message => {
			this.reload_factura();
		});

		this.messagingService.fac_request_fac_reload_ocr_info_event.subscribe(message => {
			this.reload_factura_pull_ocr_info();
		});

		this.messagingService.fac_new_image_loaded_event.subscribe(message => {
			this.reload_factura_pull_ocr_info();
		});

		this.messagingService.fac_nullify_factura_event.subscribe(message => {
			this.NullifyFactura(message);
		});

		// --- Dynamics Debug ---

		this.messagingService.fac_sync_with_dynamics_event.subscribe(async message => {
			// Removes the OCR Info part of the object to avoid large package on the post to the API
			let factura_with_no_ocr = {}
			Object.assign(factura_with_no_ocr, this.factura_details_object);
			delete factura_with_no_ocr["OcrInfo"];

			let response = await this.sync_to_dynamics_approved(factura_with_no_ocr, factura_with_no_ocr["TiempoRevision"]);
		});

		this.messagingService.fac_generate_dynamics_json_event.subscribe(async message => {
			let dynamics_json = this.dynamics_service.generate_dynamics_json(this.factura_details_object);
		});

		this.messagingService.fac_generate_factura_json_event.subscribe(async message => {
		});


		// Token Processing
		// ------------------------------------------------------------

		this.token = this.route.snapshot.params["token"];
		if (this.token !== undefined) {
			let result = this.validate_token(this.token);
			if (result !== null) {
				this.receipt_id = result["receiptid"];
				this.abox_services_api_token = result["token"];
				this.abox_services_api_user = result["user"];

				this.is_token_valid = true;
				this.receipt_services.SetToken(this.abox_services_api_token);
				this.reload_factura();
			}
			else {
				this.is_token_valid = false;
				this.message = "The token is not valid.";
			}
		}
		else {
			this.is_token_valid = false;
			this.message = "The token is not valid.";
		}

		// Messages Subscriptions
		// ------------------------------------------------------------

		this.messagingService.fac_approve_event.subscribe((obj) => { this.show_approve_modal_dialog(); });
		this.messagingService.fac_reject_event.subscribe((obj) => { this.reject_factura(); });
		this.messagingService.fac_save_event.subscribe((obj) => { this.save_factura(); });
	}

	async ngOnInit() {
		//$('#validationsLimitConsumptions').modal('show');
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
	}



}
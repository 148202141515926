import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding, ChangeDetectionStrategy } from "@angular/core";
import { ReceiptService } from '../../services/receipt.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessagingService } from '../../services/messagingservice';
import { TokenService } from '../../services/token.service';

import { environment } from '../../../environments/environment';

declare var jquery:any
declare var $ :any;

@Component({
	selector: "cartsimulator",
	templateUrl: "cartsimulator.component.html",
	styleUrls: ["cartsimulator.component.css"]
})
export class CartSimulator implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	// Cart Simulator Storage
	selectedProducts: any = [];
	productsList: any = [];

	constructor(private messagingService: MessagingService, private receiptService: ReceiptService) {
		this.messagingService.clearCartEvent.subscribe((message) => {
			this.selectedProducts = [];
			this.messagingService.UpdateCart();
		});
	}

	selectedItemChanged(event) {
		this.selectedProducts.push(event);
		this.messagingService.UpdateCart();
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
		// Gets the Products list
		let productsR = await this.receiptService.GetProducts('CR');
		this.productsList = productsR;
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }
}
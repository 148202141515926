import { Component, OnInit, Renderer, HostListener, Inject, enableProdMode } from "@angular/core";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";

declare var jquery:any;
declare var $ :any;
  
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
constructor(
    private renderer: Renderer,
    public location: Location,
    @Inject(DOCUMENT) document
) {}

@HostListener("window:scroll", ["$event"])
    onWindowScroll(e) {
        if (window.pageYOffset > 100) {
        var element = document.getElementById("navbar-top");
        if (element) {
            element.classList.remove("navbar-transparent");
            element.classList.add("bg-danger");
        }
        } else {
        var element = document.getElementById("navbar-top");
        if (element) {
            element.classList.add("navbar-transparent");
            element.classList.remove("bg-danger");
        }
        }
    }
    
    ngOnInit() {
        this.onWindowScroll(event);
    }
}
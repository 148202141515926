import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";

declare var jquery: any;
declare var $: any;

@Component({
	selector: 'rejection-reason-dropdown',
	templateUrl: './rejectionreasondropdown.component.html',
	styleUrls: ["rejectionreasondropdown.component.css"]
})
export class RejectionReasonDropDown implements OnInit, OnChanges {

	@Input() selectedReason: string = "";
	@Output() selectedReasonChanged = new EventEmitter<string>();

	reasonsList: any = [
		{ id: 1, value: "FACTURA SIN NOMBRE DE FARMACIA" },
		{ id: 2, value: "FACTURA SIN NOMBRE DE FARMACIA" },
		{ id: 3, value: "EXCEDE LIMITE DE COMPRAS REPORTADAS" },
		{ id: 4, value: "FACTURA REPORTA PRODUCTO SUELTO" },
		{ id: 5, value: "FACTURA NO PRESENTA PRECIO" },
		{ id: 6, value: "FACTURA YA FUE REPORTADA" },
		{ id: 7, value: "FACTURA BORROSA" },
		{ id: 8, value: "FACTURA CORTADA" },
		{ id: 9, value: "FACTURA SIN FECHA" },
		{ id: 10, value: "VIGENCIA MAYOR A 1 MES" },
		{ id: 11, value: "NO HAY ARCHIVO ADJUNTO" },
		{ id: 12, value: "IMAGEN NO CORRESPONDE A FACTURA" },
		{ id: 13, value: "FACTURA NO REGISTRA COMPRA DE PRODUCTO ABBOTT" },
		{ id: 14, value: "FACTURA PRESENTA MODIFICACIONES" },
		{ id: 15, value: "VOUCHER SOBRE FACTURA, NO PERMITE LECTURA CORRECTA" },
		{ id: 16, value: "FACTURA NO REPORTA PRODUCTO PARTICIPANTE EN ABOX" },
		{ id: 17, value: "MULTIPLES MOTIVOS" },
		{ id: 18, value: "NIT DE FACTURA NO COINCIDE CON EL NIT DEL PERFIL" },
		{ id: 19, value: "LUGAR DE COMPRA NO AUTORIZADO" }
	];

	constructor() {
	}

	selectChanged(e) {
		let idx = Number(e.srcElement.value);

		this.selectedReason = this.reasonsList.filter(r => r.id === idx);
		this.selectedReasonChanged.emit(this.selectedReason[0]["value"]);
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
	}
}

import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ModalDismissReasons, NgbDateStruct, NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from '../../../../../services/messagingservice';

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "input_textbox",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "input_textbox.component.html",
	styleUrls: ["input_textbox.component.css"]
})
export class input_textbox_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() title: string;
    @Input() placeholder: string;
    @Input() value: string;

    // Component Methods
	// --------------------------------------------------

	constructor(
		private ref: ChangeDetectorRef, 
		private messagingService: MessagingService) {
	}

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
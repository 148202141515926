import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root', })
export class CacheService {

	constructor() {
	}

    Store(key, data) {
        let d = JSON.stringify(data);
        // localStorage.setItem(key, d);
    }

    Get(key, defaultValue) {
        return new Promise(async (resolve, reject) => {
            try {
                defaultValue = defaultValue || "";
                let value = localStorage.getItem(key);
                if (value) {
                    resolve(JSON.parse(value));
                } 
                else {
                    resolve(defaultValue);
                }
            }
            catch(err) {
                reject(err);
            }
        });
    }
}
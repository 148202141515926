import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";
import { MessagingService } from '../../../services/messagingservice';
import { ReceiptService } from "src/app/services/receipt.service";

declare var jquery: any;
declare var $: any;

@Component({
    selector: "receiptinfo-component",
    templateUrl: "receiptinfo.component.html",
    styleUrls: ["receiptinfo.component.css"]
})
export class ReceiptInfoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() receiptObject: any = {};
    @Input() updateRequestBody: Object = {};
    public farmacias: any = null;

    public ocrInfo: any = {};
    public receiptHasMatches: boolean = false;
    public pharmacyHasMatches: boolean = false;
    public dateHasMatches: boolean = false;

    constructor(private ms: MessagingService, private receiptService: ReceiptService,) {
        this.ms.updateReceiptInfoComponentEvent.subscribe((obj) => {
            this.receiptObject = obj;
            this.ngOnInit();
        });

        this.ms.triggerDrawPolygonsEvent.subscribe(() => {
            setTimeout(() => {
                // this.Select('receipt');
                // this.Select('pharmacy');
            }, 360);
        });
    }

    // Component Methods
    // --------------------------------------------------

    checkValue(checked: any, source: any) {
        switch (source) {
            case "receipt":
                this.receiptObject["receipt_checked"] = checked.currentTarget.checked;
                this.updateRequestBody['receipt_checked'] = checked.currentTarget.checked;
                break;
            case "pharmacy":
                this.receiptObject["pharmacy_checked"] = checked.currentTarget.checked;
                this.updateRequestBody['pharmacy_checked'] = checked.currentTarget.checked;
                break;
            case "date":
                this.receiptObject["date_checked"] = checked.currentTarget.checked;
                this.updateRequestBody['date_checked'] = checked.currentTarget.checked;
                break;
            case "total_amount":
                this.receiptObject["total_amount_checked"] = checked.currentTarget.checked;
                this.updateRequestBody['total_amount_checked'] = checked.currentTarget.checked;
                break;
            case "sale_type":
                this.receiptObject["sale_type_checked"] = checked.currentTarget.checked;
                this.updateRequestBody['sale_type'] = checked.currentTarget.checked;
                break;
        }
    }

    Select(source) {
        if (this.ocrInfo) {
            switch (source) {
                case "receipt":
                    if (this.ocrInfo["receiptnumber"]) {
                        this.ms.DrawPolygonOnCanvas(this.ocrInfo["receiptnumber"]["matched_words"][0]);
                    }
                    break;
                case "pharmacy":
                    if (this.ocrInfo["pharmacyname"]) {
                        this.ms.DrawPolygonOnCanvas(this.ocrInfo["pharmacyname"]["matched_words"][0]);
                    }
                    break;
                case "date":
                    if (this.ocrInfo["receiptdate"]) {
                        this.ms.DrawPolygonOnCanvas(this.ocrInfo["receiptdate"]["matched_words"][0]);
                    }
                    break;
            }
        }
    }

    formatDate(string) {
        var date = new Date(string);
        var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        return dateString
    }

    // UPDATE OPERATION FUNCTIONS
    getFarmacia(id: string) {
        var far: any;
        this.farmacias.forEach(f => {
            if (f.id == id) {
                far = f;
            }
        });

        return far;
    }

    getFarmaciaByName(name: string) {
        var far: any;
        this.farmacias.forEach(f => {
            if (f.name == name) {
                far = JSON.parse(JSON.stringify(f));
            }
        });

        return far;
    }

    checkUpdateObject() {
        
    }

    fillRequestObjectWithReciept() {
        if (this.receiptObject != null && this.receiptObject != undefined) {
            this.updateRequestBody['ID'] = this.receiptObject.idFactura;
            let f = this.getFarmaciaByName(this.receiptObject.Farmacia);
            this.updateRequestBody['Farmacia'] = { ID: f === undefined ? -1 : f.id, Nombre: f === undefined ? 'Otra' : f.name }

            if (f === undefined) {
                this.updateRequestBody['Farmacia']['Nombre_Otra'] = this.receiptObject.Farmacia;
            }

            let fa = this.receiptObject.Factura;
            this.updateRequestBody['Factura'] = fa;

            let fe = this.receiptObject.Fecha === undefined ? '' : this.formatDate(this.receiptObject.Fecha);
            this.updateRequestBody['Fecha'] = fe;
            this.updateRequestBody['ProductosAbbott'] = this.receiptObject.Products;

            // Sets a flag that indicates the object with the data is not validated
            this.updateRequestBody['is_valid'] = false;
        }
    }

    modifyRequestObject(key: string, value: any) {

        if (key === 'Factura') {
            this.updateRequestBody['Factura'] = value;
        }

        if (key === 'MontoTotal') {
            this.updateRequestBody['MontoTotal'] = value;
        }

        if (key === 'Farmacia') {
            let f = this.getFarmacia(value);
            
            if (f === undefined) return;

            if (value !== "-1") {
                delete this.updateRequestBody['Farmacia']["Nombre_Otra"];
            }

            this.updateRequestBody['Farmacia'] = { ID: f.id, Nombre: f.name };
        }

        if (key === 'Farmacia-Name-Other') {
            this.updateRequestBody['Farmacia']["Nombre_Otra"] = value;
        }

        if (key == 'ProductosAbbott') {

        }

        if (key == 'ProductosNoAbbott') {

        }

        if (key != 'Farmacia' && key != 'ProductosAbbott' && key != 'ProductosNoAbbott') {
            this.updateRequestBody[key] = value;
        }
    }

    async ngOnInit() {

        if (this.receiptObject["OcrInfo"] !== undefined && this.receiptObject["OcrInfo"]["imageText"] != "") {
            this.ocrInfo = this.receiptObject["OcrInfo"]["imageText"];
            this.ocrInfo = unescape(this.ocrInfo);
            this.ocrInfo = JSON.parse(this.ocrInfo);

            if (this.ocrInfo && this.ocrInfo["receiptnumber"] && this.ocrInfo["receiptnumber"]["matched_words"] && this.ocrInfo["receiptnumber"]["matched_words"].length > 0) {
                this.receiptHasMatches = true;
                this.Select('receipt');
            }

            if (this.ocrInfo && this.ocrInfo["pharmacyname"] && this.ocrInfo["pharmacyname"]["matched_words"] && this.ocrInfo["pharmacyname"]["matched_words"].length > 0) {
                this.pharmacyHasMatches = true;
                this.Select('pharmacy');
            }

            if (this.ocrInfo && this.ocrInfo["receiptdate"] && this.ocrInfo["receiptdate"]["matched_words"] && this.ocrInfo["receiptdate"]["matched_words"].length > 0) {
                this.dateHasMatches = true;
                this.Select('date');
            }

            if (this.ocrInfo !== null && this.ocrInfo !== undefined) {
                if (this.ocrInfo['products'] !== null && this.ocrInfo['products'] !== undefined) {
                    this.ocrInfo['products'].forEach(p=>{
                        this.receiptObject['Products'].forEach(pAbbot => {
                            if(p['ID_Producto'] === pAbbot['ID_Producto'] && p['hasMatches']){
                                pAbbot['matched_words'] = p['matched_words'];
                            }
                        });
                    });
                }
            }
        }

        try {
            var r: any = await this.receiptService.GetDrugStoresData(this.receiptObject.country);
            this.farmacias = r.data;
            this.fillRequestObjectWithReciept();
        }
        catch (err) {
            console.log(err)
        }
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
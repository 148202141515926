import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, ɵɵupdateSyntheticHostBinding } from "@angular/core";
import { DemoService } from '../../services/demo.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: "welcome",
	templateUrl: "welcome.component.html",
	styleUrls: ["welcome.component.css"]
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {

	public token: string = "";

	constructor(private demoService: DemoService, private router: Router) {
	}

	// Events
	// --------------------------------------------------

	async OpenFactura() {
		this.router.navigate(['receipt', this.token]);
	}
	
	// Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}
}
import { Input, Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter } from "@angular/core";

declare var jquery:any;
declare var $ :any;

@Component({
	selector: "priority_dropdown",
	templateUrl: "priority_dropdown.component.html",
	styleUrls: ["priority_dropdown.component.css"]
})
export class priority_dropdown_Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Output() selectedItemChangedEvent = new EventEmitter<string>();
    
    // Data:
    public datalist: any = [
        { 'id':'', 'name':'TODOS' },
		{ 'id':'ENSURE', 'name':'ENSURE' },
		{ 'id':'MIA', 'name':'MIA' },
		{ 'id':'KLARICID', 'name':'KLARICID' },
		{ 'id':'GLUCERNA', 'name':'GLUCERNA' },
		{ 'id':'PEDIALYTE', 'name':'PEDIALYTE' },
		{ 'id':'PEDIASURE', 'name':'PEDIASURE' },
		{ 'id':'RIBOLAC', 'name':'RIBOLAC' },
		{ 'id':'SIMILAC', 'name':'SIMILAC' },
		{ 'id':'QUETIDIN', 'name':'QUETIDIN' }
	];

	selectedPriorityChanged(selectedItem) {
		this.selectedItemChangedEvent.emit(selectedItem);
	}

    // Component Methods
	// --------------------------------------------------

    async ngOnInit() {
    }

    ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
    }

}
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { WelcomeComponent } from './pages/welcome/welcome.component';
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { ReceiptRevisionComponent } from "./pages/receiptrevision/receiptrevision.component";
import { ReceiptPosComponent } from "./pages/receiptpos/receiptpos.component";
import { CartSimulator } from "./pages/cartsimulator/cartsimulator.component";
import { ReceiptDetailsPageComponent } from "./pages/receiptdetails/receiptdetailspage.component";
import { Facturas_SearchPageComponent } from "./pages/facturas_search_page/facturas_search_page.component";
import { Revision_Factura_Page } from "./pages/revision_factura/revision_factura_page.component";

const routes: Routes = [
	{ path: "cartsimulator", component: CartSimulator },
	{ path: "receipt/:token", component: ReceiptDetailsPageComponent },
	{ path: "receipt/:id/:token", component: ReceiptDetailsPageComponent },
	{ path: "facturas", component: Facturas_SearchPageComponent },
	{ path: "revision_factura/:token", component: Revision_Factura_Page },
	{ path: "", component: WelcomeComponent },
	{ path: "**", redirectTo: '404' }
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: true
		})
	],
	exports: []
})
export class AppRoutingModule { }